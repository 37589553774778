// action - state management
import * as actionTypes from '../actions';

export const initialState = {
    user: null,
    globalDefaults: null,
    userDetails: null,
    detailsDefaultCompany: null
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.user
            };
        case actionTypes.SET_GLOBAL_DEFAULTS:
            return {
                ...state,
                globalDefaults: action.globalDefaults
            };
        case actionTypes.SET_DETAILS_DEFAULTS_COMPANY:
            return {
                ...state,
                detailsDefaultCompany: action.detailsDefaultCompany
            };
        case actionTypes.SET_USER_DETAILS:
            return {
                ...state,
                userDetails: action.userDetails
            };
        case actionTypes.USER_LOGOUT:
            return {
                ...state,
                user: null,
                globalDefaults: null,
                userDetails: null,
                detailsDefaultCompany: null
            };
        default:
            return state;
    }
};

export default loginReducer;
