import React from 'react';
import {
    Modal,
    InputLabel,
    Grid,
    FormControl,
    Select,
    MenuItem,
    Button,
    Box,
    Typography,
    InputAdornment,
    IconButton,
    Tooltip
} from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import { AddCircleOutline, SelectAllOutlined } from '@mui/icons-material';
import MainCard from 'ui-component/cards/MainCard';
import InfoIcon from '@mui/icons-material/Info';
import { modalStyle } from 'views/utilities/ModalStyle';
import ModalForm from './ModalForm';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomerSearchForm from './CustomerSearchForm';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SelectForm = ({
    disabled,
    helper,
    label,
    handleChange,
    value,
    name,
    data,
    error,
    propToRender,
    required,
    sx,
    fullwidth,
    flagAddDefaultCustomer,
    refetch,
    id,
    setFormState,
    flagParentProductBundle,
    handleOpenParentProductBdl,
    isMultiple,
    isLink
}) => {
    const classes = useStyles();
    const isDisabled = Boolean(id);
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [openMiniForm, setOpenMiniForm] = React.useState(false);
    const [openParentBundle, setOpenParentBundle] = React.useState(false);
    const [customerSearchModal, setCustomerSearchModal] = React.useState(false);
    const handleOpen = () => setOpenMiniForm(true);
    const handleClose = () => setOpenMiniForm(false);

    const addForm = (
        <Box sx={modalStyle}>
            <MainCard divider title={'Add Customer'}>
                <ModalForm handleClose={handleClose} loadingButton={loadingButton} setLoadingButton={setLoadingButton} refetch={refetch} />
            </MainCard>
        </Box>
    );

    const customerSearch = (
        <Box sx={modalStyle}>
            <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 70px)', overflowX: 'hidden' }}>
                <MainCard divider title={'Search Customer'}>
                    <CustomerSearchForm
                        setOpenModal={setCustomerSearchModal}
                        handleCancel={() => setCustomerSearchModal(false)}
                        openModal={customerSearchModal}
                        data={data}
                        setFormState={setFormState}
                    />
                </MainCard>
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Grid
                sx={sx}
                container
                item
                xs={!fullwidth && 12}
                sm={!fullwidth && 12}
                md={!fullwidth && 8}
                lg={!fullwidth && 8}
                xl={!fullwidth && 8}
                spacing={4}
                display={'flex'}
                alignItems={'center'}
            >
                <Grid item xs={4}>
                    <InputLabel error={error ?? error} required={required} className={classes.inputLabel}>
                        {label}
                    </InputLabel>
                </Grid>
                <Grid item xs={8}>
                    <FormControl variant="outlined" fullWidth disabled={isDisabled || disabled}>
                        <Select
                            name={name}
                            value={isMultiple ? (Array.isArray(value) ? value : []) : value}
                            onChange={handleChange}
                            error={error ?? error}
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple={isMultiple}
                            IconComponent={() => (isLink ? null : <ArrowDropDownIcon />)}
                            endAdornment={
                                isLink && (
                                    <InputAdornment position="end">
                                        <Tooltip title="Accéder au profil" arrow>
                                            <IconButton
                                                component={Link}
                                                to={`/core/edit-role-profile/${value}`}
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                <ArrowForwardIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }
                        >
                            {flagAddDefaultCustomer && (
                                <Box className={classes.linkSection}>
                                    <MenuItem value={null} className={classes.addButton}>
                                        <Button
                                            disabled={!flagAddDefaultCustomer}
                                            className={classes.link}
                                            startIcon={<AddCircleOutline />}
                                            onClick={handleOpen}
                                        >
                                            Add {label}
                                        </Button>
                                        <Button
                                            className={classes.link}
                                            startIcon={<SelectAllOutlined />}
                                            onClick={() => setCustomerSearchModal(true)}
                                        >
                                            Select
                                        </Button>
                                    </MenuItem>
                                </Box>
                            )}
                            {data?.length === 0 ? (
                                <option disabled style={{ textAlign: 'center' }}>
                                    No data available
                                </option>
                            ) : (
                                Array.isArray(data) &&
                                data?.map((row, index) => (
                                    <MenuItem key={index} value={propToRender ? row?.[propToRender] : row}>
                                        {propToRender ? row?.[propToRender] : row}
                                    </MenuItem>
                                ))
                            )}
                            {flagAddDefaultCustomer && (
                                <Box className={classes.linkSection}>
                                    <Box className={classes.addButton}>
                                        <Button
                                            disabled={!flagAddDefaultCustomer}
                                            className={classes.link}
                                            startIcon={<AddCircleOutline />}
                                            onClick={handleOpen}
                                        >
                                            Add {label}
                                        </Button>
                                        <Button
                                            className={classes.link}
                                            startIcon={<SelectAllOutlined />}
                                            onClick={() => setCustomerSearchModal(true)}
                                        >
                                            Select
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                            {flagParentProductBundle && (
                                <Box className={classes.linkSection}>
                                    <Box className={classes.addButton}>
                                        <Button
                                            disabled={!flagParentProductBundle}
                                            className={classes.link}
                                            startIcon={<AddCircleOutline />}
                                            onClick={handleOpenParentProductBdl}
                                        >
                                            Add {label}
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </Select>
                        {helper && (
                            <Box display={'flex'} alignItems={'center'}>
                                <InfoIcon sx={{ fontSize: 14 }} color={error ? 'error' : 'blue'} />
                                <Typography color={error ? 'error' : 'primary'} sx={{ fontSize: 12, ml: 0.5 }}>
                                    {helper}
                                </Typography>
                            </Box>
                        )}
                    </FormControl>
                </Grid>
                <Modal open={openMiniForm} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {addForm}
                </Modal>
                <Modal open={customerSearchModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {customerSearch}
                </Modal>
            </Grid>
        </>
    );
};

export default SelectForm;
