import { Box } from '@mui/system';
import React, { useState } from 'react';
import { DropzoneArea } from 'react-mui-dropzone';
import ServerMSG from 'ui-component/cards/ServerMSG';
import { formatErpResponse } from 'utils/utils';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as XLSX from 'xlsx'; // for generating xlsx files
import { saveAs } from 'file-saver'; // for downloading the file

const DataImporter = ({ handleClose, refetch, columns, doctype }) => {
    const dispatch = useDispatch();

    const [files, setFiles] = useState([]);
    const [data2Insert, setData2Insert] = useState(null);
    const [docname, setDocname] = useState(null);
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fileType, setFileType] = useState('xlsx');

    const handleFilesChange = (files) => {
        setFiles(files);
    };

    const fetchLogs = () => {
        const header = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-log-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({
                doctype: 'Data Import Log',
                filters: { data_import: docname },
                fields: ['success', 'docname', 'messages', 'exception', 'row_indexes'],
                limit_page_length: 5000,
                order_by: 'log_index'
            })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((respw) => {
                if (respw?.success === false) {
                    dispatch(
                        SetNotification({
                            code: 'warning',
                            message: formatErpResponse(respw?.message)
                        })
                    );
                    return;
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Logs Fetched' }));
                    setLogs(respw?.message);
                }
            });
    };

    const handleUpload = () => {
        if (files?.length !== 0) {
            setLoading(true);
            const erpHeader = {
                Authorization: JSON.parse(localStorage.getItem('user'))?.service_token
            };
            const header = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                Authorization: JSON.parse(localStorage.getItem('user'))?.token
            };
            const data = {
                doc: {
                    docstatus: 0,
                    doctype: 'Data Import',
                    __islocal: 1,
                    __unsaved: 1,
                    import_type: 'Insert New Records',
                    status: 'Pending',
                    submit_after_import: 0,
                    mute_emails: 1,
                    show_failed_logs: 1,
                    reference_doctype: doctype
                },
                action: 'Save'
            };

            fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify(data)
            })
                .then((res) => {
                    if (!res.ok) {
                        dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                        setLoading(false);
                    }
                    return res.json();
                })
                .then((data) => {
                    if (data?.success === false) {
                        dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                        return;
                    }
                    setDocname(data?.docs?.[0]?.name);
                    const formData = new FormData();
                    formData.append(`file`, files[0], files[0]?.name);
                    formData.append('doctype', 'Data Import');
                    formData.append('is_private', 0);
                    formData.append('folder', 'Home');
                    formData.append('docname', data?.docs?.[0]?.name);
                    formData.append('fieldname', 'import_file');
                    fetch(`${process.env.REACT_APP_ERPNEXT_URL}/api/method/upload_file`, {
                        method: 'POST',
                        headers: erpHeader,
                        body: formData
                    })
                        .then((res) => {
                            if (!res.ok) {
                                dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                                setLoading(false);
                            }
                            return res.json();
                        })
                        .then((respData) => {
                            if (respData?.success === false) {
                                dispatch(SetNotification({ code: 'warning', message: formatErpResponse(respData?.message) }));
                                return;
                            }
                            fetch(`${process.env.REACT_APP_API_URI}/api/get-preview-from-template`, {
                                method: 'POST',
                                headers: header,
                                body: JSON.stringify({
                                    data_import: data?.docs?.[0]?.name,
                                    import_file: respData?.message?.file_url
                                })
                            })
                                .then((res) => {
                                    if (!res.ok) {
                                        dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                                        setLoading(false);
                                    }
                                    return res.json();
                                })
                                .then((resp) => {
                                    if (resp?.success === false) {
                                        dispatch(SetNotification({ code: 'warning', message: formatErpResponse(resp?.message) }));
                                        return;
                                    }
                                    if (resp?.message) {
                                        setData2Insert(resp?.message);
                                    }

                                    fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                                        method: 'POST',
                                        headers: header,
                                        body: JSON.stringify({
                                            doc: { ...data.docs?.[0], import_file: respData?.message?.file_url, __unsaved: 1 },
                                            action: 'Save'
                                        })
                                    })
                                        .then((res) => {
                                            if (!res.ok) {
                                                dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                                                setLoading(false);
                                            }
                                            return res.json();
                                        })
                                        .then((respx) => {
                                            if (respx?.success === false) {
                                                dispatch(SetNotification({ code: 'warning', message: formatErpResponse(respx?.message) }));
                                                return;
                                            }
                                            fetch(`${process.env.REACT_APP_API_URI}/api/form-start-import`, {
                                                method: 'POST',
                                                headers: header,
                                                body: JSON.stringify({
                                                    data_import: data?.docs?.[0]?.name
                                                })
                                            })
                                                .then((res) => {
                                                    if (!res.ok) {
                                                        dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                                                        setLoading(false);
                                                    }
                                                    return res.json();
                                                })
                                                .then((respy) => {
                                                    refetch({});
                                                    setLoading(false);
                                                    if (respy?.success === false) {
                                                        dispatch(SetNotification({ code: 'warning', message: 'An error has occurred' }));
                                                        return;
                                                    } else {
                                                        dispatch(
                                                            SetNotification({
                                                                code: 'success',
                                                                message: 'The import is start check the logs '
                                                            })
                                                        );
                                                    }
                                                });
                                        });
                                });
                        });
                });
        }
    };

    const formatLogs = (row) => {
        if (row?.messages) {
            let messages = JSON.parse(row?.messages);
            messages = JSON.parse(messages?.[0]);
            return messages?.title + ': ' + formatErpResponse(messages?.message);
        }
    };

    const downloadTemplate = () => {
        const fileExtension = fileType === 'xlsx' ? '.xlsx' : '.csv';
        const exportFileName = 'template' + fileExtension;

        // create a new workbook
        const workbook = XLSX.utils.book_new();

        // add a worksheet with the specified column headers
        const worksheet = XLSX.utils.json_to_sheet([{}], { header: columns });

        // add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // generate the file blob
        const fileBlob =
            fileType === 'xlsx' ? XLSX.write(workbook, { bookType: 'xlsx', type: 'array' }) : XLSX.utils.sheet_to_csv(worksheet);

        // save the file
        saveAs(new Blob([fileBlob], { type: 'application/octet-stream' }), exportFileName);
    };

    const handleFormatChange = (e) => {
        setFileType(e.target.value);
    };

    return (
        <div>
            <Box mb={2} mt={-1} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Select
                    value={fileType}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name={fileType}
                    onChange={handleFormatChange}
                    sx={{ width: '40%' }}
                >
                    <MenuItem value={'xlsx'} key={1}>
                        xlsx
                    </MenuItem>
                    <MenuItem value={'csv'} key={2}>
                        csv
                    </MenuItem>
                </Select>
                <Button variant="text" color="blue" onClick={downloadTemplate}>
                    Download Template
                </Button>
            </Box>
            <PerfectScrollbar style={{ overflow: 'scroll', height: 300 }}>
                <Box width={'100%'}>
                    <DropzoneArea
                        filesLimit={1}
                        dropzoneText="Déposer un fichier CSV/XLSX ici"
                        onChange={handleFilesChange}
                        maxFileSize={5000000}
                    />
                </Box>
                {data2Insert && (
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography mt={2} variant="h5" fontWeight={800}>
                            {data2Insert?.data?.length} records are been imported...
                        </Typography>

                        {loading === false && (
                            <Button variant="text" color="blue" onClick={fetchLogs}>
                                Fetch logs
                            </Button>
                        )}
                    </Box>
                )}

                {((data2Insert && data2Insert?.warnings?.length !== 0) || logs?.length !== 0) && (
                    <Box mt={2}>
                        {logs?.map((el, i) => (
                            <Box mb={2} key={i}>
                                <ServerMSG message={el.success === 1 ? 'No Logs' : formatLogs(el)} />
                            </Box>
                        ))}
                        {data2Insert?.warnings?.map((el, i) => (
                            <Box mb={2} key={i}>
                                <ServerMSG message={el?.message} />
                            </Box>
                        ))}
                    </Box>
                )}
            </PerfectScrollbar>
            {logs?.length === 0 && (
                <LoadingButton
                    disabled={files?.length === 0}
                    loading={loading}
                    sx={{ mt: 3, width: '100%' }}
                    onClick={handleUpload}
                    variant="contained"
                    color="blue"
                    size="large"
                >
                    Démarrer l'importation
                </LoadingButton>
            )}

            <Button sx={{ mt: 2, width: '100%' }} onClick={handleClose} variant="outlined" color="blue" size="large">
                Fermer
            </Button>
        </div>
    );
};

export default DataImporter;
