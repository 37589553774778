import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import TemplateProdForm from './TemplateProdForm';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';

const ProducDetails = ({ doc, isTemplate, editToggled, storedFiles }) => {
    // formatting the Request DATA
    // >>>
    const itemGroupsReqData = {
        doctype: 'Item Group',
        fields: ['`tabItem Group`.`name`'],
        start: 0,
        page_length: 500
    };

    const brandReqData = {
        doctype: 'Brand',
        fields: ['`tabBrand`.`name`'],
        start: 0,
        page_length: 500
    };

    const itemTaxReqData = {
        doctype: 'Item Tax Template',
        fields: ['`tabItem Tax Template`.`name`'],
        start: 0,
        page_length: 500
    };

    const itemAttributesReqData = {
        doctype: 'Item Attribute',
        fields: ['`tabItem Attribute`.`name`'],
        start: 0,
        page_length: 500
    };

    const companiesReqData = {
        doctype: 'Company',
        fields: ['`tabCompany`.`name`'],
        start: 0,
        page_length: 500
    };

    const priceListsReqData = {
        doctype: 'Price List',
        fields: ['`tabPrice List`.`name`'],
        start: 0,
        page_length: 500
    };

    const warehouseReqData = {
        doctype: 'Warehouse',
        fields: ['`tabWarehouse`.`name`', '`tabWarehouse`.`company`'],
        start: 0,
        page_length: 500
    };

    const itemCollectionReqData = {
        doctype: 'Item Collection',
        fields: ['`tabItem Collection`.`name`'],
        start: 0,
        page_length: 500
    };
    // fetching DATA
    // >>>
    const { data: itemGroups, isPending } = useFetch(`/api/get-list`, itemGroupsReqData, 'POST');
    const { data: companies, isCompaniesPending } = useFetch(`/api/get-list`, companiesReqData, 'POST');
    const { data: warehouses, isWarehousesPending } = useFetch(`/api/get-list`, warehouseReqData, 'POST');
    const { data: brands, isBrandsPending } = useFetch(`/api/get-list`, brandReqData, 'POST');
    const { data: itemTaxTemplates, isTaxesPending } = useFetch(`/api/get-list`, itemTaxReqData, 'POST');
    const { data: priceLists, isPriceListsPending } = useFetch(`/api/get-list`, priceListsReqData, 'POST');
    const { data: itemAttributes, isAttributesPending } = useFetch(`/api/get-list`, itemAttributesReqData, 'POST');
    const { data: itemCollections, isitemCollectionsPending } = useFetch(`/api/get-list`, itemCollectionReqData, 'POST');
    if (
        isPriceListsPending ||
        isAttributesPending ||
        isTaxesPending ||
        isBrandsPending ||
        isWarehousesPending ||
        isCompaniesPending ||
        isitemCollectionsPending ||
        isPending
    )
        return <Loader />;

    return (
        <div>
            {doc?.disabled === 1 ? (
                <Box display={'flex'} mt={-4} mb={2}>
                    <InfoIcon sx={{ mt: 0.6 }} color="error" />
                    <Typography p={1} variant="h5" color="error">
                        Ce produit est archivé, il n'apparaîtra donc pas dans les systèmes de POS.
                    </Typography>
                </Box>
            ) : (
                isTemplate && (
                    <Box display={'flex'} mt={-4} mb={2}>
                        <InfoIcon sx={{ mt: 0.6 }} />
                        <Typography p={1} variant="h5" color="primary">
                            Ce produit est un modèle et ne peut pas être utilisé dans les transactions. Les attributs du produit seront
                            copiés dans les variantes.
                        </Typography>
                    </Box>
                )
            )}
            <Box p={5} sx={{ borderRadius: 2, bgcolor: 'Menu' }}>
                <TemplateProdForm
                    doc={doc}
                    isSimple={!isTemplate}
                    editToggled={editToggled}
                    itemGroups={itemGroups}
                    companies={companies}
                    warehouses={warehouses}
                    brands={brands}
                    itemTaxTemplates={itemTaxTemplates}
                    itemAttributes={itemAttributes}
                    priceLists={priceLists}
                    storedFiles={storedFiles}
                    itemCollections={itemCollections}
                />
            </Box>
        </div>
    );
};

export default ProducDetails;
