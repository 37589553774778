import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, Grid, Modal } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import { useStyles } from 'views/utilities/Style';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import useGetAxios from 'hooks/useGetAxios';
import Loader from 'ui-component/Loader';
import EditIcon from '@mui/icons-material/Edit';
import useFetch from 'hooks/useFetch';
import RenameForm from '../../ui-component/form/RenameForm';
import { useTranslation } from 'react-i18next';

function AddSalesPerson() {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { t } = useTranslation();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [isEnabled, setIsEnabled] = useState(false);
    const [salesPersonName, setSalesPersonName] = useState('');
    const handleChange = (e) => setSalesPersonName(e.target.value);
    const [loadingButton, setLoadingButton] = useState(false);

    const [open, setOpen] = useState(false);
    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Sales%20Person&name=${id}`);
    const countIsParentReqData = {
        doctype: 'Sales Person',
        fields: ['`tabSales Person`.`name`'],
        filters: [['Sales Person', 'parent_sales_person', '=', id]]
    };
    const { data: count, isPending: isPendingcount } = useFetch(`api/get-list`, countIsParentReqData, 'POST');
    const newSalesPerson = {
        docstatus: 0,
        ...(id && {
            creation: doc.creation,
            modified: doc.modified,
            owner: doc.owner,
            modified_by: doc.owner,
            name: doc.name
        }),
        doctype: 'Sales Person',
        is_group: 0,
        sales_person_name: salesPersonName,
        enabled: isEnabled ? 1 : 0
    };

    const [state_rename, setState_rename] = useState({
        doctype: 'Sales Person',
        docname: id,
        new_name: id,
        enqueue: true,
        merge: 0
    });
    const handleClose = () => {
        setOpen(false);
        setState_rename({ doctype: 'Sales Person', docname: id, new_name: id, enqueue: true, merge: 0 });
    };
    const handleAddSalesPerson = async () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: newSalesPerson, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(
                        SetNotification({
                            code: 'success',
                            message: id ? 'Le vendeur a été modifiée avec succès' : 'Le vendeur a été créée avec succès'
                        })
                    );
                    navigate('/core/vendeurs');
                }
            });
    };
    useEffect(() => {
        if (id && !Array.isArray(doc) && doc) {
            setSalesPersonName(doc.sales_person_name);
            setIsEnabled(doc.enabled == 1 ? true : false);
        }
    }, [id, doc]);

    if (isPending && isPendingcount) return <Loader />;
    return (
        <div>
            <Box>
                <MainCard
                    title={
                        <Box display={'flex'} flexDirection={'column'}>
                            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                            <Box display={'flex'} flexWrap="wrap">
                                {id ? `${t('core:asp')}: ${id}` : t('core:newsp')}
                                {id && <EditIcon sx={{ fontSize: 17, ml: 1, cursor: 'pointer' }} onClick={() => setOpen(true)} />}
                            </Box>
                        </Box>
                    }
                >
                    <Box>
                        <FormControlLabel
                            sx={{
                                display: 'flex',
                                justifyContent: 'left'
                            }}
                            label={t('core:enabled')}
                            control={<Checkbox color="blue" checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)} />}
                        />

                        {!id && (
                            <InputTextField
                                sx={{ mt: 0 }}
                                error={salesPersonName === ''}
                                handleChange={handleChange}
                                value={salesPersonName}
                                required
                                label={t('core:spname')}
                                id="outlined-size-small"
                                size="small"
                            />
                        )}
                    </Box>
                    <Grid container xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Grid item xs={12}>
                            <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                                <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                                    {t('common:cancel')}
                                </Button>
                                <LoadingButton
                                    disabled={salesPersonName === ''}
                                    onClick={handleAddSalesPerson}
                                    size="large"
                                    variant="contained"
                                    color="blue"
                                    loading={loadingButton}
                                >
                                    {t('common:save')}
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </MainCard>
            </Box>
            <Modal onClose={handleClose} open={open}>
                <RenameForm
                    state={state_rename}
                    setState={setState_rename}
                    list={'/core/vendeurs'}
                    message_success={t('core:sprenamed')}
                    title={t('core:renamesp')}
                />
            </Modal>
        </div>
    );
}

export default AddSalesPerson;
