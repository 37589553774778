import React from 'react';
import { InputLabel, OutlinedInput, Grid, InputAdornment, Typography, Box } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import InfoIcon from '@mui/icons-material/Info';

const InputTextField = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const {
        handleChange,
        label,
        value,
        name,
        error,
        type,
        required,
        sx,
        fullwidth,
        isCurrency,
        currency,
        isPercentage,
        helper,
        placeholder,
        disabled = false,
        multiline,
        rows
    } = props;
    return (
        <Grid
            container
            item
            xs={!fullwidth && 12}
            sm={!fullwidth && 12}
            md={!fullwidth && 8}
            lg={!fullwidth && 8}
            xl={!fullwidth && 8}
            spacing={4}
            display={'flex'}
            alignItems={'center'}
            sx={sx}
        >
            <Grid item xs={4}>
                <InputLabel error={error ?? error} required={required} className={classes.inputLabel}>
                    {label}
                </InputLabel>
            </Grid>
            <Grid item xs={8}>
                <OutlinedInput
                    id="outlined-adornment-weight"
                    endAdornment={
                        isCurrency ? (
                            <InputAdornment position="end">
                                <Typography variant="h5" fontWeight={600} color={theme.palette.blue.main}>
                                    {currency ? currency : globalDefaults.default_currency}
                                </Typography>
                            </InputAdornment>
                        ) : isPercentage ? (
                            <InputAdornment position="end">
                                <Typography variant="h5" fontWeight={600} color={theme.palette.blue.main}>
                                    %
                                </Typography>
                            </InputAdornment>
                        ) : (
                            <></>
                        )
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                        'aria-label': 'weight'
                    }}
                    value={value}
                    error={error ?? error}
                    name={name}
                    onChange={handleChange}
                    type={type ?? type}
                    placeholder={placeholder ?? placeholder}
                    disabled={disabled}
                    multiline={multiline ?? multiline}
                    rows={rows ?? rows}
                />
                {helper && (
                    <Box display={'flex'} alignItems={'center'}>
                        <InfoIcon sx={{ fontSize: 14 }} color={error ? 'error' : 'blue'} />
                        <Typography color={error ? 'error' : 'primary'} sx={{ fontSize: 12, ml: 0.5 }}>
                            {helper}
                        </Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default InputTextField;
