//react
import React, { useState } from 'react';
//component imports
import DataRender from 'ui-component/tables/DataRender';
import MainCard from 'ui-component/cards/MainCard';
import useFetch from 'hooks/useFetch';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';

//mui
import { Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

//styles
import { useTheme } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { modalStyle } from 'views/utilities/ModalStyle';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import DataImporter from 'views/products/productManagement/components/DataImporter';
import ModalForm from 'ui-component/form/ModalForm';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

const CustomerList = () => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();

    const [selectionModel, setSelectionModel] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [open, setOpen] = useState(false);
    const [modals, setModals] = useState(0);
    const [loadingButtonForm, setLoadingButtonForm] = React.useState(false);
    const [openMiniForm, setOpenMiniForm] = React.useState(false);
    const handleOpenForm = () => setOpenMiniForm(true);
    const handleCloseForm = () => setOpenMiniForm(false);
    const handleOpen = (modal) => {
        setOpen(true);
        setModals(modal);
    };
    const handleClose = () => setOpen(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const customerReqData = {
        doctype: 'Customer',
        fields: [
            '`tabCustomer`.`name`',
            '`tabCustomer`.`customer_group`',
            '`tabCustomer`.`territory`',
            '`tabCustomer`.`customer_name`',
            '`tabCustomer`.`customer_type`',
            '`tabCustomer`.`mobile_no`'
        ],
        filters: [['Customer', 'disabled', '=', 0]],
        order_by: '`tabCustomer`.`modified` desc'
    };
    const { data, isPending, refetch } = useFetch(`/api/get-list`, customerReqData, 'POST');

    const columnsImport = ['Full Name', 'Type', 'Customer Group', 'Territory', 'Mobile No', 'Email Id', 'Primary Address', 'Salutation'];

    const handleEdit = (row) => {
        navigate(`/sales/customer-details/${row}`);
    };
    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Customer', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ message: 'Client supprimée avec succès', code: 'success' }));
                    handleClose();
                    refetch({});
                }
            });
    };

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={'Confirmer'}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    Supprimer définitivement <strong>{selectionModel}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                    Non
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleDelete(selectionModel)}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    Oui
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const dataImporter = (
        <Box sx={modalStyle}>
            <MainCard divider title={'Import'}>
                <DataImporter refetch={refetch} handleClose={handleClose} columns={columnsImport} doctype="Customer" />
            </MainCard>
        </Box>
    );

    const addCustomer = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('crm:addcustomer')}>
                <ModalForm
                    handleClose={handleCloseForm}
                    loadingButton={loadingButtonForm}
                    setLoadingButton={setLoadingButtonForm}
                    refetch={refetch}
                />
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            {t('crm:customers')}
            <Box>
                <Grid container spacing={1}>
                    {false && (
                        <>
                            <Grid item>
                                {selectionModel.length === 1 && (
                                    <Button variant="outlined" color="error" onClick={() => handleOpen(1)} sx={{ marginTop: -3 }}>
                                        Delete
                                    </Button>
                                )}
                            </Grid>
                            <Grid item>
                                {selectionModel.length === 1 && (
                                    <Button
                                        variant="outlined"
                                        color="blue"
                                        sx={{ marginTop: -3 }}
                                        onClick={() => handleEdit(selectionModel)}
                                    >
                                        Details
                                    </Button>
                                )}
                            </Grid>
                        </>
                    )}
                    <Grid item>
                        <Button
                            onClick={handleOpenForm}
                            startIcon={<AddCircleIcon />}
                            variant="outlined"
                            color="blue"
                            sx={{ marginTop: -3 }}
                        >
                            {t('crm:addcustomer')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="text" color="blue" onClick={() => handleOpen(2)} sx={{ marginTop: -3 }}>
                            Import
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

    return (
        <div>
            <DataRender
                refetch={refetch}
                isViewAction
                isPrintable
                data={data}
                loading={isPending}
                selectionModel={selectionModel}
                title={title}
                setSelectionModel={setSelectionModel}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
            />
            <Modal onClose={handleClose} open={open}>
                {modals === 1 ? deleteConfirm : modals === 2 ? dataImporter : <div>Oops</div>}
            </Modal>
            <Modal open={openMiniForm} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                {addCustomer}
            </Modal>
        </div>
    );
};

export default CustomerList;
