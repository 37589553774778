import { useStyles } from 'views/utilities/Style';
import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, InputLabel, Modal, Typography, useMediaQuery, useTheme } from '@mui/material';
import InputTextField from 'ui-component/form/InputTextField';
import { modalStyle } from 'views/utilities/ModalStyle';
import useFetch from 'hooks/useFetch';
import SelectForm from 'ui-component/form/SelectForm';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { frLocal } from 'utils/dataGridFRLocal';
import { LoadingButton } from '@mui/lab';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import Loader from 'ui-component/Loader';
import GeneralForm from 'ui-component/form/GeneralForm';
import { Box } from '@mui/system';
import useGetAxios from 'hooks/useGetAxios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FiltreForm from 'ui-component/form/FiltreForm';
import { GlobalfilterData } from '../../../helpers/helper';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

const useStylesgrid = makeStyles({
    toselected: {
        color: '#43AB00',
        backgroundColor: '#7050F2',
        fontWeight: 'bold'
    },
    todiscarded: {
        color: '#cc0000',
        backgroundColor: '#7050F2',
        fontWeight: 'bold'
    }
});
const ItemsGrid = (props) => {
    const { columns, items, selectionItemsModel, setSelectionItemsModel, isItemsPending, formErrState, labelItems, labelSelection } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const classesgrid = useStylesgrid();
    let theme = useTheme();
    let isSmallScreen = useMediaQuery(theme.breakpoints.down('1200'));
    let isXSmallScreen = useMediaQuery(theme.breakpoints.down('780'));
    let isXxSmallScreen = useMediaQuery(theme.breakpoints.down('560'));
    const [selectedItems, setSelecteditems] = useState([]);
    const [discardedItems, setDiscardeditems] = useState([]);
    const [filtre, setFiltre] = useState('');
    const [selectrow, setSelectrow] = useState(null);
    const [rowclick, setRowclick] = useState(null);
    const [itemsfiltred, setItemsfiltred] = useState([]);
    const [paginationModelselected, setPaginationModelselected] = useState({
        pageSize: 6,
        page: 0
    });
    const [paginationModeldiscarded, setPaginationModeldiscarded] = useState({
        pageSize: 6,
        page: 0
    });

    useEffect(() => {
        var tabselected = [];
        var tabdiscarded = [];
        items?.forEach((item) => {
            const el = selectionItemsModel?.filter((element) => element == item?.name);
            if (el.length > 0) tabselected.push(item);
            else tabdiscarded.push(item?.name);
        });
        setSelecteditems(tabselected);
        setDiscardeditems(tabdiscarded);
    }, [selectionItemsModel]);
    useEffect(() => {
        if (filtre) {
            setItemsfiltred(
                GlobalfilterData(
                    items,
                    filtre,
                    columns?.map((col) => col.field)
                )
            );
        } else {
            setItemsfiltred([]);
        }
    }, [filtre]);
    const handleFilterChange = (model) => {
        if (model.quickFilterValues && model?.quickFilterValues?.length > 0) {
            setFiltre(model?.quickFilterValues[0]);
        } else if (model?.quickFilterValues?.length == 0) {
            setFiltre('');
        }
    };
    const handleEventAdd = (event) => {
        setSelectrow(event.id);
        setRowclick('add');
        if (event.rowIndex === -1) {
            // Disable selection when clicking away from rows
            event.event.preventDefault();
        }
        //  setSelectionItemsModel([...selectionItemsModel, event.id]);
    };
    const handleEventRm = (event) => {
        setSelectrow(event.id);
        setRowclick('rm');
        if (event.rowIndex === -1) {
            // Disable selection when clicking away from rows
            event.event.preventDefault();
        }
        // setSelectionItemsModel(selectionItemsModel?.filter((el) => el !== event.id));
    };
    const updateselection = () => {
        if (rowclick == 'rm') {
            setSelectionItemsModel(selectionItemsModel?.filter((el) => el !== selectrow));
        }
        if (rowclick == 'add') {
            setSelectionItemsModel([...selectionItemsModel, selectrow]);
        }
        setSelectrow(null);
        setRowclick(null);
    };
    const NoRowsOverlay = () => {
        return (
            <Stack backgroundColor="#fff" marginLeft="-100%" height="100%" width="80%" alignItems="center" justifyContent="center">
                <pre>{t('common:noRows')}</pre>
            </Stack>
        );
    };
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ flexDirection: { xs: 'column', lg: 'row' } }}>
            <Grid item align="center" sx={{ width: isSmallScreen ? '100%' : '45%' }}>
                <InputLabel required style={{ textAlign: 'left' }} className={classes.inputLabel}>
                    {labelItems}
                </InputLabel>
                <br />
                <Box
                    height={600}
                    width={'100%'}
                    sx={{
                        borderRadius: '16px',
                        border: '5px solid #fff',
                        boxSizing: 'border-box',
                        overflow: 'auto'
                    }}
                >
                    <DataGrid
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    name: false
                                }
                            }
                        }}
                        filterModel={{
                            items: [{ field: 'name', operator: 'isAnyOf', value: discardedItems }],
                            linkOperator: 'and'
                        }}
                        rows={selectionItemsModel?.length == items?.length ? [] : !filtre ? items : itemsfiltred}
                        columns={columns}
                        getRowId={(row) => row.name}
                        getRowClassName={(params) => {
                            return params.id === selectrow && rowclick === 'add' ? classesgrid.toselected : '';
                        }}
                        paginationModel={paginationModelselected}
                        onPaginationModelChange={setPaginationModelselected}
                        onRowClick={handleEventAdd}
                        loading={isItemsPending}
                        onFilterModelChange={handleFilterChange}
                        hideFooterSelectedRowCount
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                                quickFilterProps: { debounceMs: 500 }
                            }
                        }}
                        disableColumnFilter
                        disableColumnMenu
                        disableColumnSelector
                        disableDensitySelector
                        /* disableClickEventBubbling
                        disableSelectionOnClick */
                    />
                </Box>
            </Grid>
            <br />
            <br />
            <Grid
                item
                align="center"
                justifyContent="center"
                xs={isSmallScreen ? 0 : 1}
                direction="row"
                container
                padding={isSmallScreen ? '0%' : '3%'}
            >
                <Grid item>
                    <Button
                        variant="contained"
                        disabled={!rowclick || rowclick == 'rm'}
                        onClick={updateselection}
                        // color="#0766FF"
                        sx={{ fontSize: isSmallScreen ? '180%' : '300%' }}
                        style={isSmallScreen ? { transform: 'rotate(90deg)' } : {}}
                    >
                        <ArrowForwardIosIcon sx={{ color: !rowclick || rowclick == 'rm' ? '#444444' : '#fff' }} />
                    </Button>
                </Grid>
                <br />
                <br />
                <Grid item>
                    <Button
                        variant="contained"
                        disabled={!rowclick || rowclick == 'add'}
                        onClick={updateselection}
                        sx={{ fontSize: isSmallScreen ? '200%' : '250%' }}
                        style={isSmallScreen ? { transform: 'rotate(90deg)' } : {}}
                    >
                        <ArrowBackIosIcon sx={{ color: !rowclick || rowclick == 'add' ? '#444444' : '#fff' }} />
                    </Button>
                </Grid>
            </Grid>
            <br />
            <br />
            <Grid item align="center" sx={{ width: isSmallScreen ? '100%' : '45%' }}>
                <InputLabel
                    required
                    error={formErrState?.items && selectionItemsModel.length < 1}
                    style={{ textAlign: 'left' }}
                    className={classes.inputLabel}
                >
                    {labelSelection}
                </InputLabel>
                <br />
                <Box
                    height={600}
                    width={'100%'}
                    sx={{
                        borderRadius: '16px',
                        border: '5px solid #fff',
                        boxSizing: 'border-box',
                        overflow: 'auto'
                    }}
                >
                    <DataGrid
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    name: false
                                }
                            }
                        }}
                        components={{ /* Toolbar, */ NoRowsOverlay /* y, NoResultsOverlay */ }} // localeText={frLocal}
                        rows={selectedItems ?? selectedItems}
                        getRowClassName={(params) => {
                            return params.id === selectrow && rowclick === 'rm' ? classesgrid.todiscarded : '';
                        }}
                        columns={columns ?? columns}
                        getRowId={(row) => row?.name} // localeText={frLocal}
                        paginationModel={paginationModeldiscarded}
                        onPaginationModelChange={setPaginationModeldiscarded}
                        onRowClick={handleEventRm}
                        hideFooterSelectedRowCount
                        disableColumnFilter
                        disableColumnMenu
                        disableColumnSelector
                        disableDensitySelector
                        disableClickEventBubbling
                    />
                </Box>
            </Grid>
        </Grid>
    );
};
export default ItemsGrid;
