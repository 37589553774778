// assets
import { ROLE } from '../configs/constants';
import CardMembershipRoundedIcon from '@mui/icons-material/CardMembershipRounded';

// constant
const icons = {
    CardMembershipRoundedIcon
};

// ==============================|| USERS MENU ITEMS ||============================== //

const loyalty = {
    id: 'loyatly',
    type: 'group',
    role: [ROLE.ADMIN],
    children: [
        {
            id: 'loyatly-menu',
            title: 'crm:lyltysec',
            type: 'collapse',
            icon: icons.CardMembershipRoundedIcon,
            children: [
                {
                    id: 'loyatly-programs',
                    title: 'crm:lps',
                    type: 'item',
                    url: '/crm/loyatly-programs'
                },
                {
                    id: 'loyatly-cards',
                    title: 'crm:lyltycard',
                    type: 'item',
                    url: '/crm/loyatly-cards'
                }
            ]
        }
    ]
};

export default loyalty;
