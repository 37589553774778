import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import MainCard from 'ui-component/cards/MainCard';
import TreeView from '@mui/lab/TreeView';
import { Button, ButtonGroup, Typography, Modal, FormControlLabel, Checkbox, TextField, Grid, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import useFetch from 'hooks/useFetch';
import ViewListIcon from '@mui/icons-material/ViewList';
import Loader from 'ui-component/Loader';
import { MinusSquare, PlusSquare, CloseSquare, StyledTreeItem } from 'ui-component/treeComponents';
import { modalStyle } from 'views/utilities/ModalStyle';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { formatErpResponse } from 'utils/utils';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export default function SalesPersonTree() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const salesPersonReqData = {
        doctype: 'Sales Person',
        fields: [
            '`tabSales Person`.`name`',
            '`tabSales Person`.`creation`',
            '`tabSales Person`.`modified`',
            '`tabSales Person`.`sales_person_name`',
            '`tabSales Person`.`parent_sales_person`',
            '`tabSales Person`.`is_group`',
            '`tabSales Person`.`enabled`'
        ],
        start: 0,
        page_length: 500
    };

    const { data, isPending, refetch } = useFetch(`/api/get-list`, salesPersonReqData, 'POST');
    const [root, setRoot] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [isGroup, setIsGroup] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [salesPersonName, setSalesPersonName] = useState('');
    const handleChange = (e) => setSalesPersonName(e.target.value);
    const [loadingButton, setLoadingButton] = useState(false);

    const [newName, setNewName] = useState('');
    const handleChangeNewName = (e) => setNewName(e.target.value);

    const newSalesPerson = {
        doctype: 'Sales Person',
        is_group: isGroup ? 1 : 0,
        sales_person_name: salesPersonName,
        enabled: isEnabled ? 1 : 0,
        parent_sales_person: selectedRow
    };

    const handleAddVendeur = async () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: newSalesPerson, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Vendur créée avec succès' }));
                    handleClose();
                    refetch({});
                }
            });
    };

    const handleDelete = () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Sales Person', name: selectedRow })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Le Vendeur a été supprimée avec succès' }));
                    handleClose();
                    refetch({});
                }
            });
    };

    const renameSalesName = {
        doctype: 'Sales Person',
        docname: selectedRow,
        name: newName,
        enqueue: true,
        merge: 0
    };

    const handleRename = () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/rename-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(renameSalesName)
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                dispatch(SetNotification({ code: 'success', message: 'Vendeur renommée avec succès' }));
                handleClose();
                refetch({});
            });
    };

    const addForm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('core:newsp')}>
                <FormControlLabel
                    sx={{ mt: -2 }}
                    label={t('core:enabled')}
                    control={<Checkbox checked={isEnabled} color="blue" onChange={() => setIsEnabled(!isEnabled)} />}
                />
                <FormControlLabel
                    sx={{ mt: -2 }}
                    label={t('common:group')}
                    control={<Checkbox checked={isGroup} color="blue" onChange={() => setIsGroup(!isGroup)} />}
                />
                <TextField
                    onChange={handleChange}
                    value={salesPersonName}
                    required
                    sx={{ width: '100%', mt: 3 }}
                    label={t('core:spname')}
                    id="outlined-size-small"
                    size="small"
                />
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <LoadingButton
                        disabled={salesPersonName === ''}
                        onClick={handleAddVendeur}
                        variant="contained"
                        color="blue"
                        size="large"
                        loading={loadingButton}
                    >
                        {t('common:save')}
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );

    const renameForm = (
        <Box sx={modalStyle}>
            <MainCard divider title={`${t('common:rename')} "${selectedRow}"`}>
                <TextField
                    onChange={handleChangeNewName}
                    value={newName}
                    required
                    sx={{ width: '100%' }}
                    label={t('core:newspname')}
                    id="outlined-size-small"
                    size="small"
                />
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <LoadingButton variant="contained" color="blue" size="large" disabled={newName === ''} onClick={handleRename}>
                        {t('common:rename')}
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={'Confirmer'}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    Supprimer définitivement <strong>{selectedRow}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Button color="blue" size="large" variant="outlined" sx={{ mr: 1 }} onClick={handleClose}>
                        Non
                    </Button>
                    <LoadingButton variant="contained" color="blue" size="large" onClick={handleDelete} loading={loadingButton}>
                        Oui
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );

    const getRoot = (data) => {
        return data.filter((el) => el.parent_sales_person == null);
    };

    const getChildren = (parentName) => {
        return data.filter((el) => el.parent_sales_person == parentName);
    };

    const handleClick = (action) => {
        setAction(action);
        handleOpen();
    };

    const handleDetails = (row) => {
        navigate(`/core/vendeur/${row.name}`);
    };

    const handleSelect = (e) => {
        setSelectedRow(e.target.textContent);
    };

    useEffect(() => {
        if (data) {
            setRoot(getRoot(data));
        }
    }, [data, open]);

    function getTreeItemLabel(row) {
        return (
            <Box sx={{ display: 'flex', p: 0.5, pr: 0, height: 40, alignItems: 'center' }}>
                <Typography
                    variant="h5"
                    onClick={(e) => handleSelect(e)}
                    sx={{ flexGrow: 1, fontWeight: selectedRow === row.name ? 600 : 500 }}
                >
                    {row.name}
                </Typography>
                {selectedRow === row.name && (
                    <ButtonGroup
                        sx={{ borderRadius: 10, border: 'none', boxShadow: 'none' }}
                        variant="contained"
                        size="small"
                        color="blue"
                        aria-label="outlined primary button group"
                    >
                        <Button
                            disabled={getRoot(data)?.[0]?.name === selectedRow}
                            color="blue"
                            sx={{ fontSize: 11 }}
                            onClick={() => handleDetails(row)}
                        >
                            {t('common:edit')}
                        </Button>
                        {row.is_group === 1 && (
                            <Button color="blue" sx={{ fontSize: 11 }} onClick={() => handleClick('add')}>
                                {t('common:addchild')}
                            </Button>
                        )}
                        <Button
                            disabled={getRoot(data)?.[0]?.name === selectedRow}
                            color="blue"
                            sx={{ fontSize: 11 }}
                            onClick={() => handleClick('rename')}
                        >
                            {t('common:rename')}
                        </Button>
                        <Button
                            disabled={getRoot(data)?.[0]?.name === selectedRow}
                            color="error"
                            sx={{ fontSize: 11 }}
                            onClick={() => handleClick('remove')}
                        >
                            {t('common:delete')}
                        </Button>
                    </ButtonGroup>
                )}
            </Box>
        );
    }

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Grid>
                {t('core:sptree')}
                <IconButton
                    aria-haspopup="true"
                    onClick={() => navigate('/core/vendeurs')}
                    color="inherit"
                    aria-label="cart"
                    variant="rounded"
                    size="small"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: 'transparent !important',
                        color: theme.palette.primary.dark,
                        borderRadius: 50,
                        height: 20,
                        ml: 2,
                        mt: -0.5
                    }}
                >
                    <ViewListIcon
                        sx={{
                            height: 25
                        }}
                        color="blue"
                    />
                </IconButton>
            </Grid>
        </Box>
    );

    if (isPending) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                <Box>
                    <TreeView
                        defaultExpanded={['1']}
                        aria-label="file system navigator"
                        defaultCollapseIcon={<MinusSquare />}
                        defaultExpandIcon={<PlusSquare />}
                        defaultEndIcon={<CloseSquare />}
                        sx={{ flexGrow: 1, overflowY: 'auto' }}
                    >
                        {root.map((row, i) => (
                            <StyledTreeItem key={i} nodeId={row.name} label={getTreeItemLabel(row)}>
                                {getChildren(row.name).map((subRows, idx) => (
                                    <StyledTreeItem key={idx} nodeId={subRows.name} label={getTreeItemLabel(subRows)}>
                                        {getChildren(subRows.name).map((xsRow, index) => (
                                            <StyledTreeItem key={index} nodeId={xsRow.name} label={getTreeItemLabel(xsRow)}>
                                                {getChildren(xsRow.name).map((xxsRow, ix) => (
                                                    <StyledTreeItem
                                                        key={ix}
                                                        nodeId={xxsRow.name}
                                                        label={getTreeItemLabel(xxsRow)}
                                                    ></StyledTreeItem>
                                                ))}
                                            </StyledTreeItem>
                                        ))}
                                    </StyledTreeItem>
                                ))}
                            </StyledTreeItem>
                        ))}
                    </TreeView>
                </Box>
            </MainCard>
            <Modal onClose={handleClose} open={open}>
                {action == 'add' ? addForm : action == 'remove' ? deleteConfirm : action == 'rename' ? renameForm : <div>ERROR</div>}
            </Modal>
        </Box>
    );
}
