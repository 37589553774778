// assets
import { IconKey, IconUsers } from '@tabler/icons';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    IconKey,
    IconUsers
};

// ==============================|| USERS MENU ITEMS ||============================== //

const users = {
    id: 'users',
    type: 'group',
    role: [ROLE.ADMIN, ROLE.CASHIER],
    children: [
        {
            id: 'user',
            title: 'users:users',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'roles',
                    title: 'users:roleMenu',
                    type: 'item',
                    url: '/core/roles'
                },
                {
                    id: 'profiles',
                    title: 'users:Profil',
                    type: 'item',
                    url: '/core/profiles'
                },
                {
                    id: 'user-list-view',
                    title: 'users:users',
                    type: 'item',
                    url: '/core/user-list-view'
                },
                {
                    id: 'connected-user',
                    title: 'users:ConnectedUser',
                    type: 'item',
                    url: '/core/connected-user'
                }
            ]
        }
    ]
};

export default users;
