// action - state management
import * as actionTypes from '../actions';

export const initialState = {
    local: 'en'
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LOCAL:
            return {
                ...state,
                local: action.local
            };

        default:
            return state;
    }
};

export default themeReducer;
