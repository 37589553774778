import { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export function useBlocker(onConfirm, when = true) {
    const { navigator } = useContext(NavigationContext);
    useEffect(() => {
        if (!when) return;
        const unblock = navigator.block((tx) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                }
            };
            onConfirm(autoUnblockingTx);
        });
        return unblock;
    }, [navigator, when]);
}
