import { useTranslation } from 'react-i18next';

export const frLocal = {
    // Root
    noRowsLabel: 'Aucune Donnée',
    noResultsOverlayLabel: 'Aucun résultat trouvé.',

    // Density selector toolbar button text
    toolbarDensity: 'Densité',
    toolbarDensityLabel: 'Densité',
    toolbarDensityCompact: 'Compact',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Comfortable',

    // Columns selector toolbar button text
    toolbarColumns: 'Colonnes',
    toolbarColumnsLabel: 'Sélectionner les colonnes',

    // Filters toolbar button text
    toolbarFilters: 'Filtres',
    toolbarFiltersLabel: 'Afficher les filtres',
    toolbarFiltersTooltipHide: 'Masquer les filtres',
    toolbarFiltersTooltipShow: 'Afficher les filtres',
    toolbarFiltersTooltipActive: (count) => (count !== 1 ? `${count} filtres actifs` : `${count} filtre actif`),

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Recherche...',
    toolbarQuickFilterLabel: 'Recherche',
    toolbarQuickFilterDeleteIconLabel: 'Clair',

    // Export selector toolbar button text
    toolbarExport: 'Export',
    toolbarExportLabel: 'Export',
    toolbarExportCSV: 'Télécharger en CSV',
    toolbarExportPrint: 'Imprimer',
    toolbarExportExcel: 'Télécharger sous Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Trouver une colonne',
    columnsPanelTextFieldPlaceholder: 'Titre de la colonne',
    columnsPanelDragIconLabel: 'Réorganiser la colonne',
    columnsPanelShowAllButton: 'Afficher tous',
    columnsPanelHideAllButton: 'Cacher tout',

    // Filter panel text
    filterPanelAddFilter: 'Ajouter un filtre',
    filterPanelDeleteIconLabel: 'Supprimer',
    filterPanelLogicOperator: 'Opérateur logique',
    filterPanelOperator: 'Opérateur',
    filterPanelOperatorAnd: 'Et',
    filterPanelOperatorOr: 'Ou',
    filterPanelColumns: 'Colonnes',
    filterPanelInputLabel: 'Valeur',
    filterPanelInputPlaceholder: 'Valeur du filtre',

    // Filter operators text
    filterOperatorContains: 'contient',
    filterOperatorEquals: 'égale à',
    filterOperatorStartsWith: 'commence par',
    filterOperatorEndsWith: 'se termine par',
    filterOperatorIs: 'est',
    filterOperatorNot: "n'est pas",
    filterOperatorAfter: 'est après',
    filterOperatorOnOrAfter: 'est sur ou après',
    filterOperatorBefore: 'est avant',
    filterOperatorOnOrBefore: 'est le ou avant le',
    filterOperatorIsEmpty: 'est vide',
    filterOperatorIsNotEmpty: "n'est pas vide",
    filterOperatorIsAnyOf: "est l'un des éléments suivants",

    // Filter values text
    filterValueAny: 'tout',
    filterValueTrue: 'vrai',
    filterValueFalse: 'faux',

    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Afficher les colonnes',
    columnMenuManageColumns: 'Gérer les colonnes',
    columnMenuFilter: 'Filtre',
    columnMenuHideColumn: 'Masquer la colonne',
    columnMenuUnsort: 'Déclasser',
    columnMenuSortAsc: 'Trier par ASC',
    columnMenuSortDesc: 'Trier par DESC',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => (count !== 1 ? `${count} filtres actifs` : `${count} filtre actif`),
    columnHeaderFiltersLabel: 'Afficher les filtres',
    columnHeaderSortIconLabel: 'Trier',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1 ? `${count.toLocaleString()} lignes sélectionnées` : `${count.toLocaleString()} ligne sélectionnée`,

    // Total row amount footer text
    footerTotalRows: 'Total des lignes:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Sélection de cases à cocher',
    checkboxSelectionSelectAllRows: 'Sélectionner toutes les lignes',
    checkboxSelectionUnselectAllRows: 'Désélectionner toutes les lignes',
    checkboxSelectionSelectRow: 'Sélectionner une ligne',
    checkboxSelectionUnselectRow: 'Désélectionner une ligne',

    // Boolean cell text
    booleanCellTrueLabel: 'oui',
    booleanCellFalseLabel: 'non',

    // Actions cell more text
    actionsCellMore: 'plus',

    // Column pinning text
    pinToLeft: 'Broche vers la gauche',
    pinToRight: 'Broche vers la droite',
    unpin: 'Déplacer',

    // Tree Data
    treeDataGroupingHeaderName: 'Groupe',
    treeDataExpand: 'voir les enfants',
    treeDataCollapse: 'cacher les enfants',

    // Grouping columns
    groupingColumnHeaderName: 'Groupe',
    groupColumn: (name) => `Grouper par ${name}`,
    unGroupColumn: (name) => `Arrêter de regrouper par ${name}`,

    // Master/detail
    detailPanelToggle: 'Bascule du panneau de détail',
    expandDetailPanel: 'Élargir',
    collapseDetailPanel: 'Collapse',

    // Used core components translation keys
    MuiTablePagination: {},

    // Row reordering text
    rowReorderingHeaderName: 'Réorganisation des lignes',

    // Aggregation
    aggregationMenuItemHeader: 'Agrégation',
    aggregationFunctionLabelSum: 'somme',
    aggregationFunctionLabelAvg: 'moy',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'taille'
};
