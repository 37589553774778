// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_NOTIFICATION = '@notification/SET_NOTIFICATION';
export const FAILED_ADD_DATA = '@customization/FAILED_ADD_DATA';

// action - login reducer
export const SET_USER = '@login/SET_USER';
export const SET_GLOBAL_DEFAULTS = '@login/SET_GLOBAL_DEFAULTS';
export const SET_USER_DETAILS = '@login/SET_USER_DETAILS';
export const USER_LOGOUT = '@login/USER_LOGOUT';
export const SET_DETAILS_DEFAULTS_COMPANY = '@login/SET_DETAILS_DEFAULTS_COMPANY';

// action - pos profile reducer
export const SET_WAREHOUSES = '@core/SET_WAREHOUSES';

// action - company reducer
export const CREATE_COMPANY = '@company/CREATE_COMPANY';
export const CREATE_COMPANY_SUCCESS = '@company/CREATE_COMPANY_SUCCESS';

// action - pim reducer
export const SET_DOC_2_DUPLICATE = '@pim/SET_DOC_2_DUPLICATE';
export const SET_DETAILS_ITEMS_QUOTATION = '@pim/SET_DETAILS_ITEMS_QUOTATION';
export const SET_BULK_EDIT_IDS = '@pim/SET_BULK_EDIT_IDS';
export const SET_BULK_EDIT_FIELDS = '@pim/SET_BULK_EDIT_FIELDS';
export const AUTH_TOKEN = 'auth_token';
// theme
export const SET_LOCAL = 'SET_LOCAL';

// mapped docs reducer
export const SET_MAPPED_SALESORDER = '@mappedDocs/SET_MAPPED_SALESORDER';
export const SET_MAPPED_DELIVERYNOTE = '@mappedDocs/SET_MAPPED_DELIVERYNOTE';
export const SET_MAPPED_SALESINVOICE = '@mappedDocs/SET_MAPPED_SALESINVOICE';
export const GET_SELLING_SETTINGS = 'GET_SELLING_SETTINGS';
