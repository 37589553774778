import { LoadingButton } from '@mui/lab';
import { Checkbox, Divider, FormControl, FormControlLabel, FormLabel, Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import { formatErpResponse, isExistInDoc } from 'utils/utils';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { SetDoc2Duplicate, SetNotification } from 'store/services/api';
import { DropzoneArea } from 'react-mui-dropzone';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Loader from 'ui-component/Loader';
import { useTranslation } from 'react-i18next';
import InputCheckboxField from 'ui-component/form/InputCheckboxField';

const TemplateProdForm = ({
    storedFiles,
    doc,
    itemGroups,
    companies,
    warehouses,
    brands,
    itemTaxTemplates,
    itemAttributes,
    priceLists,
    isSimple,
    editToggled,
    toPrint,
    itemCollections
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const docToDuplicate = useSelector((state) => state.pim.docToDuplicate);
    const theme = useTheme();
    const [magasin, setMagasin] = React.useState([]);
    const [isWrhPending, setIsWrhPending] = React.useState(false);

    const [notDiscountable, setNotDiscountable] = useState(doc?.not_discountable ? doc?.not_discountable === 1 : false);
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    const [itemDefaults, setItemDefaults] = useState({
        doctype: 'Item Default',
        __islocal: 1,
        __unsaved: 1,
        company: globalDefaults.default_company,
        default_warehouse: '',
        parentfield: 'item_defaults',
        parenttype: 'Item',
        idx: 1,
        __unedited: false,
        default_price_list: ''
    });
    const [itemErrDefaults, setItemErrDefaults] = useState({
        default_warehouse: false,
        default_price_list: false
    });

    const [reorderLevels, setReorderLevels] = useState({
        docstatus: 0,
        doctype: 'Item Reorder',
        material_request_type: 'Purchase',
        parentfield: 'reorder_levels',
        parenttype: 'Item',
        warehouse: '',
        warehouse_reorder_level: doc
            ? doc?.reorder_levels?.find((x) => x.warehouse == doc?.item_defaults[0]?.default_warehouse)?.warehouse_reorder_level
            : 0,
        warehouse_reorder_qty: doc
            ? doc?.reorder_levels?.find((x) => x.warehouse == doc?.item_defaults[0]?.default_warehouse)?.warehouse_reorder_qty
            : 0
    });
    const [formState, setFormState] = useState({
        // fixed values
        doctype: 'Item',
        __islocal: 1,
        __unsaved: 1,
        disabled: 0,
        allow_alternative_item: 0,
        is_stock_item: 1,
        include_item_in_manufacturing: 1,
        is_fixed_asset: 0,
        auto_create_assets: 0,
        is_grouped_asset: 0,
        end_of_life: '2099-12-31',
        default_material_request_type: 'Purchase',
        valuation_method: '',
        allow_negative_stock: 0,
        has_batch_no: 0,
        create_new_batch: 0,
        has_expiry_date: 0,
        retain_sample: 0,
        has_serial_no: 0,
        min_order_qty: 0,
        is_purchase_item: 1,
        is_customer_provided_item: 0,
        delivered_by_supplier: 0,
        enable_deferred_expense: 0,
        grant_commission: 1,
        is_sales_item: 1,
        enable_deferred_revenue: 0,
        inspection_required_before_purchase: 0,
        inspection_required_before_delivery: 0,
        is_sub_contracted_item: 0,
        published_in_website: 0,
        max_discount: null,
        stock_uom: 'Nos',
        barcodes: [],
        weight_per_unit: null,
        // changable values
        variant_based_on: 'Item Attribute',
        create_variant: 0,
        item_template: '',
        has_variants: 1,
        attributes: [],
        item_defaults: [],
        taxes: [],
        description: '',
        brand: '',
        item_code: '',
        item_name: '',
        item_group: '',
        item_collection: '',
        opening_stock: 0,
        standard_rate: 0,
        valuation_rate: 0,
        reorder_levels: []
    });

    const [formErrState, setFormErrState] = useState({
        item_code: false,
        item_name: false,
        description: false,
        item_group: false,
        standard_rate: false,
        valuation_rate: false,
        //company: false,
        default_price_list: false,
        item_collection: false
    });

    const [loadingButton, setLoadingButton] = useState(false);

    const [files, setFiles] = useState([]);

    const [ItemsFiles, setItemsFiles] = useState([]);

    const [openDropZone, setOpenDropZone] = useState(false);

    // handle the change of the form states
    // >>>
    const handleItemDefaultsChange = (e) => {
        setItemDefaults({ ...itemDefaults, [e.target.name]: e.target.value });

        // err handlers
        if (e.target.value == '') {
            setItemErrDefaults({ ...itemErrDefaults, [e.target.name]: true });
        } else {
            setItemErrDefaults({ ...itemErrDefaults, [e.target.name]: false });
        }
        if (e.target.name == 'default_warehouse') {
            setReorderLevels({ ...reorderLevels, ['warehouse']: e.target.value });
        }
    };

    const handleChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });

        // err handlers
        if (e.target.value == '') {
            setFormErrState({ ...formErrState, [e.target.name]: true });
        } else {
            setFormErrState({ ...formErrState, [e.target.name]: false });
        }
    };
    const handleReorderLevelsChange = (e) => {
        setReorderLevels({ ...reorderLevels, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (event) => {
        const {
            target: { value }
        } = event;
        if (event.target.name == 'taxes') {
            setFormState({
                ...formState,
                taxes: [
                    {
                        doctype: 'Item Tax',
                        __islocal: 1,
                        __unsaved: 1,
                        parentfield: 'taxes',
                        parenttype: 'Item',
                        __unedited: false,
                        item_tax_template: value
                    }
                ]
            });
        } else {
            setFormState({ ...formState, [event.target.name]: value });
        }
        setFormErrState({ ...formErrState, [event.target.name]: false });
    };
    const [isChangedAttributes, setIsChangedAttributes] = useState(false);
    const handleAttributesChange = (e) => {
        let row = {
            docstatus: 0,
            doctype: 'Item Variant Attribute',
            __islocal: 1,
            __unsaved: 1,
            parentfield: 'attributes',
            parenttype: 'Item',
            __unedited: false,
            numeric_values: 0,
            from_range: 0,
            to_range: 0,
            increment: 0,
            attribute: e.target.name
        };
        let data = doc && editToggled && !isChangedAttributes ? [...doc?.attributes] : [...formState.attributes];
        setIsChangedAttributes(true);
        const rowIndex = data.findIndex((el) => el.attribute == e.target.name);
        if (rowIndex == -1 && e.target.checked) {
            data.push(row);
        } else if (rowIndex != -1 && !e.target.checked) {
            data = data.filter((el) => el.attribute != e.target.name);
        }
        setFormState({ ...formState, attributes: data });
    };

    const handleFilesChange = (files) => {
        setFiles(files);
    };

    // handle submitting the form & redirection
    // >>>
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const formVerif = () => {
        const hasErrsItemDefaults = {
            default_warehouse: !itemDefaults.default_warehouse || itemDefaults.default_warehouse === '',
            default_price_list: !itemDefaults.default_price_list || itemDefaults.default_price_list === ''
        };
        const hasErrsFormState = {
            item_code: !formState.item_code || formState.item_code === '',
            item_name: !formState.item_name || formState.item_name === '',
            description: !formState.description || formState.description === '',
            item_group: !formState.item_group || formState.item_group === '',
            standard_rate: !formState.standard_rate || formState.standard_rate === '' || formState.standard_rate <= 0,
            valuation_rate: !formState.standard_rate || formState.standard_rate === '' || formState.standard_rate <= 0,
            //company: false,
            item_collection: !formState.item_collection || formState.item_collection === ''
        };
        setItemErrDefaults(hasErrsItemDefaults);
        setFormErrState(hasErrsFormState);
        if (!JSON.stringify(hasErrsItemDefaults).includes('true') && !JSON.stringify(hasErrsFormState).includes('true')) {
            handleCreate(editToggled);
        }
    };

    const handleCreate = (editToggled) => {
        setLoadingButton(true);
        reorderLevels.warehouse = itemDefaults.default_warehouse;
        const data = {
            doc: {
                ...formState,
                not_discountable: notDiscountable ? 1 : 0,
                item_defaults: [itemDefaults],
                reorder_levels:
                    reorderLevels.warehouse_reorder_level !== 0 || reorderLevels.warehouse_reorder_qty !== 0 ? [reorderLevels] : undefined,
                ...(isSimple &&
                    !editToggled && { has_variants: 0, attributes: [], item_template: null, create_variant: 0, variant_based_on: null })
            },
            action: 'Save'
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    editToggled
                        ? dispatch(
                              SetNotification({ code: 'success', message: `Produit ${!isSimple ? 'modèle' : ''} sauvegardé avec succès` })
                          )
                        : dispatch(SetNotification({ code: 'success', message: `Produit ${!isSimple ? 'modèle' : ''} créé avec succès` }));

                    /*upload product files*/
                    if (files?.length !== 0) {
                        const header = {
                            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                            Authorization: JSON.parse(localStorage.getItem('user'))?.token
                        };
                        const formData = new FormData();
                        for (var x = 0; x < files?.length; x++) {
                            formData.append(`file_${x}`, files[x], files[x]?.name);
                        }
                        formData.append('doctype', 'Item');
                        formData.append('name', data.docs[0].name);

                        fetch(`${process.env.REACT_APP_API_URI}/api/upload-file`, {
                            method: 'POST',
                            headers: header,
                            body: formData
                        })
                            .then((res) => {})
                            .then((data) => {});
                    }

                    navigate('/pim/products-view');
                }
                dispatch(SetDoc2Duplicate(null));
            });
    };

    const renderSaveButton = () => {
        if (doc) {
            if (editToggled) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    useEffect(() => {
        if (editToggled && doc) {
            setNotDiscountable(doc?.not_discountable);
            // set doc values to the form state
            setFormState({
                doctype: 'Item',
                naming_series: doc.naming_series,
                // __islocal: 1,
                __unsaved: 1,
                creation: doc.creation,
                modified: doc.modified,
                owner: doc.owner,
                modified_by: doc.owner,
                name: doc.name,
                disabled: doc.disabled,
                allow_alternative_item: doc.allow_alternative_item,
                is_stock_item: doc.is_stock_item,
                include_item_in_manufacturing: doc.include_item_in_manufacturing,
                is_fixed_asset: doc.is_fixed_asset,
                auto_create_assets: doc.auto_create_assets,
                is_grouped_asset: doc.is_grouped_asset,
                end_of_life: doc.end_of_life,
                default_material_request_type: doc.default_material_request_type,
                valuation_method: doc.valuation_method,
                allow_negative_stock: doc.allow_negative_stock,
                has_batch_no: doc.has_batch_no,
                create_new_batch: doc.create_new_batch,
                has_expiry_date: doc.has_expiry_date,
                retain_sample: doc.retain_sample,
                has_serial_no: doc.has_serial_no,
                min_order_qty: doc.min_order_qty,
                is_purchase_item: doc.is_purchase_item,
                is_customer_provided_item: doc.is_customer_provided_item,
                delivered_by_supplier: doc.delivered_by_supplier,
                enable_deferred_expense: doc.enable_deferred_expense,
                grant_commission: doc.grant_commission,
                is_sales_item: doc.is_sales_item,
                enable_deferred_revenue: doc.enable_deferred_revenue,
                inspection_required_before_purchase: doc.inspection_required_before_purchase,
                inspection_required_before_delivery: doc.inspection_required_before_delivery,
                is_sub_contracted_item: doc.is_sub_contracted_item,
                published_in_website: doc.published_in_website,
                max_discount: doc.max_discount,
                stock_uom: doc.stock_uom,
                barcodes: doc.barcodes,
                uoms: doc.uoms,
                supplier_items: doc.supplier_items,
                reorder_levels: doc.reorder_levels,
                customer_items: doc.customer_items,
                variant_of: doc.variant_of ? doc.variant_of : null,
                weight_per_unit: doc.weight_per_unit,
                // changable values
                variant_based_on: doc.variant_based_on,
                create_variant: doc?.create_variant ? doc?.create_variant : 0,
                item_template: doc?.item_template ? doc?.item_template : '',
                has_variants: doc?.has_variants ? doc?.has_variants : null,
                attributes: doc?.attributes,
                item_defaults: [],
                taxes: doc.taxes,
                description: doc.description,
                brand: doc.brand,
                item_code: doc.item_code,
                item_name: doc.item_name,
                item_group: doc.item_group,
                item_collection: doc?.item_collection,
                opening_stock: doc.opening_stock,
                standard_rate: doc.standard_rate,
                valuation_rate: doc.valuation_rate
            });
            setItemDefaults({
                doctype: 'Item Default',
                __islocal: 1,
                __unsaved: 1,
                company: doc.item_defaults[0].company,
                default_warehouse: doc.item_defaults[0].default_warehouse,
                parentfield: 'item_defaults',
                parenttype: 'Item',
                idx: 1,
                __unedited: false,
                default_price_list: doc.item_defaults[0].default_price_list
            });
            const doc_reorder_levels = doc?.reorder_levels?.find((x) => x.warehouse == doc?.item_defaults[0]?.default_warehouse);

            setReorderLevels({
                docstatus: 0,
                doctype: 'Item Reorder',
                material_request_type: 'Purchase',
                parentfield: 'reorder_levels',
                parenttype: 'Item',
                warehouse: doc_reorder_levels?.warehouse,
                warehouse_reorder_level: doc_reorder_levels?.warehouse_reorder_level,
                warehouse_reorder_qty: doc_reorder_levels?.warehouse_reorder_qty,
                __islocal: 1,
                __unsaved: 1,
                idx: 1,
                __unedited: false
            });
        }
    }, [editToggled]);

    useEffect(() => {
        if (docToDuplicate) {
            setFormState({
                // fixed values
                doctype: 'Item',
                __islocal: 1,
                __unsaved: 1,
                disabled: docToDuplicate?.disabled,
                allow_alternative_item: docToDuplicate?.allow_alternative_item,
                is_stock_item: docToDuplicate?.is_stock_item,
                include_item_in_manufacturing: docToDuplicate?.include_item_in_manufacturing,
                is_fixed_asset: docToDuplicate?.is_fixed_asset,
                auto_create_assets: docToDuplicate?.auto_create_assets,
                is_grouped_asset: docToDuplicate?.is_grouped_asset,
                end_of_life: docToDuplicate?.end_of_life,
                default_material_request_type: docToDuplicate?.default_material_request_type,
                valuation_method: docToDuplicate?.valuation_method,
                allow_negative_stock: docToDuplicate?.allow_negative_stock,
                has_batch_no: docToDuplicate?.has_batch_no,
                create_new_batch: docToDuplicate?.create_new_batch,
                has_expiry_date: docToDuplicate?.has_expiry_date,
                retain_sample: docToDuplicate?.retain_sample,
                has_serial_no: docToDuplicate?.has_serial_no,
                min_order_qty: docToDuplicate?.min_order_qty,
                is_purchase_item: docToDuplicate?.is_purchase_item,
                is_customer_provided_item: docToDuplicate?.is_customer_provided_item,
                delivered_by_supplier: docToDuplicate?.delivered_by_supplier,
                enable_deferred_expense: docToDuplicate?.enable_deferred_expense,
                grant_commission: docToDuplicate?.grant_commission,
                is_sales_item: docToDuplicate?.is_sales_item,
                enable_deferred_revenue: docToDuplicate?.enable_deferred_revenue,
                inspection_required_before_purchase: docToDuplicate?.inspection_required_before_purchase,
                inspection_required_before_delivery: docToDuplicate?.inspection_required_before_delivery,
                is_sub_contracted_item: docToDuplicate?.is_sub_contracted_item,
                published_in_website: docToDuplicate?.published_in_website,
                max_discount: docToDuplicate?.max_discount,
                stock_uom: docToDuplicate?.stock_uom,
                barcodes: [],
                uoms: docToDuplicate?.uoms,
                weight_per_unit: docToDuplicate?.weight_per_unit,
                // changable values
                variant_based_on: docToDuplicate?.variant_based_on,
                create_variant: docToDuplicate?.create_variant,
                item_template: docToDuplicate?.item_template,
                has_variants: docToDuplicate?.has_variants,
                attributes: docToDuplicate?.attributes,
                item_defaults: [],
                taxes: docToDuplicate?.taxes,
                description: docToDuplicate?.description,
                brand: docToDuplicate?.brand,
                item_code: docToDuplicate?.item_code + ' - Copie',
                item_name: docToDuplicate?.item_name + ' - Copie',
                item_group: docToDuplicate?.item_group,
                item_collection: docToDuplicate?.item_collection,
                opening_stock: docToDuplicate?.opening_stock,
                standard_rate: docToDuplicate?.standard_rate,
                valuation_rate: docToDuplicate?.valuation_rate
            });
            setItemDefaults({
                doctype: 'Item Default',
                __islocal: 1,
                __unsaved: 1,
                company: docToDuplicate?.item_defaults[0].company,
                default_warehouse: docToDuplicate?.item_defaults[0].default_warehouse,
                parentfield: 'item_defaults',
                parenttype: 'Item',
                idx: 1,
                __unedited: false,
                default_price_list: docToDuplicate?.item_defaults[0].default_price_list
            });
        }
    }, [docToDuplicate]);

    useEffect(() => {
        const header = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };
        if (storedFiles?.filePaths) {
            const fetchFiles = async () => {
                const promises = storedFiles?.filePaths?.map(async (url) => {
                    const response = await fetch(`${process.env.REACT_APP_API_URI}/api/files`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify({ fileUrl: url })
                    });
                    const fileType = response.headers.get('Content-Type');
                    const buffer = await response.arrayBuffer();
                    const blob = new Blob([buffer], { type: fileType });

                    const filename = url.substring(url.lastIndexOf('/') + 1);

                    return new File([blob], filename, { type: fileType });
                });
                const files = await Promise.all(promises);
                setItemsFiles(files);
            };

            fetchFiles();
        }
    }, [storedFiles]);

    const MyFileLink = ({ file }) => {
        const handleDownload = (event) => {
            event.preventDefault();
            const url = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        };

        let preview = null;
        if (file.type.startsWith('image/')) {
            preview = (
                <img
                    style={{ marginRight: 5, width: 64, height: 64, objectFit: 'cover' }}
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                />
            );
        } else {
            preview = (
                <Box sx={{ marginRight: 5 }}>
                    <InsertDriveFileRoundedIcon sx={{ width: 64, height: 64, objectFit: 'cover' }} />;
                </Box>
            );
        }

        return (
            <Paper
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover
                    },
                    padding: theme.spacing(2),
                    marginBottom: theme.spacing(1)
                }}
                onClick={handleDownload}
            >
                {preview}
                <Typography variant="subtitle1">{file.name}</Typography>
            </Paper>
        );
    };

    /* FETCHING AGAIN */
    const fetchWarehouse = () => {
        setIsWrhPending(true);
        let parentWarehouseReqData = {
            doctype: 'Warehouse',
            fields: ['`tabWarehouse`.`name`', '`tabWarehouse`.`warehouse_name`'],
            filters: [['Warehouse', 'company', '=', itemDefaults?.company]]
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(parentWarehouseReqData)
        })
            .then((res) => {
                setIsWrhPending(false);
                return res.json();
            })
            .then((data) => {
                if (data?.message || data?.message == 0) {
                    setMagasin(data?.message);
                } else {
                    setMagasin(data);
                }
            });
    };
    useEffect(() => {
        fetchWarehouse();
    }, [itemDefaults?.company]);

    const handleChangeCondition = () => {
        (!doc || (doc && editToggled)) && setNotDiscountable(!notDiscountable);
    };

    if (isWrhPending) return <Loader />;

    return (
        <Box mt={3}>
            <Box>
                <Grid container spacing={toPrint ? 0 : 2} columns={toPrint ? 1 : 2} display={'flex'} justifyContent={'center'}>
                    <Grid item xs={1}>
                        {doc && doc?.variant_of && (
                            <InputTextField
                                fullwidth
                                name={'item_template'}
                                value={`${doc.variant_of}: ${doc.item_name.split('-').shift()}`}
                                label={t('products:variantOf')}
                                sx={{ mb: 1 }}
                            />
                        )}
                        {['item_code', 'item_name', 'description'].map((el, i) => (
                            <InputTextField
                                fullwidth
                                key={i}
                                name={el}
                                error={doc && !editToggled ? null : formErrState[el]}
                                handleChange={(doc && !editToggled) || (editToggled && el === 'item_code') ? null : handleChange}
                                disabled={(editToggled && el === 'item_code') || (doc && !editToggled)}
                                value={doc && !editToggled ? doc[el] : formState[el]}
                                required
                                label={
                                    el === 'item_code'
                                        ? t('products:productCode')
                                        : el === 'item_name'
                                        ? t('products:productName')
                                        : t('common:description')
                                }
                                sx={{ mb: 1 }}
                            />
                        ))}
                        <SelectForm
                            fullwidth
                            label={t('products:productCategory')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc.item_group : formState.item_group}
                            name={'item_group'}
                            data={itemGroups}
                            error={doc && !editToggled ? null : formErrState?.item_group}
                            propToRender={'name'}
                            required
                            sx={{ mb: 1 }}
                        />
                        <SelectForm
                            fullwidth
                            label={t('products:collection')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.item_collection : formState?.item_collection}
                            name={'item_collection'}
                            data={itemCollections}
                            error={doc && !editToggled ? null : formErrState?.item_collection}
                            propToRender={'name'}
                            required
                            sx={{ mb: 1 }}
                        />
                        <SelectForm
                            fullwidth
                            label={t('company:company')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleItemDefaultsChange}
                            value={doc && !editToggled ? doc.item_defaults[0].company : itemDefaults.company}
                            name={'company'}
                            data={companies}
                            error={doc && !editToggled ? null : formErrState?.company}
                            propToRender={'name'}
                            required
                            sx={{ mb: 1 }}
                        />
                        <SelectForm
                            fullwidth
                            label={t('products:defaultWarehouse')}
                            disabled={doc && !editToggled}
                            error={doc && !editToggled ? null : itemErrDefaults?.default_warehouse}
                            handleChange={doc && !editToggled ? null : handleItemDefaultsChange}
                            value={doc && !editToggled ? doc.item_defaults[0].default_warehouse : itemDefaults?.default_warehouse}
                            name="default_warehouse"
                            data={magasin.length != 0 ? magasin : warehouses}
                            propToRender={'name'}
                            required
                            sx={{ mb: 1 }}
                        />
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'warehouse_reorder_qty'}
                            handleChange={doc && !editToggled ? null : handleReorderLevelsChange}
                            value={reorderLevels?.warehouse_reorder_qty}
                            label={'Quantité de Réassort'}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                        {!isSimple && !toPrint && (
                            <Box>
                                <FormControl sx={{ width: '100%' }} required error={doc ? null : formState.attributes?.length == 0}>
                                    <Grid container item spacing={4} display={'flex'} alignItems={'center'}>
                                        <Grid item xs={4}>
                                            <FormLabel sx={{ display: 'flex', justifyContent: 'right' }}>Types variantes:</FormLabel>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Box
                                                borderRadius={2}
                                                bgcolor={doc ? theme.palette.secondary.main : 'Menu'}
                                                p={2}
                                                sx={{ overflowY: 'scroll', height: 150, width: '100%' }}
                                            >
                                                {itemAttributes?.map((name, i) => (
                                                    <Box height={35} key={i}>
                                                        <FormControlLabel
                                                            key={i}
                                                            label={name.name}
                                                            control={
                                                                <Checkbox
                                                                    key={i}
                                                                    name={name.name}
                                                                    checked={
                                                                        doc && !editToggled
                                                                            ? isExistInDoc(name.name, doc.attributes, 'attribute')
                                                                            : isExistInDoc(name.name, formState.attributes, 'attribute')
                                                                    }
                                                                    onChange={doc && !editToggled ? () => {} : handleAttributesChange}
                                                                />
                                                            }
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={1}>
                        {[/*'opening_stock', */ 'standard_rate', 'valuation_rate', 'price_list_rate'].map((el, i) => (
                            <InputTextField
                                fullwidth
                                disabled={doc && !editToggled}
                                key={i}
                                name={el}
                                error={doc && !editToggled ? null : formErrState[el]}
                                handleChange={doc && !editToggled ? null : handleChange}
                                value={doc && !editToggled ? doc[el] : formState[el]}
                                required={el != 'price_list_rate'}
                                label={
                                    el == 'opening_stock'
                                        ? 'Ouverture de stock'
                                        : el == 'standard_rate'
                                        ? t('dashboard:standardRate')
                                        : el == 'valuation_rate'
                                        ? t('dashboard:valuationRate')
                                        : 'Taux de la liste de prix'
                                }
                                type="number"
                                sx={{ mb: 1 }}
                            />
                        ))}
                        <SelectForm
                            fullwidth
                            disabled={doc && !editToggled}
                            label={t('products:brand')}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc.brand : formState.brand}
                            name={'brand'}
                            data={brands}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />
                        <SelectForm
                            fullwidth
                            disabled={doc && !editToggled}
                            label={t('products:taxModel')}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={
                                doc && !editToggled
                                    ? doc.taxes[0]?.item_tax_template
                                    : formState.taxes?.[0]?.item_tax_template
                                    ? formState.taxes?.[0]?.item_tax_template
                                    : ''
                            }
                            name={'taxes'}
                            data={itemTaxTemplates}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />

                        <SelectForm
                            fullwidth
                            disabled={doc && !editToggled}
                            required
                            label={t('products:defaultPriceList')}
                            error={doc && !editToggled ? null : itemErrDefaults?.default_price_list}
                            handleChange={doc && !editToggled ? null : handleItemDefaultsChange}
                            value={doc && !editToggled ? doc.item_defaults[0].default_price_list : itemDefaults.default_price_list}
                            name="default_price_list"
                            data={priceLists}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />

                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'warehouse_reorder_level'}
                            handleChange={doc && !editToggled ? null : handleReorderLevelsChange}
                            value={reorderLevels?.warehouse_reorder_level}
                            label={'Niveau de Réassort'}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                        <InputCheckboxField
                            sx={{ mb: 1 }}
                            value={notDiscountable}
                            label={t('products:notDiscountable')}
                            fullwidth
                            name="not_discountable"
                            handleChange={handleChangeCondition}
                        />
                    </Grid>
                </Grid>
            </Box>
            {!toPrint && (
                <>
                    <Divider sx={{ mt: 3, mb: 2 }} />
                    <Box width={'100%'}>
                        {ItemsFiles.map((file, i) => (
                            <MyFileLink key={i} file={file} />
                        ))}
                        <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                },
                                padding: theme.spacing(2),
                                marginBottom: theme.spacing(1)
                            }}
                            onClick={() => setOpenDropZone(!openDropZone)}
                        >
                            <AttachmentIcon sx={{ marginRight: 2, width: 34, height: 34, objectFit: 'cover' }} />
                            <Typography variant="subtitle1">{t('common:attachFile')}</Typography>
                        </Paper>
                    </Box>
                    {openDropZone && (
                        <Box width={'100%'} sx={{ padding: theme.spacing(2) }}>
                            <DropzoneArea
                                filesLimit={5}
                                dropzoneText="Déposer les fichiers ici"
                                onChange={handleFilesChange}
                                maxFileSize={5000000}
                            />
                        </Box>
                    )}
                    {renderSaveButton() && (
                        <Box display={'flex'} mt={3} justifyContent="right">
                            <LoadingButton
                                loading={loadingButton}
                                variant="contained"
                                size="large"
                                color="blue"
                                onClick={() => formVerif()}
                            >
                                {t('common:save')}
                            </LoadingButton>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default TemplateProdForm;
