// assets
import { IconKey, IconUsers } from '@tabler/icons';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    IconKey,
    IconUsers
};

// ==============================|| USERS MENU ITEMS ||============================== //

const onlineCommercial = {
    id: 'online-users',
    type: 'group',
    role: [ROLE.ADMIN, ROLE.SALES],
    children: [
        {
            id: 'online-users',
            title: 'users:OnlineUser',
            type: 'item',
            url: '/online/users',
            icon: icons.IconUsers
        }
    ]
};

export default onlineCommercial;
