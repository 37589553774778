/* eslint-disable react/prop-types */
import React from 'react';
import { Grid, Box, Button, FormControlLabel, Checkbox, Typography, Paper, IconButton } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import InputTextField from './InputTextField';
import InputFileField from './InputFileField';
import AutoCompleteField from './AutoCompleteField';
import validator from 'validator';
import { useNavigate } from 'react-router';
import SelectForm from './SelectForm';
import AddItem from './addItem';
import { LoadingButton } from '@mui/lab';
import InputCheckboxField from './InputCheckboxField';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';

function FiltreForm(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();

    const { filtre, setFiltre, handleSubmitFiltre } = props;
    return (
        <Box>
            <Grid container row columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                <Grid item>
                    <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight'
                        }}
                        value={filtre}
                        onChange={(event) => setFiltre(event.target.value)}
                        placeholder="Search"
                    />
                </Grid>
                <Grid item>
                    <IconButton onClick={() => handleSubmitFiltre()}>
                        <SearchIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    );
}

export default FiltreForm;
