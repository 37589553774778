import { GET_SELLING_SETTINGS } from '../actions';

const initialState = {
    configurations: [],
    sellingsettings: null
};

const configurationReducer = (state = initialState, action) => {
    var configurations = state.configurations;

    switch (action.type) {
        case GET_SELLING_SETTINGS:
            return {
                ...state,
                sellingsettings: action.payload
            };
        default:
            return state;
    }
};

export default configurationReducer;
