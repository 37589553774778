import { Button, Modal, Table, TableBody, TableCell, TableHead, TableRow, InputAdornment, OutlinedInput, Grid } from '@mui/material';
import { Box } from '@mui/system';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MainCard from 'ui-component/cards/MainCard';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CurrencyFormatter, formatErpResponse } from 'utils/utils';
import { LoadingButton } from '@mui/lab';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'ui-component/Loader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@emotion/react';
import { GlobalfilterData } from 'utils/formatColumnsHeaders';
import { modalStyle } from 'views/utilities/ModalStyle';
import { SetNotification } from 'store/services/api';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useTranslation } from 'react-i18next';

const ProductTable = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        company,
        setSelectedItems,
        selectedItems,
        priceList,
        currency,
        addDiscountFormState,
        warehouse,
        initialState,
        uneditable,
        doctype,
        defaultTaxDetails,
        toPrint,
        isProdQuotation,
        doc,
        isInvoices,
        setFormIsDirty
    } = props;

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [selectionItemsModel, setSelectionItemsModel] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [loadingTable, setLoadingTable] = useState(false);

    const selectItemsQuotation = useSelector((state) => state.pim.itemsDetailsQuotation);
    const { data: items, isPending: isItemsPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Item',
            fields: ['`tabItem`.`name`', '`tabItem`.`item_name`', '`tabItem`.`item_code`', '`tabItem`.`item_group`'],
            filters: [
                ['Item', 'disabled', '=', 0],
                ['Item', 'has_variants', '=', 0],
                ['Item Default', 'company', '=', company]
            ]
        },
        'POST'
    );

    const columns = [
        { field: 'name', headerName: 'name', flex: 1 },
        {
            field: 'item_name',
            headerName: 'Item',
            flex: 1
        },
        {
            field: 'item_code',
            headerName: 'Item Code',
            flex: 1
        },
        {
            field: 'item_group',
            headerName: 'Category',
            flex: 1
        },
        {
            headerName: 'Stock Level',
            field: 'actions',
            type: 'actions',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            getActions: (params) => [
                <GridActionsCellItem
                    sx={{ bgcolor: theme.palette.secondary.main }}
                    icon={<VisibilityIcon color="primary" />}
                    label="Details"
                    onClick={handleDetails(params.id)}
                />
            ]
        }
    ];

    const insertItemsDetails = (items) => {
        let result = [];

        items?.map((el) => {
            let itemIndex = selectedItems.findIndex((e) => e.item_code === el.item_code);
            if (itemIndex === -1) {
                setLoadingTable(true);
                let rowIndex = items.findIndex((e) => e.item_code === el.item_code);
                fetch(`${process.env.REACT_APP_API_URI}/api/item/item-details`, {
                    method: 'POST',
                    headers: header,
                    body: JSON.stringify({
                        args: warehouse
                            ? {
                                  item_code: items[rowIndex]?.item_code,
                                  company: company,
                                  conversion_rate: 1,
                                  ignore_pricing_rule: 1,
                                  price_list: priceList ? priceList : 'Standard Selling',
                                  doctype: doctype ? doctype : 'Quotation',
                                  qty: items[rowIndex]?.qty,
                                  set_warehouse: warehouse
                              }
                            : {
                                  item_code: items[rowIndex]?.item_code,
                                  company: company,
                                  conversion_rate: 1,
                                  ignore_pricing_rule: 1,
                                  price_list: priceList ? priceList : 'Standard Selling',
                                  doctype: doctype ? doctype : 'Quotation',
                                  qty: items[rowIndex]?.qty
                              }
                    })
                })
                    .then((res) => {
                        if (rowIndex === items?.length - 1) {
                            setLoadingTable(false);
                        }
                        return res.json();
                    })
                    .then((data) => {
                        if (data?.message) {
                            result.push(data?.message);
                            setSelectedItems([...selectedItems, ...result]);
                        }
                    });
            }
        });
    };

    const handleItemsDetails = () => {
        let result = [];

        selectionItemsModel?.map((el) => {
            let itemIndex = selectedItems.findIndex((e) => e.item_code === el);
            if (itemIndex === -1) {
                setLoadingButton(true);
                let rowIndex = items.findIndex((e) => e.name === el);
                fetch(`${process.env.REACT_APP_API_URI}/api/item/item-details`, {
                    method: 'POST',
                    headers: header,
                    body: JSON.stringify({
                        args: warehouse
                            ? {
                                  item_code: items[rowIndex]?.item_code,
                                  company: company,
                                  conversion_rate: 1,
                                  ignore_pricing_rule: 1,
                                  price_list: priceList ? priceList : 'Standard Selling',
                                  doctype: doctype ? doctype : 'Quotation',
                                  qty: 1,
                                  set_warehouse: warehouse
                              }
                            : {
                                  item_code: items[rowIndex]?.item_code,
                                  company: company,
                                  conversion_rate: 1,
                                  ignore_pricing_rule: 1,
                                  price_list: priceList ? priceList : 'Standard Selling',
                                  doctype: doctype ? doctype : 'Quotation',
                                  qty: 1
                              }
                    })
                })
                    .then((res) => {
                        if (items[rowIndex]?.item_code === selectionItemsModel?.[selectionItemsModel?.length - 1]) {
                            setLoadingButton(false);
                            handleClose();
                        }
                        return res.json();
                    })
                    .then((data) => {
                        if (data?.message) {
                            result.push(data?.message);
                            setSelectedItems([...selectedItems, ...result]);
                            setFormIsDirty(true);
                        }
                    });
            }
        });
    };

    const handleQtyChange = (e, row) => {
        setFormIsDirty(true);
        const itemIndex = selectedItems.findIndex((el) => el.item_code === row.item_code);
        const newQuantity = parseInt(e.target.value);

        function handleQuantityChange(itemIndex, newQuantity) {
            const newItems = [...selectedItems];
            newItems[itemIndex].qty = Math.max(1, newQuantity);
            setSelectedItems(newItems);
        }

        if (newQuantity && newQuantity > 0) {
            handleQuantityChange(itemIndex, newQuantity);
        }
    };

    // SUBTOTAL >>>
    const subTotalPerRow = (item) => {
        return parseInt(item?.qty) * parseFloat(item?.price_list_rate !== 0 ? item?.price_list_rate : item?.rate);
    };

    const subTotal = () => {
        return selectedItems?.reduce((total, item) => total + subTotalPerRow(item), 0);
    };

    // TOTAL >>>
    const totalPerRow = (item) => {
        let obj = JSON?.parse(item?.item_tax_rate);
        if (obj) {
            const itemTaxRate = Object?.values(obj)[0];
            if (defaultTaxDetails && Array.isArray(defaultTaxDetails)) {
                return subTotalPerRow(item) + (subTotalPerRow(item) * defaultTaxDetails?.[0]?.rate) / 100;
            } else {
                if (itemTaxRate) {
                    return subTotalPerRow(item) + (subTotalPerRow(item) * itemTaxRate) / 100;
                } else {
                    return subTotalPerRow(item);
                }
            }
        } else {
            return subTotalPerRow(item);
        }
    };

    const total = () => {
        return selectedItems?.reduce((total, item) => total + totalPerRow(item), 0);
    };

    const taxAmountPerRow = (item) => {
        let obj = JSON?.parse(item?.item_tax_rate);
        if (obj) {
            const itemTaxRate = Object?.values(obj)[0];
            if (defaultTaxDetails && Array.isArray(defaultTaxDetails)) {
                return (subTotalPerRow(item) * defaultTaxDetails?.[0]?.rate) / 100;
            } else {
                if (itemTaxRate) {
                    return (subTotalPerRow(item) * itemTaxRate) / 100;
                } else {
                    return 0;
                }
            }
        } else {
            return 0;
        }
    };

    const taxAmount = () => {
        return selectedItems?.reduce((total, item) => total + taxAmountPerRow(item), 0);
    };

    // GRAND TOTAL >>>
    function applyDiscount(subtotal) {
        let netTotal = subtotal;
        if (addDiscountFormState.additional_discount_percentage) {
            netTotal -= (parseFloat(addDiscountFormState.additional_discount_percentage) * subtotal) / 100;
        } else if (addDiscountFormState.discount_amount) {
            netTotal -= parseFloat(addDiscountFormState.discount_amount);
        }
        return netTotal;
    }

    function grandTotal() {
        // discounted total
        if (addDiscountFormState.apply_discount_on === 'Grand Total') {
            return applyDiscount(total());
        } else if (addDiscountFormState.apply_discount_on === 'Net Total') {
            return applyDiscount(subTotal()) + taxAmount();
        }
    }

    function extractVATPercentage(str) {
        const regex = /\d+(?:\.\d+)?%/;
        if (str) {
            const match = str?.match(regex);
            if (match) {
                return match[0];
            } else {
                return '-';
            }
        } else {
            return '-';
        }
    }

    /* SEARCH HANDLER & STOCK DETAILS */

    const [loadingStock, setLoadingStock] = useState(false);
    const [openStock, setOpenStock] = useState(false);
    const [stockDetails, setStockDetails] = useState(null);

    const handleOpenStock = () => setOpenStock(true);
    const handleCloseStock = () => {
        setOpenStock(false);
        setStockDetails(null);
    };

    const handleDetails = React.useCallback(
        (id) => () => {
            handleOpenStock();
            setLoadingStock(true);
            fetch(`${process.env.REACT_APP_API_URI}/api/stock/dashboard`, {
                method: 'POST',
                headers: header,
                body: JSON?.stringify({ item_code: id })
            })
                .then((res) => {
                    setLoadingStock(false);
                    if (!res.ok) {
                        dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    }
                    return res.json();
                })
                .then((data) => {
                    if (data?.success === false) {
                        dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                    } else {
                        setStockDetails(data?.message);
                    }
                });
        },
        []
    );

    const [searchProduct, setSearchProduct] = React.useState('');
    const [searched4Items, setSearched4Items] = React.useState([]);

    const SearchProduct = (event) => {
        setSearchProduct(event.target.value);
        const data = GlobalfilterData(items, event.target.value.toLowerCase(), ['item_group', 'item_code', 'item_name']);
        let selectedItems = [];
        selectionItemsModel.forEach((itemCode) => {
            let row = items.filter((el) => el.name === itemCode);
            row && selectedItems.push(row);
        });

        const mergedItems = [...data, ...selectedItems?.flat()];
        const uniqueItems = Array.from(new Set(mergedItems.map((item) => item.item_code))).map((code) =>
            mergedItems.find((item) => item.item_code === code)
        );

        uniqueItems?.length ? setSearched4Items(uniqueItems) : setSearched4Items([]);
    };

    const StockDetailsTable = ({ stockDetails }) => {
        return (
            <>
                <Table aria-label="stock details table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Warehouse</TableCell>
                            <TableCell>Actual</TableCell>
                            {/* <TableCell>Projected Qty</TableCell> */}
                            <TableCell>Reserved</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stockDetails?.map((detail) => (
                            <TableRow key={`${detail.item_code}-${detail.warehouse}`}>
                                <TableCell scope="row">{detail.warehouse}</TableCell>
                                <TableCell>{detail.actual_qty}</TableCell>
                                {/* <TableCell>{detail.projected_qty}</TableCell> */}
                                <TableCell>{detail.reserved_qty}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </>
        );
    };

    const stockDetailsModal = (
        <Box sx={{ ...modalStyle, width: 350 }}>
            <MainCard
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <BarChartIcon sx={{ mr: 1 }} /> Stock level
                    </Box>
                }
            >
                {loadingStock && <Loader />}
                {!loadingStock && <Box>{stockDetails?.length ? <StockDetailsTable stockDetails={stockDetails} /> : 'NONE!'}</Box>}
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button variant="outlined" color="blue" size="large" onClick={handleCloseStock}>
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const selectingItems = (
        <Box display={'grid'} sx={{ placeItems: 'center' }}>
            <Box width={500} mt={'4%'}>
                <MainCard divider>
                    <Box p={1}>
                        <OutlinedInput
                            sx={{ mb: 2 }}
                            id="outlined-adornment-weight"
                            value={searchProduct}
                            placeholder={'Search...'}
                            onChange={SearchProduct}
                            endAdornment={
                                <InputAdornment position="end">
                                    <SearchIcon color={'gray'} />
                                </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                'aria-label': 'weight'
                            }}
                        />
                    </Box>
                    <Box height={417} width={'100%'}>
                        <DataGrid
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        name: false
                                    }
                                }
                            }}
                            rows={searchProduct ? searched4Items : items}
                            columns={columns}
                            checkboxSelection
                            getRowId={(row) => row.name}
                            hideFooterPagination
                            rowSelectionModel={selectionItemsModel}
                            onRowSelectionModelChange={(newSelectionModel) => {
                                setSelectionItemsModel(newSelectionModel);
                            }}
                            loading={isItemsPending}
                        />
                    </Box>
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <Button sx={{ mr: 1 }} variant="outlined" color="warning" size="large" onClick={handleClose}>
                            Close
                        </Button>
                        <LoadingButton
                            loading={loadingButton}
                            disabled={selectionItemsModel?.length === 0}
                            size="large"
                            color="blue"
                            variant="outlined"
                            onClick={() => handleItemsDetails()}
                        >
                            Add
                        </LoadingButton>
                    </Box>
                </MainCard>
            </Box>
        </Box>
    );

    const handleDeleteRow = (itemCode) => {
        setFormIsDirty(true);
        setSelectedItems(selectedItems?.filter((el) => el.item_code !== itemCode));
        setSelectionItemsModel(selectionItemsModel?.filter((el) => el !== itemCode));
    };

    useEffect(() => {
        if (initialState && initialState?.length !== 0) {
            setSelectedItems(initialState);
            setSelectionItemsModel(initialState.map((el) => el.item_code));
        }
    }, [initialState]);

    useEffect(() => {
        if (isProdQuotation && selectItemsQuotation?.length != 0) {
            setFormIsDirty(true);
            insertItemsDetails(selectItemsQuotation);
            setSelectionItemsModel(selectItemsQuotation.map((el) => el.item_code));
        }
    }, [selectItemsQuotation]);

    if (loadingTable) return <Loader />;
    return (
        <div>
            <MainCard divider border>
                {!toPrint && (
                    <Box display={'flex'} justifyContent={'right'}>
                        <Button
                            disabled={uneditable}
                            startIcon={<AddCircleIcon />}
                            variant="text"
                            color="blue"
                            size="small"
                            onClick={handleOpen}
                        >
                            Add Item
                        </Button>
                    </Box>
                )}
                <PerfectScrollbar style={{ overflow: 'scroll' }}>
                    <Table
                        aria-label="simple table"
                        sx={toPrint ? { mt: -1, fontSize: '0.4rem', borderCollapse: 'separate', borderSpacing: '0' } : { mt: -2 }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>ITEM CODE</TableCell>
                                <TableCell>ITEM</TableCell>
                                <TableCell>PRICE</TableCell>
                                <TableCell>QTY</TableCell>
                                <TableCell>TOTAL EXCL. TAXES</TableCell>
                                <TableCell>VAT</TableCell>
                                <TableCell>TOTAL INCL. TAXES</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedItems.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell>{row?.item_code}</TableCell>
                                    <TableCell scope="row" width={'40%'}>
                                        {row?.item_name}
                                    </TableCell>
                                    <TableCell>
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            currency={currency}
                                            minimumFractionDigits={3}
                                            value={row?.price_list_rate !== 0 ? row?.price_list_rate : row?.rate}
                                        />
                                    </TableCell>
                                    <TableCell width={60}>
                                        <input
                                            disabled={uneditable}
                                            id="qty-input"
                                            type={'number'}
                                            min={1}
                                            style={{ borderRadius: 2, border: '1px solid #ccc', padding: '5px', width: 60 }}
                                            value={row?.qty}
                                            onChange={(e) => handleQtyChange(e, row)}
                                        />
                                    </TableCell>
                                    <TableCell scope="row">
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            currency={currency}
                                            minimumFractionDigits={3}
                                            value={subTotalPerRow(row)}
                                        />
                                    </TableCell>
                                    <TableCell scope="row">
                                        {defaultTaxDetails?.[0]?.account_head
                                            ? extractVATPercentage(defaultTaxDetails?.[0]?.account_head)
                                            : row?.item_tax_template
                                            ? extractVATPercentage(row?.item_tax_template)
                                            : '-'}
                                    </TableCell>
                                    <TableCell scope="row" align="right">
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            currency={currency}
                                            minimumFractionDigits={3}
                                            value={totalPerRow(row)}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        {!toPrint && (
                                            <DeleteIcon
                                                color="error"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => (uneditable ? {} : handleDeleteRow(row?.item_code))}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {selectedItems?.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={8}>
                                        <Box display={'flex'} alignItems={'center'}>
                                            <InfoIcon sx={{ mr: 1 }} />
                                            <>No Items yet!</>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    <TableRow>
                                        <TableCell colSpan={4} />
                                        <TableCell align="right" colSpan={2}>
                                            <strong>TOTAL EXCL. TAXES</strong>
                                        </TableCell>
                                        <TableCell align="right">
                                            <strong>
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    currency={currency}
                                                    minimumFractionDigits={3}
                                                    value={subTotal()}
                                                />
                                            </strong>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={4} />
                                        <TableCell align="right" colSpan={2}>
                                            <strong>VAT</strong>
                                        </TableCell>
                                        <TableCell align="right">
                                            <strong>
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    currency={currency}
                                                    minimumFractionDigits={3}
                                                    value={taxAmount()}
                                                />
                                            </strong>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={4} />
                                        <TableCell align="right" colSpan={2}>
                                            <strong>TOTAL INCL. TAXES</strong>
                                        </TableCell>
                                        <TableCell align="right">
                                            <strong>
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    currency={currency}
                                                    minimumFractionDigits={3}
                                                    value={total()}
                                                />
                                            </strong>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={4} />
                                        <TableCell align="right" colSpan={2}>
                                            <strong>DISCOUNTED TOTAL</strong>
                                        </TableCell>
                                        <TableCell align="right">
                                            <strong>
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    currency={currency}
                                                    minimumFractionDigits={3}
                                                    value={grandTotal()}
                                                />
                                            </strong>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                    {isInvoices && doc?.status === 'Partly Paid' && (
                                        <>
                                            <TableRow>
                                                <TableCell colSpan={4} />
                                                <TableCell align="right" colSpan={2}>
                                                    <strong>{t('columns:status')}</strong>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <strong>{doc?.status}</strong>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} />
                                                <TableCell align="right" colSpan={2}>
                                                    <strong>PAID AMOUNT</strong>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <strong>
                                                        <CurrencyFormatter
                                                            locale="fr-FR"
                                                            currency={currency}
                                                            minimumFractionDigits={3}
                                                            value={grandTotal() - doc?.outstanding_amount}
                                                        />
                                                    </strong>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} />
                                                <TableCell align="right" colSpan={2}>
                                                    <strong>OUTSTANDING AMOUNT</strong>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <strong>
                                                        <CurrencyFormatter
                                                            locale="fr-FR"
                                                            currency={currency}
                                                            minimumFractionDigits={3}
                                                            value={doc?.outstanding_amount}
                                                        />
                                                    </strong>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </>
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </PerfectScrollbar>
            </MainCard>
            <Modal onClose={handleClose} open={open}>
                {selectingItems}
            </Modal>
            <Modal onClose={handleCloseStock} open={openStock}>
                {stockDetailsModal}
            </Modal>
        </div>
    );
};

export default ProductTable;
