import { Box } from '@mui/system';
import useGetAxios from 'hooks/useGetAxios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from 'ui-component/Loader';
import InputTextField from 'ui-component/form/InputTextField';
import { Divider, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { formatErpResponse } from 'utils/utils';
import { SetNotification } from 'store/services/api';
import InfoIcon from '@mui/icons-material/Info';
import useFetch from 'hooks/useFetch';
import { useTranslation } from 'react-i18next';

const ItemCollectionForm = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Item%20Collection&name=${id}`);

    const { data: count, isPending: isCountPending } = useFetch(
        `/api/get-list-count`,
        {
            doctype: 'Item',
            filters: [['Item', 'item_collection', '=', `${id}`]],
            distinct: false
        },
        'POST'
    );

    const [loadingButton, setLoadingButton] = useState(false);

    const [formState, setFormState] = useState({
        collection_name: ''
    });
    const [formErrState, setFormErrState] = useState({
        collection_name: false
    });

    const handleChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });

        // err handlers
        if (e.target.value == '') {
            setFormErrState({ ...formErrState, [e.target.name]: true });
        } else {
            setFormErrState({ ...formErrState, [e.target.name]: false });
        }
    };

    const handleSaveItemCollection = async () => {
        setLoadingButton(true);
        const data = {
            doc: {
                ...(!id && { __islocal: 1 }),
                ...(id && {
                    creation: doc.creation,
                    modified: doc.modified,
                    owner: doc.owner,
                    modified_by: doc.owner,
                    name: doc.name
                }),
                doctype: 'Item Collection',
                collection_name: formState.collection_name,
                __unsaved: 1
            },
            action: 'Save'
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Collection sauvegardé avec succès' }));
                    navigate('/pim/product-collections');
                }
            });
    };

    useEffect(() => {
        if (id && doc?.collection_name) {
            setFormState({ ...formState, collection_name: doc.collection_name });
        }
    }, [doc]);

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {id ? `${t('products:collection')}: ${doc?.collection_name}` : t('products:newCollection')}
        </Box>
    );

    if (id && isPending) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                {doc?.collection_name && (
                    <Box display={'flex'} mt={-4} mb={2}>
                        <InfoIcon sx={{ mt: 0.6 }} />
                        <Typography p={1} variant="h5" color="primary">
                            {`Cette collection comprend ${count?.message} produits.`}
                        </Typography>
                    </Box>
                )}
                <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                    <InputTextField
                        handleChange={handleChange}
                        value={formState.collection_name}
                        error={formErrState?.collection_name}
                        label="Collection"
                        name="collection_name"
                    />
                </Grid>
                <Grid container item xs={12} sm={12} md={8} lg={8} xl={8} spacing={4} display={'flex'} justifyContent={'center'}>
                    <Grid item mt={3}>
                        <LoadingButton
                            loading={loadingButton}
                            variant="contained"
                            size="large"
                            color="blue"
                            onClick={handleSaveItemCollection}
                        >
                            {t('common:save')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};

export default ItemCollectionForm;
