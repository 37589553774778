// action - state management
import * as actionTypes from '../actions';

export const initialState = {
    mappedSalesOrder: null,
    mappedDeliveryNote: null,
    mappedSalesInvoice: null
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const mappedDocsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MAPPED_SALESORDER:
            return {
                ...state,
                mappedSalesOrder: action.doc
            };
        case actionTypes.SET_MAPPED_DELIVERYNOTE:
            return {
                ...state,
                mappedDeliveryNote: action.doc
            };
        case actionTypes.SET_MAPPED_SALESINVOICE:
            return {
                ...state,
                mappedSalesInvoice: action.doc
            };
        default:
            return state;
    }
};

export default mappedDocsReducer;
