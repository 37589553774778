import { Button, Grid, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import DataRender from 'ui-component/tables/DataRender';
import { modalStyle } from 'views/utilities/ModalStyle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { LoadingButton } from '@mui/lab';

const AllFilesList = () => {
    const [selectionModel, setSelectionModel] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    const filesReqData = {
        doctype: 'File',
        fields: [
            '`tabFile`.`creation`',
            '`tabFile`.`name`',
            '`tabFile`.`file_name`',
            // '`tabFile`.`is_private`'
            // '`tabFile`.`file_size`'
            '`tabFile`.`file_url`',
            // '`tabFile`.`thumbnail_url`',
            // '`tabFile`.`is_folder`',
            '`tabFile`.`attached_to_doctype`'
            // '`tabFile`.`attached_to_name`'
            // '`tabFile`.`attached_to_field`',
            // '`tabFile`.`content_hash`',
            // '`tabFile`.`uploaded_to_dropbox`',
            // '`tabFile`.`uploaded_to_google_drive`',
        ],
        start: 0,
        page_length: 500
    };

    const { data: filesData, isPending: isFilesPending, refetch } = useFetch(`/api/get-list`, filesReqData, 'POST');

    const [selectedFile, setSelectedFile] = useState();

    const uploadHandler = (event) => {
        setSelectedFile(event?.target?.files?.[0]);
    };

    const handleSubmission = () => {
        // setLoadingButton(true);

        const header = {
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };

        const formData = new FormData();
        formData.append('file', selectedFile, selectedFile?.name);
        formData.append('folder', 'Home');
        formData.append('is_private', '0');

        fetch(`${process.env.REACT_APP_API_URI}/api/upload-file`, {
            method: 'POST',
            headers: header,
            body: formData
        })
            .then((res) => {})
            .then((data) => {});
    };

    const fileUploader = (
        <Box sx={modalStyle}>
            <MainCard divider title={'Files'}>
                <Box p={1} display={'flex'} alignItems={'center'} flexDirection={'row'} bgcolor={'Menu'} sx={{ borderRadius: 2 }}>
                    <UploadFileIcon />
                    <Typography id="modal-modal-description" color={'primary'} sx={{ ml: 2, fontSize: 14 }} fontWeight={500}>
                        {selectedFile?.name}
                    </Typography>
                    <Typography id="modal-modal-description" color={'primary'} sx={{ ml: 2, fontSize: 14 }}>
                        {selectedFile?.type}
                    </Typography>
                    <Typography id="modal-modal-description" color={'error'} sx={{ ml: 2, fontSize: 14, width: 20 }}>
                        {selectedFile?.size && (selectedFile?.size / 1024 / 1024).toFixed(2)} MB
                    </Typography>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleSubmission()}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    Upload
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    useEffect(() => {
        if (selectedFile) {
            setOpen(true);
        }
    }, [selectedFile]);

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            {t('common:filesList')}
            <Button variant="outlined" color="blue" component="label">
                I{t('common:import')}
                <input type="file" hidden onChange={uploadHandler} />
            </Button>
        </Box>
    );

    return (
        <Box>
            <DataRender
                title={title}
                data={filesData}
                loading={isFilesPending}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                // handleEdit={handleEdit}
                // handleDelete={handleDelete}
                // hasCheckBoxes
            />
            <Modal onClose={handleClose} open={open}>
                {fileUploader}
            </Modal>
        </Box>
    );
};

export default AllFilesList;
