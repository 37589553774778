import { Box } from '@mui/material';
import useFetch from 'hooks/useFetch';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetNotification } from 'store/services/api';
import DataRender from 'ui-component/tables/DataRender';
import { formatErpResponse } from 'utils/utils';

const LoyaltyCardList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [selectionModel, setSelectionModel] = useState([]);

    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { data, isPending, refetch } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Loyalty Card',
            fields: [
                '`tabLoyalty Card`.`creation`',
                '`tabLoyalty Card`.`name`',
                '`tabLoyalty Card`.`customer`',
                '`tabLoyalty Card`.`loyalty_program`'
            ]
        },
        'POST'
    );

    const handleEdit = (row) => {
        navigate(`/crm/loyatly-card/${row}`);
    };

    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Loyalty Card', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Carte de fidélité a été supprimée avec succès' }));
                    refetch({});
                    handleClose();
                }
            });
    };

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            {t('crm:lyltycard')}
        </Box>
    );

    return (
        <DataRender
            refetch={refetch}
            isViewAction
            data={data}
            loading={isPending}
            selectionModel={selectionModel}
            title={title}
            setSelectionModel={setSelectionModel}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
        />
    );
};

export default LoyaltyCardList;
