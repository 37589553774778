import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import GenericForm from 'ui-component/form/GenericForm';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import useGetAxios from 'hooks/useGetAxios';
import Loader from 'ui-component/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';

const AddNewDepot = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { t } = useTranslation();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const [loadingButton, setLoadingButton] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [state, setState] = React.useState({
        doctype: 'Warehouse',
        warehouse_name: '',
        warehouse_type: '',
        default_customer: '',
        company: globalDefaults?.default_company,
        parent_warehouse: '',
        phone_no: '',
        mobile_no: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        email: ''
    });

    const [error, setError] = React.useState({
        warehouse_name: false,
        company: false
    });

    const handleChange = (event) => {
        setError({ ...error, [event.target.name]: false });
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Warehouse&name=${id}`);

    const warehouseData = {
        ...(id && {
            creation: doc.creation,
            modified: doc.modified,
            owner: doc.owner,
            modified_by: doc.owner,
            name: doc.name
        }),
        doctype: state.doctype,
        warehouse_name: state.warehouse_name,
        default_customer: state.default_customer,
        company: state.company,
        parent_warehouse: state.parent_warehouse,
        phone_no: state.phone_no,
        mobile_no: state.mobile_no,
        address_line_1: state.address_line_1,
        address_line_2: state.address_line_2,
        city: state.city,
        email: state.email,
        is_storage: 1,
        disabled: isDisabled ? 1 : 0
    };

    useEffect(() => {
        if (id && !Array.isArray(doc) && doc) {
            setState({
                ...state,
                warehouse_name: doc?.warehouse_name ? doc?.warehouse_name : '',
                warehouse_type: doc?.warehouse_type ? doc?.warehouse_type : '',
                default_customer: doc?.default_customer ? doc?.default_customer : '',
                company: doc?.company ? doc?.company : '',
                parent_warehouse: doc?.parent_warehouse ? doc?.parent_warehouse : '',
                phone_no: doc?.phone_no ? doc?.phone_no : '',
                mobile_no: doc?.mobile_no ? doc?.mobile_no : '',
                address_line_1: doc?.address_line_1 ? doc?.address_line_1 : '',
                address_line_2: doc?.address_line_2 ? doc?.address_line_2 : '',
                city: doc?.city ? doc?.city : '',
                email: doc?.email ? doc?.email : ''
            });
            setIsDisabled(doc.disabled == 1 ? true : false);
        }
    }, [id, doc]);

    const handleAddWarehouse = async () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: warehouseData, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    if (id) {
                        dispatch(SetNotification({ message: 'Dépôt sauvegardé avec succès', code: 'success' }));
                    } else {
                        dispatch(SetNotification({ message: 'Dépôt créé avec succès', code: 'success' }));
                    }
                    navigate('/core/depots');
                }
            });
    };

    const handleSubmit = (event) => {
        var isValid = true;
        if (state.warehouse_name === '') {
            setError({ ...error, ['warehouse_name']: true });
            isValid = false;
        }
        if (state.company === '') {
            setError({ ...error, ['company']: true });
            isValid = false;
        }
        if (isValid) {
            handleAddWarehouse();
        }
    };
    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {id ? `${t('core:warehouse')}: ${id}` : t('core:newwarehouse')}
        </Box>
    );

    if (id && isPending) return <Loader />;
    return (
        <Box>
            <MainCard title={title}>
                <GenericForm
                    isDepot
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    setState={setState}
                    state={state}
                    setError={setError}
                    error={error}
                    loadingButton={loadingButton}
                    id={id}
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                />
            </MainCard>
        </Box>
    );
};

export default AddNewDepot;
