import dashboard from './dashboard';
import core from './core';
import utilities from './utilities';
import other from './other';
import pim from './pim';
import users from './users';
import sales from './sales';
import crm from './crm';
import onlineCommercial from './onlineCommercial';
import commercialCondition from './commercialCondition';
import loyalty from './loyatlyProgram';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, onlineCommercial, pim, core, users, sales, loyalty, crm, commercialCondition]
};

export default menuItems;
