// assets
import { IconKey, IconTypography, IconCategory, IconPackage } from '@tabler/icons';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    IconKey,
    IconCategory,
    IconPackage
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pim = {
    id: 'pim',
    title: 'PIM',
    caption: 'Gestion des informations sur les articles',
    type: 'group',
    role: [ROLE.ADMIN, ROLE.CASHIER],
    children: [
        {
            id: 'PIM',
            title: 'PIM',
            type: 'collapse',
            icon: icons.IconPackage,
            children: [
                {
                    id: 'pim-dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/pim/dashboard'
                },
                {
                    id: 'products',
                    title: 'Produits',
                    type: 'item',
                    url: '/pim/products-view'
                },
                {
                    id: 'products-category',
                    title: 'Catégories produits',
                    type: 'item',
                    url: '/pim/products-category-list-view'
                },
                {
                    id: 'canaux',
                    title: 'Canaux',
                    type: 'item',
                    url: '/pim/canaux'
                },
                {
                    id: 'product-attributes',
                    title: 'Types de variantes',
                    type: 'item',
                    url: '/pim/product-attributes'
                },
                // {
                //     id: 'all-files',
                //     title: 'Fichiers',
                //     type: 'item',
                //     url: '/pim/all-files'
                // },
                {
                    id: 'customize-form',
                    title: "Types d'attributs",
                    type: 'item',
                    url: '/pim/customize'
                },
                {
                    id: 'product-collections',
                    title: 'Collections',
                    type: 'item',
                    url: '/pim/product-collections'
                },
                {
                    id: 'product-bundles',
                    title: 'Ensemble produits',
                    type: 'item',
                    url: '/pim/product-bundles'
                }
            ]
        }
    ]
};

export default pim;
