import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { CurrencyFormatter, formatErpResponse } from 'utils/utils';
import Logo from 'ui-component/Logo';
import { Box } from '@mui/system';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        padding: theme.spacing(3),
        boxSizing: 'border-box',
        minHeight: '842px',
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        justifyContent: 'space-between'
    },

    section: {
        marginBottom: theme.spacing(1),
        marginTop: 10
    },
    sectionHeader: {
        marginTop: 0,
        marginBottom: theme.spacing(3)
    },
    content: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spacing(2)
    },
    table: {
        borderCollapse: 'collapse',
        width: '100%',
        border: '1px solid #000',
        borderSpacing: 0,
        color: 'black'
    },
    tableRow: {
        pageBreakInside: 'avoid',
        pageBreakBefore: 'auto'
    },
    th: {
        padding: '4px',
        textAlign: 'left',
        background: '#f2f2f2',
        border: '1px solid #000',
        fontSize: 9
    },
    td: {
        padding: '4px',
        border: '1px solid #000',
        fontSize: 9
    },
    tdTotal: {
        padding: '4px',
        border: '1px solid #000',
        textAlign: 'right',
        fontSize: 9
    },
    footer: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        textAlign: 'center',
        padding: '16px'
    },
    avoidPageBreak: {
        position: 'relative',
        marginTop: 20,
        padding: theme.spacing(2),
        pageBreakBefore: 'always',
        pageBreakAfter: 'avoid',
        pageBreakInside: 'avoid',
        textAlign: 'justify'
    }
}));

const PrintInvoiceTemplate = React.forwardRef(
    ({ doc, selectedItems, isInvoices, customerDetails, isSalesOrder, isQuotation, defaultTaxDetails, isDeliveryNote, docinfo }, ref) => {
        const classes = useStyles();
        const globalDefaults = useSelector((state) => state.login.globalDefaults);

        function extractVATPercentage(str) {
            const regex = /\d+(?:\.\d+)?%/;
            if (str) {
                const match = str?.match(regex);
                if (match) {
                    return match[0];
                } else {
                    return '-';
                }
            } else {
                return '-';
            }
        }

        // SUBTOTAL >>>
        const subTotalPerRow = (item) => {
            return parseInt(item?.qty) * parseFloat(item?.price_list_rate);
        };

        // TOTAL >>>
        const totalPerRow = (item) => {
            let obj = JSON?.parse(item?.item_tax_rate);
            if (obj) {
                const itemTaxRate = Object?.values(obj)[0];
                if (defaultTaxDetails && Array.isArray(defaultTaxDetails)) {
                    return subTotalPerRow(item) + (subTotalPerRow(item) * defaultTaxDetails?.[0]?.rate) / 100;
                } else {
                    if (itemTaxRate) {
                        return subTotalPerRow(item) + (subTotalPerRow(item) * itemTaxRate) / 100;
                    } else {
                        return subTotalPerRow(item);
                    }
                }
            } else {
                return subTotalPerRow(item);
            }
        };

        return (
            <div className={classes.body} ref={ref}>
                <div className={classes.root}>
                    <div className={classes.header}>
                        <Logo />
                        <h1>
                            {doc?.doctype === 'Sales Invoice' && doc?.status != 'Partly Paid'
                                ? 'Tax Invoice'
                                : doc?.doctype === 'Sales Invoice' && doc?.status === 'Partly Paid'
                                ? 'Deposit Invoice'
                                : doc?.doctype}
                        </h1>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.section}>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}> {doc?.company}</p>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>FRENCH RIVIERA FURNITURE LLC</p>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>HABIB BANK AG ZURICH</p>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>DEIRA, DUBAI</p>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>SWIFT: HBZUAEADXXX</p>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>AE67 0290 1902 1050 0999 294</p>
                        </div>
                        <div className={classes.section}>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>
                                {customerDetails?.customer_type
                                    ? customerDetails?.salutation
                                        ? customerDetails?.salutation + '. '
                                        : 'Mrs. '
                                    : ' '}
                                {doc?.customer_name}
                            </p>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>
                                Address :
                                {customerDetails?.primary_address
                                    ? formatErpResponse(customerDetails?.primary_address)
                                    : doc?.address_display
                                    ? formatErpResponse(doc?.address_display)
                                    : ' -'}
                            </p>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>
                                Phone : {doc?.contact_mobile ?? ' -'}
                            </p>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>
                                Email: {customerDetails?.email_id ?? ' -'}
                            </p>
                        </div>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.section}>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>References</p>
                            <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>Ordered by : {doc?.owner}</p>
                            <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>Order #: {doc?.name}</p>
                            {!isSalesOrder && !isQuotation && (
                                <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                    Date: {doc?.posting_date} {moment(doc?.posting_time, 'HH:mm:ss').format('HH:mm:ss')}
                                </p>
                            )}
                            {isInvoices && <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>Due Date: {doc?.due_date}</p>}
                            {isSalesOrder && (
                                <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>Delivery Date: {doc?.delivery_date}</p>
                            )}
                            {isQuotation && (
                                <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                    Transaction Date: {doc?.transaction_date} <br />
                                    Valid Till: {doc?.valid_till}
                                </p>
                            )}
                        </div>
                        {docinfo?.comments?.length != 0 && (
                            <div className={classes.section}>
                                <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>Notes</p>
                                {docinfo?.comments &&
                                    docinfo?.comments?.map((comment, index) => (
                                        <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }} key={index}>
                                            {formatErpResponse(comment?.content)}
                                        </p>
                                    ))}
                            </div>
                        )}
                    </div>
                    <div className={classes.section}>
                        <table className={classes.table}>
                            <thead>
                                <tr className={classes.tableRow}>
                                    <th className={classes.th}>Ref.</th>
                                    {isDeliveryNote && <th className={classes.th}>Item Group</th>}
                                    <th className={classes.th} style={{ width: '60%' }}>
                                        Item
                                    </th>
                                    {!isDeliveryNote && <th className={classes.th}>Unit Price</th>}
                                    <th className={classes.th}>Qty</th>

                                    {!isDeliveryNote && <th className={classes.th}>Total excl. taxes</th>}
                                    {!isDeliveryNote && <th className={classes.th}>VAT</th>}
                                    {!isDeliveryNote && <th className={classes.th}>Total incl. taxes</th>}
                                </tr>
                            </thead>
                            <tbody className={classes.tableBody}>
                                {selectedItems &&
                                    selectedItems?.map((row, index) => [
                                        <tr key={index} className={classes.tableRow}>
                                            <td className={classes.td}>{row?.item_code}</td>
                                            {isDeliveryNote && <td className={classes.td}>{row?.item_group}</td>}
                                            <td className={classes.td} style={{ width: '60%' }}>
                                                {row?.item_name}
                                            </td>
                                            {!isDeliveryNote && (
                                                <td className={classes.td} align="right">
                                                    <CurrencyFormatter
                                                        locale="fr-FR"
                                                        currency={globalDefaults?.default_currency}
                                                        minimumFractionDigits={3}
                                                        value={row?.price_list_rate}
                                                    />
                                                </td>
                                            )}
                                            <td className={classes.td}>{row?.qty}</td>

                                            {!isDeliveryNote && (
                                                <td className={classes.td} align="right">
                                                    <CurrencyFormatter
                                                        locale="fr-FR"
                                                        currency={globalDefaults?.default_currency}
                                                        minimumFractionDigits={3}
                                                        value={subTotalPerRow(row)}
                                                    />
                                                </td>
                                            )}
                                            {!isDeliveryNote && (
                                                <td className={classes.td}>
                                                    {defaultTaxDetails?.[0]?.account_head
                                                        ? extractVATPercentage(defaultTaxDetails?.[0]?.account_head)
                                                        : row?.item_tax_template
                                                        ? extractVATPercentage(row?.item_tax_template)
                                                        : '-'}
                                                </td>
                                            )}
                                            {!isDeliveryNote && (
                                                <td className={classes.td} align="right">
                                                    <CurrencyFormatter
                                                        locale="fr-FR"
                                                        currency={globalDefaults?.default_currency}
                                                        minimumFractionDigits={3}
                                                        value={totalPerRow(row)}
                                                    />
                                                </td>
                                            )}
                                        </tr>,

                                        index === selectedItems.length - 1 && (
                                            <React.Fragment key="tfoot">
                                                {!isDeliveryNote && (
                                                    <>
                                                        <tr className={classes.tableRow}>
                                                            <td
                                                                className={classes.td}
                                                                style={{ textAlign: 'right', fontWeight: 'bold' }}
                                                                colSpan="6"
                                                            >
                                                                Total order excl. taxes
                                                            </td>
                                                            <td className={classes.td} align="right">
                                                                <CurrencyFormatter
                                                                    locale="fr-FR"
                                                                    currency={globalDefaults?.default_currency}
                                                                    minimumFractionDigits={3}
                                                                    value={doc?.total}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr className={classes.tableRow}>
                                                            <td
                                                                className={classes.td}
                                                                style={{ textAlign: 'right', fontWeight: 'bold' }}
                                                                colSpan="6"
                                                            >
                                                                VAT (
                                                                {extractVATPercentage(
                                                                    doc?.taxes?.length != 0 ? doc?.taxes?.[0]?.account_head : 0
                                                                )}
                                                                )
                                                            </td>
                                                            <td className={classes.td} align="right">
                                                                <CurrencyFormatter
                                                                    locale="fr-FR"
                                                                    currency={globalDefaults?.default_currency}
                                                                    minimumFractionDigits={3}
                                                                    value={doc?.taxes?.length != 0 ? doc?.taxes?.[0]?.tax_amount : 0}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr className={classes.tableRow}>
                                                            <td
                                                                className={classes.td}
                                                                style={{ textAlign: 'right', fontWeight: 'bold' }}
                                                                colSpan="6"
                                                            >
                                                                Total incl. taxes
                                                            </td>
                                                            <td className={classes.td} align="right">
                                                                <CurrencyFormatter
                                                                    locale="fr-FR"
                                                                    currency={globalDefaults?.default_currency}
                                                                    minimumFractionDigits={3}
                                                                    value={doc?.taxes?.length != 0 ? doc?.taxes?.[0]?.total : 0}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr className={classes.tableRow}>
                                                            <td
                                                                className={classes.td}
                                                                style={{ textAlign: 'right', fontWeight: 'bold' }}
                                                                colSpan="6"
                                                            >
                                                                Discount (
                                                                {doc?.additional_discount_percentage
                                                                    ? doc?.additional_discount_percentage
                                                                    : 0}{' '}
                                                                %)
                                                            </td>
                                                            <td className={classes.td} align="right">
                                                                <CurrencyFormatter
                                                                    locale="fr-FR"
                                                                    currency={globalDefaults?.default_currency}
                                                                    minimumFractionDigits={3}
                                                                    value={doc?.discount_amount ? doc?.discount_amount : 0}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr className={classes.tableRow}>
                                                            <td
                                                                className={classes.td}
                                                                style={{ textAlign: 'right', fontWeight: 'bold' }}
                                                                colSpan="6"
                                                            >
                                                                Total order after discount
                                                            </td>
                                                            <td className={classes.td} align="right">
                                                                <CurrencyFormatter
                                                                    locale="fr-FR"
                                                                    currency={globalDefaults?.default_currency}
                                                                    minimumFractionDigits={3}
                                                                    value={doc?.grand_total}
                                                                />
                                                            </td>
                                                        </tr>

                                                        {isInvoices && doc?.status === 'Partly Paid' && (
                                                            <>
                                                                <tr className={classes.tableRow}>
                                                                    <td
                                                                        className={classes.td}
                                                                        style={{ textAlign: 'right', fontWeight: 'bold' }}
                                                                        colSpan="6"
                                                                    >
                                                                        Status
                                                                    </td>
                                                                    <td className={classes.td}>{doc?.status}</td>
                                                                </tr>
                                                                <tr className={classes.tableRow}>
                                                                    <td
                                                                        className={classes.td}
                                                                        style={{ textAlign: 'right', fontWeight: 'bold' }}
                                                                        colSpan="6"
                                                                    >
                                                                        DEPOSIT AMOUNT
                                                                    </td>
                                                                    <td className={classes.td} align="right">
                                                                        <CurrencyFormatter
                                                                            locale="fr-FR"
                                                                            currency={globalDefaults?.default_currency}
                                                                            minimumFractionDigits={3}
                                                                            value={doc?.grand_total - doc?.outstanding_amount}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr className={classes.tableRow}>
                                                                    <td
                                                                        className={classes.td}
                                                                        style={{ textAlign: 'right', fontWeight: 'bold' }}
                                                                        colSpan="6"
                                                                    >
                                                                        Net to pay
                                                                    </td>
                                                                    <td className={classes.td} align="right">
                                                                        <CurrencyFormatter
                                                                            locale="fr-FR"
                                                                            currency={globalDefaults?.default_currency}
                                                                            minimumFractionDigits={3}
                                                                            value={doc?.outstanding_amount}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </React.Fragment>
                                        )
                                    ])}
                            </tbody>
                        </table>

                        {isInvoices && (
                            <p style={{ marginTop: 2, textAlign: 'center', fontWeight: 'bold', color: 'black' }}>
                                System generated invoice, do not require stamp and signature
                            </p>
                        )}
                    </div>
                    {!isDeliveryNote && (
                        <div className={classes.avoidPageBreak}>
                            <p style={{ margin: 0, lineHeight: 1.5, fontSize: 12, fontWeight: 600 }}>Terms and Conditions</p>
                            <br />
                            <p style={{ margin: 0, lineHeight: 1.5, fontSize: 12 }}>
                                * Merchandise not paid in full for within 90 days of deposit invoice will be considered abandoned. In
                                addition to any other remedy, French Riviera Furniture LLC shall be entitled to liquidate the merchandise,
                                retain all deposits, and apply such proceeds to the unpaid invoiced balances.
                                <br /> <br />
                                * Failure to make a claim within this period constitutes acceptance of the merchandise and a waiver of
                                claims. French Riviera Furniture LLC shall be entitled to repair or replace the damaged pieces, no refund.
                                <br /> <br />
                                * The purchaser is entitled of a free 30-day storage of the purchased goods starting from the issuing date
                                of the invoice, afterwards a monthly charge of 250 Dirhams per cubic meter should be paid by the purchaser
                                in advance with a minimum charge of 150 dirhams when applicable.
                                <br /> <br />
                                * All purchased and ordered items cannot be returned or exchanged. For special cases of undelivered goods,
                                an exchange request is mandatory and French Riviera Furniture LLC shall be entitled to deny the request, or
                                approve it with a deduction of 35% of the original price of the goods, the purchaser will be receiving a
                                credit note for the rest of the amount to be redeemed through the purchase of French Riviera Furniture LLC
                                (Gautier Dubai) goods.
                                <br /> <br />* Delivery dates are estimates unless a fixed date for the transactions has been expressly
                                agreed. if the purchaser is responsible for any delay of the delivery, service or the assembly of the goods,
                                we are entitled without prejudice to any other claims, to demand payment fees in accordance with the case.
                                Cancelation or modification of delivery date/time should be done at least 48 hours in advance.
                                <br /> <br />
                                * A delivery and assembly fee of 250 Dirhams is applicable for any invoice not exceeding the total of 5000
                                Dirhams.
                                <br /> <br />
                                * Delivery and assembly charges: Dubai/Sharjah/Ajman-Free, Rak-500 Dirhams, Abu Dhabi/Al Ain-550 Dirhams and
                                Fujeirah-600 Dirhams * Merchandise not paid in full for within 90 days of deposit invoice will be considered
                                abandoned. In addition to any other remedy, French Riviera Furniture LLC shall be entitled to liquidate the
                                merchandise, retain all deposits, and apply such proceeds to the unpaid invoiced balances.
                                <br /> <br />
                                * Failure to make a claim within this period constitutes acceptance of the merchandise and a waiver of
                                claims. French Riviera Furniture LLC shall be entitled to repair or replace the damaged pieces, no refund.
                                <br /> <br />
                                * The purchaser is entitled of a free 30-day storage of the purchased goods starting from the issuing date
                                of the invoice, afterwards a monthly charge of 250 Dirhams per cubic meter should be paid by the purchaser
                                in advance with a minimum charge of 150 dirhams when applicable.
                                <br /> <br />
                                * All purchased and ordered items cannot be returned or exchanged. For special cases of undelivered goods,
                                an exchange request is mandatory and French Riviera Furniture LLC shall be entitled to deny the request, or
                                approve it with a deduction of 35% of the original price of the goods, the purchaser will be receiving a
                                credit note for the rest of the amount to be redeemed through the purchase of French Riviera Furniture LLC
                                (Gautier Dubai) goods.
                                <br /> <br />* Delivery dates are estimates unless a fixed date for the transactions has been expressly
                                agreed. if the purchaser is responsible for any delay of the delivery, service or the assembly of the goods,
                                we are entitled without prejudice to any other claims, to demand payment fees in accordance with the case.
                                Cancelation or modification of delivery date/time should be done at least 48 hours in advance.
                                <br /> <br />
                                * A delivery and assembly fee of 250 Dirhams is applicable for any invoice not exceeding the total of 5000
                                Dirhams.
                                <br /> <br />* Delivery and assembly charges: Dubai/Sharjah/Ajman-Free, Rak-500 Dirhams, Abu Dhabi/Al
                                Ain-550 Dirhams and Fujeirah-600 Dirhams
                            </p>
                        </div>
                    )}

                    <footer className={classes.footer}>
                        <p style={{ lineHeight: 1.5, textAlign: 'right', fontSize: 11, fontWeight: 500 }}>
                            I agree to French Riviera Furniture LLC Terms and Conditions: . . . . . . . . . . . . . . . . . . . . . . . . .
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Logo />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '70%'
                                }}
                            >
                                <p style={{ lineHeight: 1.25, textAlign: 'center', fontSize: 9, marginLeft: '10px' }}>
                                    GAUTIER DUBAI – Sheikh Zayed Road – Infinity Building – Tel: +971 4 3881 336 – Fax: +9714 3881 337
                                    <br />
                                    City Centre Mirdif – Level 1 – Tel: +971 4 6652 449 <br />
                                    P.O Box 124133 – Email:
                                    <a href="mailto:Dubai@gautier.ae">Dubai@gautier.ae</a>– Website:
                                    <a target="_blank" href="https://www.gautier.ae" title="website_gautier">
                                        www.gautier.ae
                                    </a>
                                    – <br />
                                    Independent store exploited by French Riviera Furniture LLC – TRN 100355053800003
                                </p>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
);

export default PrintInvoiceTemplate;
