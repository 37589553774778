// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useStyles } from 'views/utilities/Style';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const classes = useStyles();
    const role = JSON.parse(localStorage.getItem('user'))?.role?.toString().toUpperCase();
    const defaultCompany = useSelector((state) => state.login.detailsDefaultCompany);

    const menuItems =
        defaultCompany?.name === 'Gautier'
            ? menuItem.items.filter((item) => item.id !== 'commercial-condition' && item.id !== 'loyatly')
            : menuItem.items;

    const navItems = menuItems
        ?.filter((item) => item.role?.includes(role))
        ?.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });

    return <Box className={classes.menu}>{navItems}</Box>;
};

export default MenuList;
