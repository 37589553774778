// action - state management
import * as actionTypes from '../actions';

export const initialState = {
    warehouses: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const warehouseReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_WAREHOUSES:
            return {
                ...state,
                warehouses: action.warehouses
            };
        default:
            return state;
    }
};

export default warehouseReducer;
