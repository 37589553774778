import * as React from 'react';
import Stack from '@mui/material/Stack';
import { TextField, InputLabel, Grid, Typography, Box } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';

export default function FormDateTime(props) {
    const classes = useStyles();
    const { handleChange, label, value, name, error, required, type, disabled, sx, fullwidth, isValidDate, helper, nextDate } = props;
    const currentDate = moment().format('YYYY-MM-DD');
    return (
        <Grid
            sx={sx}
            container
            item
            xs={!fullwidth && 12}
            sm={!fullwidth && 12}
            md={!fullwidth && 8}
            lg={!fullwidth && 8}
            xl={!fullwidth && 8}
            spacing={4}
            display={'flex'}
            alignItems={'center'}
        >
            <Grid item xs={4}>
                <InputLabel error={error ?? error} required={required} className={classes.inputLabel}>
                    {label}
                </InputLabel>
            </Grid>
            <Grid item xs={8}>
                <Stack component="form" noValidate spacing={3}>
                    <TextField
                        disabled={disabled}
                        error={error ?? error}
                        value={value}
                        name={name}
                        onChange={handleChange}
                        id="datetime-local"
                        type={type ? type : 'datetime-local'}
                        InputLabelProps={{
                            shrink: true
                        }}
                        inputProps={{
                            min: isValidDate ? currentDate : nextDate ? nextDate : '',
                            max: ''
                        }}
                    />
                </Stack>
                {helper && (
                    <Box display={'flex'}>
                        <InfoIcon sx={{ mt: 0.6, color: 'red' }} />
                        <Typography p={1} variant="h5" color="red">
                            {helper}
                        </Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
