// action - state management
import * as actionTypes from '../actions';

export const initialState = {
    docToDuplicate: null,
    bulkEditIds: [],
    bulkEditFields: [],
    itemsDetailsQuotation: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const pimReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DOC_2_DUPLICATE:
            return {
                ...state,
                docToDuplicate: action.doc
            };
        case actionTypes.SET_BULK_EDIT_IDS:
            return {
                ...state,
                bulkEditIds: action.rows
            };
        case actionTypes.SET_BULK_EDIT_FIELDS:
            return {
                ...state,
                bulkEditFields: action.fields
            };
        case actionTypes.SET_DETAILS_ITEMS_QUOTATION:
            return {
                ...state,
                itemsDetailsQuotation: action.doc
            };
        default:
            return state;
    }
};

export default pimReducer;
