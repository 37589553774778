import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import MainCard from 'ui-component/cards/MainCard';
import { frLocal } from 'utils/dataGridFRLocal';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { modalStyle } from 'views/utilities/ModalStyle';
import { useTheme } from '@emotion/react';
import PrintIcon from '@mui/icons-material/Print';
import { formatColumnsHeaders, formatData, formatColumnsString } from 'utils/formatColumnsHeaders';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'views/utilities/Style';
import GetAppIcon from '@mui/icons-material/GetApp';
import { CustomNoRowsOverlay } from 'ui-component/noRowsOverlay/CustomNoRowsOverlay';

const DataRender = ({
    data,
    loading,
    title,
    selectionModel,
    setSelectionModel,
    handleEdit,
    handleDelete,
    hasCheckBoxes,
    noActions,
    isPrintable,
    componentRef,
    handlePreview,
    rowId,
    showDetails,
    handleShowDetails,
    ischild,
    notshow,
    isViewAction,
    isSales,
    refetch,
    isDisable,
    tag
}) => {
    const theme = useTheme();
    const classes = useStyles();

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0
    });

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const [idToDelete, setIdToDelete] = useState(null);
    const { t, i18n } = useTranslation();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setIdToDelete(null);
    };

    const handleTheDelete = React.useCallback(
        (id) => () => {
            setIdToDelete(id);
            handleOpen();
        },
        []
    );

    const deleteActionCaptured = async () => {
        handleDelete && (await handleDelete([idToDelete]));
        refetch && refetch({});
        handleClose();
    };

    const handleDetails = React.useCallback(
        (id) => () => {
            handlePreview ? handlePreview(id) : handleEdit(id);
        },
        []
    );

    const handleTheEdit = React.useCallback(
        (id) => () => {
            handleEdit && handleEdit(id);
        },
        []
    );

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={isSales ? 'Confirm' : 'Confirmer'}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {isSales ? 'Delete permanently' : isDisable ? 'Désactiver' : 'Supprimer définitivement'} <strong>{idToDelete}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button variant="outlined" color="blue" size="large" onClick={handleClose}>
                                    {isSales ? 'No' : 'Non'}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button color="blue" size="large" onClick={() => deleteActionCaptured()} variant="contained">
                                    {isSales ? 'Yes' : 'oui'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const actions = {
        // headerName: !isPrintable ? (
        //     ''
        // ) : (
        //     <Box>
        //         {isPrintable && (
        //             <Box display={'flex'} justifyContent={'space-between'}>
        //                 <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        //                 {componentRef && (
        //                     <ReactToPrint
        //                         trigger={() => (
        //                             <GridActionsCellItem
        //                                 sx={{ bgcolor: theme.palette.secondary.main }}
        //                                 icon={<PrintIcon color="blue" />}
        //                                 label="Print"
        //                             />
        //                         )}
        //                         content={() => componentRef.current}
        //                     />
        //                 )}
        //             </Box>
        //         )}
        //     </Box>
        // ),
        field: 'actions',
        type: 'actions',
        width: 140,
        align: 'right',
        headerAlign: 'right',
        getActions: (params) => [
            // handleEdit ? (
            //     <GridActionsCellItem
            //         sx={{ bgcolor: theme.palette.secondary.main }}
            //         icon={<VisibilityIcon color="primary" />}
            //         label="Details"
            //         onClick={handleDetails(params.id)}
            //     />
            // ) : null,
            <GridActionsCellItem
                sx={{ bgcolor: theme.palette.secondary.main }}
                icon={isViewAction ? <VisibilityIcon color="primary" /> : <EditIcon color="primary" />}
                label="Edit"
                onClick={handleTheEdit(params.id)}
            />,
            <GridActionsCellItem
                sx={{ bgcolor: theme.palette.secondary.main }}
                icon={<DeleteIcon color="error" />}
                label="Delete"
                onClick={handleTheDelete(params.id)}
            />
        ]
    };
    const showdetails = {
        field: 'Actions',
        type: 'actions',
        width: 140,
        align: 'right',
        headerAlign: 'right',
        getActions: (params) => [
            <Button
                startIcon={<VisibilityIcon />}
                variant="outlined"
                color="blue"
                onClick={() => handleShowDetails(params.id)}
                /*  sx={{ marginTop: -3 }} */
            >
                Details
            </Button>
        ]
    };
    const getColumns = (data) => {
        let columns = [];
        if (data && Array.isArray(data) && data?.length > 0) {
            Object.keys(data[0]).map((column) => {
                var showthiscol = true;
                if (notshow && Array.isArray(notshow)) {
                    const index = notshow.findIndex((el) => el == column);
                    if (index != -1) {
                        showthiscol = false;
                    }
                }
                if (showthiscol) {
                    columns.push({
                        field: column,
                        headerName: isSales ? formatColumnsString(column) : t(formatColumnsHeaders(column, tag)),
                        flex: 1
                    });
                }
            });

            !noActions && columns.push(actions);
            showDetails && columns.push(showdetails);
            return columns;
        }
        return [];
    };
    const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
    useEffect(() => {
        if (!loading && data && data?.length > 0) {
            setRows(formatData(data));
            setColumns(getColumns(data));
        }
    }, [data, getLanguage()]);

    return (
        <Box>
            <MainCard title={title}>
                <Box sx={{ height: ischild ? 500 : 823 }}>
                    <DataGrid
                        checkboxSelection={hasCheckBoxes}
                        onRowDoubleClick={() => !hasCheckBoxes && handleEdit && handleEdit(selectionModel)}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        rowSelectionModel={selectionModel}
                        experimentalFeatures={{ newEditingApi: true }}
                        loading={loading}
                        rows={rows}
                        columns={columns}
                        getRowId={(row) => row.name}
                        // localeText={frLocal}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                                printOptions: { disableToolbarButton: true }
                            }
                        }}
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnMenu
                        density="standard"
                    />
                </Box>
            </MainCard>
            <Modal onClose={handleClose} open={open}>
                {deleteConfirm}
            </Modal>
        </Box>
    );
};
export default DataRender;
