import React, { useState } from 'react';
import {
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    Typography,
    Box
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyles } from 'views/utilities/Style';
import useGetAxios from 'hooks/useGetAxios';
import Loader from 'ui-component/Loader';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import useFetch from 'hooks/useFetch';
import DetailsTables from 'ui-component/simpleTable/DetailsTables';
import ArticleIcon from '@mui/icons-material/Article';
import CustomerDetailsCard from './CustomerDetailsCard';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const CustomerDetails = () => {
    const { id } = useParams();
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const defaultCompany = useSelector((state) => state.login.detailsDefaultCompany);

    const { data: doc, isPending, refetch } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Customer&name=${id}`);

    const { data: salesOrders, isSOPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Sales Order',
            fields: [
                '`tabSales Order`.`creation`',
                '`tabSales Order`.`name`',
                '`tabSales Order`.`status`',
                '`tabSales Order`.`grand_total`',
                '`tabSales Order`.`currency`'
            ],
            filters: [['Sales Order', 'customer_name', '=', id]],
            order_by: '`tabSales Order`.`modified` desc'
        },
        'POST'
    );

    const { data: depositInv, isDepositPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Sales Invoice',
            fields: [
                '`tabSales Invoice`.`creation`',
                '`tabSales Invoice`.`name`',
                '`tabSales Invoice`.`status`',
                '`tabSales Invoice`.`grand_total`',
                '`tabSales Invoice`.`currency`'
            ],
            filters: [
                ['Sales Invoice', 'customer_name', '=', id],
                ['Sales Invoice', 'status', '=', 'Partly Paid']
            ],
            order_by: '`tabSales Invoice`.`modified` desc'
        },
        'POST'
    );

    const { data: salesInv, isInvPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Sales Invoice',
            fields: [
                '`tabSales Invoice`.`creation`',
                '`tabSales Invoice`.`name`',
                '`tabSales Invoice`.`status`',
                '`tabSales Invoice`.`grand_total`',
                '`tabSales Invoice`.`currency`'
            ],
            filters: [
                ['Sales Invoice', 'customer_name', '=', id],
                ['Sales Invoice', 'status', '!=', 'Partly Paid']
            ],
            order_by: '`tabSales Invoice`.`modified` desc'
        },
        'POST'
    );

    const { data: quotations, isQPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Quotation',
            fields: [
                '`tabQuotation`.`creation`',
                '`tabQuotation`.`name`',
                '`tabQuotation`.`status`',
                '`tabQuotation`.`grand_total`',
                '`tabQuotation`.`currency`'
            ],
            filters: [['Quotation', 'customer_name', '=', id]],
            order_by: '`tabQuotation`.`modified` desc'
        },
        'POST'
    );

    const { data: deliveryNotes, isDNPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Delivery Note',
            fields: ['`tabDelivery Note`.`creation`', '`tabDelivery Note`.`name`', '`tabDelivery Note`.`status`'],
            filters: [['Delivery Note', 'customer_name', '=', id]],
            order_by: '`tabDelivery Note`.`modified` desc'
        },
        'POST'
    );

    const [selectionModel, setSelectionModel] = useState([]);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Box p={-1} display={'flex'} flexDirection={'column'}>
                <ArrowBackIcon
                    className={classes.backLink}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
                <Box display={'flex'}>{`Customer Details: ${doc?.customer_name}`}</Box>
            </Box>
        </Box>
    );

    if (id && isPending) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                <Grid container direction="row" spacing={2} display="flex">
                    <Grid item xs={12} md={4}>
                        <div>
                            <CustomerDetailsCard refetch={refetch} doc={doc} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box sx={{ width: '100%' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons>
                                <Tab label={t('crm:qtt')} {...a11yProps(0)} />
                                <Tab label={t('crm:depositinv')} {...a11yProps(1)} />
                                <Tab label={t('crm:salesinv')} {...a11yProps(2)} />
                                <Tab label={t('crm:deliverynote')} {...a11yProps(3)} />
                                {defaultCompany?.name !== 'Gautier' && <Tab label={t('crm:salesorder')} {...a11yProps(4)} />}
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <DetailsTables
                                    data={quotations}
                                    loading={isQPending}
                                    title={
                                        <Box display={'flex'} alignItems={'center'}>
                                            <ArticleIcon sx={{ mr: 1 }} /> {t('crm:qtt')}
                                        </Box>
                                    }
                                    selectionModel={selectionModel}
                                    setSelectionModel={setSelectionModel}
                                    handleEdit={(row) => {
                                        navigate(`/sales/quotation/${row}`);
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <DetailsTables
                                    data={depositInv}
                                    loading={isDepositPending}
                                    title={
                                        <Box display={'flex'} alignItems={'center'}>
                                            <ArticleIcon sx={{ mr: 1 }} /> {t('crm:depositinv')}
                                        </Box>
                                    }
                                    selectionModel={selectionModel}
                                    setSelectionModel={setSelectionModel}
                                    handleEdit={(row) => {
                                        navigate(`/sales/tax-invoice/${row}`);
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <DetailsTables
                                    data={salesInv}
                                    loading={isInvPending}
                                    title={
                                        <Box display={'flex'} alignItems={'center'}>
                                            <ArticleIcon sx={{ mr: 1 }} /> {t('crm:salesinv')}
                                        </Box>
                                    }
                                    selectionModel={selectionModel}
                                    setSelectionModel={setSelectionModel}
                                    handleEdit={(row) => {
                                        navigate(`/sales/tax-invoice/${row}`);
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <DetailsTables
                                    data={deliveryNotes}
                                    loading={isDNPending}
                                    title={
                                        <Box display={'flex'} alignItems={'center'}>
                                            <ArticleIcon sx={{ mr: 1 }} /> {t('crm:deliverynote')}
                                        </Box>
                                    }
                                    selectionModel={selectionModel}
                                    setSelectionModel={setSelectionModel}
                                    handleEdit={(row) => {
                                        navigate(`/sales/delivery-note/${row}`);
                                    }}
                                />
                            </TabPanel>
                            {defaultCompany?.name !== 'Gautier' && (
                                <TabPanel value={value} index={4}>
                                    <DetailsTables
                                        data={salesOrders}
                                        loading={isSOPending}
                                        title={
                                            <Box display={'flex'} alignItems={'center'}>
                                                <ArticleIcon sx={{ mr: 1 }} /> {t('crm:salesorder')}
                                            </Box>
                                        }
                                        selectionModel={selectionModel}
                                        setSelectionModel={setSelectionModel}
                                        handleEdit={(row) => {
                                            navigate(`/sales/sales-order/${row}`);
                                        }}
                                    />
                                </TabPanel>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};

export default CustomerDetails;
