import React, { useState } from 'react';
import { Select, MenuItem, FormControl, Button, InputLabel, OutlinedInput, Grid } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import Box from '@mui/material/Box';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';
import { LoadingButton } from '@mui/lab';
import { formatErpResponse } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import validator from 'validator';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useGetAxios from 'hooks/useGetAxios';

const ModalForm = ({ handleClose, loadingButton, setLoadingButton, refetch, doc, isEdit }) => {
    //{['name', 'mobile', 'email', 'address']}
    const classes = useStyles();
    const dispatch = useDispatch();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [state, setState] = useState({
        doctype: 'Customer',
        customer_name: isEdit ? doc?.customer_name : '',
        customer_type: isEdit ? doc?.customer_type : '',
        customer_group: isEdit ? doc?.customer_group : '',
        territory: isEdit ? doc?.territory : '',
        email_id: isEdit ? doc?.email_id : '',
        mobile_no: isEdit ? doc?.mobile_no : '',
        address_line1: isEdit ? doc?.address_line1 : '',
        country: isEdit ? doc?.country : '',
        city: isEdit ? doc?.city : '',
        salutation: isEdit ? doc?.salutation : ''
    });
    const [error, setError] = useState({
        customer_name: false,
        customer_type: false,
        customer_group: false,
        territory: false,
        email_id: false,
        mobile_no: false,
        salutation: false
    });

    const handleChange = (e) => {
        setError({ ...error, [e.target.name]: false });
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const territoryReqData = {
        doctype: 'Territory',
        fields: [
            '`tabTerritory`.`name`',
            '`tabTerritory`.`territory_name`',
            '`tabTerritory`.`parent_territory`',
            '`tabTerritory`.`is_group`',
            '`tabTerritory`.`territory_manager`'
        ],
        filters: [['Territory', 'is_group', '=', 0]],
        order_by: '`tabTerritory`.`modified` desc'
    };

    const customerGroupReqData = {
        doctype: 'Customer Group',
        fields: [
            '`tabCustomer Group`.`name`',
            '`tabCustomer Group`.`customer_group_name`',
            '`tabCustomer Group`.`parent_customer_group`',
            '`tabCustomer Group`.`is_group`'
        ],
        order_by: '`tabCustomer Group`.`modified` desc'
    };

    const { data: territory, isTerritoryPending } = useFetch(`/api/get-list`, territoryReqData, 'POST');
    const { data: customerGroup, isCustomerGroupPending } = useFetch(`/api/get-list`, customerGroupReqData, 'POST');
    const { data: countries, isPending: isCountriesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Country',
            fields: ['`tabCountry`.`name`']
        },
        'POST'
    );

    const { data: salutations, isPending: isSalutations } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Salutation',
            fields: ['`tabSalutation`.`name`']
        },
        'POST'
    );
    const {
        data: doc_contact,
        isPending,
        refetch_contact
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Contact&name=${doc?.customer_primary_contact}`);
    const territoryName = territory?.map((obj) => obj.territory_name);
    const customerGroupName = customerGroup?.map((obj) => obj.customer_group_name);

    const data = [
        {
            name: 'customer_type',
            label: 'Customer Type',
            value: state.customer_type,
            items: ['Company', 'Individual'],
            error: error.customer_type
        },
        {
            name: 'customer_group',
            label: 'Customer Group',
            value: state.customer_group,
            items: customerGroupName,
            error: error.customer_group
        },
        { name: 'territory', label: 'Territory', value: state.territory, items: territoryName, error: error.territory }
    ];

    const handleAddCustomer = async () => {
        setLoadingButton(true);
        if (isEdit) {
            await savecontact();
            var editcustomer = doc;
            editcustomer.customer_name = state?.customer_name;
            editcustomer.address_line1 = state?.address_line1;
            editcustomer.salutation = state?.salutation;
            editcustomer.customer_type = state?.customer_type;
            editcustomer.customer_group = state?.customer_group;
            editcustomer.territory = state?.territory;
            editcustomer.country = state?.country;
            editcustomer.city = state?.city;
        }
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: isEdit ? editcustomer : state, action: 'Save' })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (!isEdit) {
                    if (data?.success === false) {
                        dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                    } else {
                        dispatch(SetNotification({ message: 'Customer successfully created', code: 'success' }));
                        refetch({});
                        handleClose();
                    }
                } else {
                    dispatch(SetNotification({ message: 'Customer successfully updated', code: 'success' }));
                    refetch({});
                    handleClose();
                }
                setLoadingButton(false);
            });
    };
    const savecontact = async () => {
        if ((state?.mobile_no !== doc?.mobile_no || state?.email_id !== doc?.email_id) && doc_contact) {
            const var_cantact = doc_contact;
            if (var_cantact?.email_ids?.length > 0) var_cantact.email_ids[0].email_id = state?.email_id;
            if (var_cantact?.phone_nos?.length > 0) {
                var_cantact.phone_nos[0].phone = state?.mobile_no;
                var_cantact.phone_nos[0].is_primary_phone = 1;
            }
            await fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ doc: var_cantact, action: 'Save' })
            })
                .then((res) => {
                    if (!res.ok) {
                        dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    }
                    return res.json();
                })
                .then((data) => {
                    return;
                });
        } else {
            return;
        }
    };

    const handleSubmit = (event) => {
        var isValid = true;
        if (state.customer_name === '') {
            setError({ ...error, ['customer_name']: true });
            isValid = false;
        }
        if (state.customer_type === '') {
            setError({ ...error, ['customer_type']: true });
            isValid = false;
        }
        if (state.customer_group === '') {
            setError({ ...error, ['customer_group']: true });
            isValid = false;
        }
        if (state.territory === '') {
            setError({ ...error, ['territory']: true });
            isValid = false;
        }
        if (state.email_id != '') {
            if (!validator.isEmail(state.email_id)) {
                setError({ ...error, ['email_id']: true });
                isValid = false;
            }
        }
        if (state?.salutation == '' && state?.customer_type == 'Individual') {
            setError({ ...error, ['salutation']: true });
            isValid = false;
        }

        if (state.mobile_no != '') {
            if (!validator.isMobilePhone(state.mobile_no)) {
                setError({ ...error, ['mobile_no']: true });
                isValid = false;
            }
        }
        if (isValid) {
            handleAddCustomer();
        }
    };

    if (isTerritoryPending || isCustomerGroupPending || isCountriesPending || isSalutations) return <Loader />;

    return (
        <Box>
            <Box p={1}>
                <PerfectScrollbar style={{ overflow: 'scroll', height: 400 }}>
                    <Box p={1}>
                        <FormControl fullWidth>
                            <InputLabel error={error.customer_name}>Customer Name</InputLabel>
                            <OutlinedInput
                                label={'Customer Name'}
                                autoComplete="off"
                                className={classes.formControll}
                                error={error.customer_name}
                                placeholder="Customer Name"
                                name="customer_name"
                                value={state.customer_name}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Box>

                    <Box justifyContent="center">
                        {Array.isArray(data) &&
                            data.map((row, index) => (
                                <Box mt={1} key={index}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" error={row.error}>
                                            {row.label}
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={row.label}
                                            name={row.name}
                                            value={row.value}
                                            onChange={handleChange}
                                            error={row.error}
                                        >
                                            {Array.isArray(row.items) &&
                                                row.items.map((item, index) => (
                                                    <MenuItem style={{ fontSize: 12 }} value={item} key={index}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            ))}
                    </Box>
                    <Box mt={1}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <InputLabel error={error.email_id}>Email</InputLabel>
                                    <OutlinedInput
                                        label={'Email'}
                                        value={state.email_id}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        className={classes.formControll}
                                        placeholder="Email"
                                        name="email_id"
                                        error={error.email_id}
                                    />
                                </FormControl>

                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <InputLabel error={error.email_id}>City</InputLabel>
                                    <OutlinedInput
                                        label={'City'}
                                        value={state.city}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        className={classes.formControll}
                                        placeholder="City"
                                        name="city"
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <InputLabel>Address</InputLabel>
                                    <OutlinedInput
                                        label={'Address'}
                                        value={state.address_line1}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        className={classes.formControll}
                                        placeholder="Adress"
                                        name="address_line1"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <InputLabel error={error.mobile_no}>Mobile</InputLabel>
                                    <OutlinedInput
                                        label={'Mobile'}
                                        value={state.mobile_no}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        className={classes.formControll}
                                        placeholder="Mobile"
                                        name="mobile_no"
                                        error={error.mobile_no}
                                    />
                                </FormControl>

                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <InputLabel mt={0.5} id="demo-simple-select-label">
                                        Country
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Country"
                                        name="country"
                                        value={state?.country}
                                        onChange={handleChange}
                                    >
                                        {Array.isArray(countries) &&
                                            countries.map((item, index) => (
                                                <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>

                                {state?.customer_type === 'Individual' && (
                                    <FormControl fullWidth sx={{ mt: 1 }}>
                                        <InputLabel mt={0.5} error={error?.salutation} id="demo-simple-select-label">
                                            Salutation
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="salutation"
                                            name="salutation"
                                            value={state?.salutation}
                                            error={error?.salutation}
                                            onChange={handleChange}
                                        >
                                            {Array.isArray(salutations) &&
                                                salutations.map((item, index) => (
                                                    <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </PerfectScrollbar>
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <LoadingButton color="blue" size="large" variant="contained" loading={loadingButton} onClick={handleSubmit}>
                                Save
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default ModalForm;
