import { Box } from '@mui/system';
import useGetAxios from 'hooks/useGetAxios';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import Loader from 'ui-component/Loader';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import { Chip, Divider, Grid, Typography, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import InputTextField from 'ui-component/form/InputTextField';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import moment from 'moment';
import useFetch from 'hooks/useFetch';
import SelectForm from 'ui-component/form/SelectForm';
import { formatErpResponse, isSameDate } from 'utils/utils';
import PersonIcon from '@mui/icons-material/Person';
import ProductTable from 'ui-component/tables/ProductTable';
import { LoadingButton } from '@mui/lab';
import { SetNotification, SetItemsQuotation, SetMappedSalesOrder, SetMappedSalesInvoice } from 'store/services/api';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import PrintInvoiceTemplate from 'views/invoices/sales/SalesInvoice/components/PrintInvoiceTemplate';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EmailSender from 'ui-component/emailSender/EmailSender';
import BundleItemsTable from 'views/products/productBundle/BundleItemsTable';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import html2pdf from 'html2pdf.js';
import { useBlocker } from 'hooks/useBlocker';
import NavigationModal from 'ui-component/cards/NavigationModal';
import Comments from 'ui-component/comments/Comments';

const QuotationForm = () => {
    const classes = useStyles();

    const [formIsDirty, setFormIsDirty] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    useBlocker(({ retry }) => {
        setModalOpen(true);
    }, formIsDirty);

    const handleCloseBlocker = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setModalOpen(false);
        setFormIsDirty(false);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const { id } = useParams();
    const componentRef = useRef();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const defaultCompany = useSelector((state) => state.login.detailsDefaultCompany);
    const selectItemsQuotation = useSelector((state) => state.pim.itemsDetailsQuotation);

    const [selectedItems, setSelectedItems] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [mappedDocLoadingButton, setMappedDocLoadingButton] = useState(false);
    const [submitQuotationLoading, setSubmitQuotationLoading] = useState(false);
    const [salesInvoiceMakingLoading, setSalesInvoiceMakingLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [company, setCompany] = useState(globalDefaults?.default_company);

    const {
        data: doc,
        isPending,
        refetch: refetchDoc,
        docinfo
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Quotation&name=${id}`);

    const { data: currencies, isPending: isCurrenciesPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Currency', fields: ['`tabCurrency`.`name`'], filters: [['Currency', 'enabled', '=', 1]] },
        'POST'
    );

    const { data: sellingPriceLists, isPending: isSellingPriceListsPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Price List',
            fields: ['`tabPrice List`.`name`'],
            filters: [
                ['Price List', 'selling', '=', 1],
                ['Price List', 'name', 'not like', '%ignore']
            ]
        },
        'POST'
    );

    const { data: taxesAndCharges, isPending: isTaxesAndChargesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Sales Taxes and Charges Template',
            fields: ['`tabSales Taxes and Charges Template`.`name`'],
            filters: [['Sales Taxes and Charges Template', 'company', '=', company]]
        },
        'POST'
    );

    const {
        data: customers,
        isPending: isCustomersPending,
        refetch
    } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Customer',
            fields: ['`tabCustomer`.`name`', '`tabCustomer`.`mobile_no`'],
            filters: [['Customer', 'disabled', '=', 0]],
            order_by: '`tabCustomer`.`modified` desc'
        },
        'POST'
    );

    const [formState, setFormState] = useState({
        valid_till: moment().format('YYYY-MM-DD'),
        currency: globalDefaults?.default_currency,
        selling_price_list: ''
    });

    const [addDiscountFormState, setAddDiscountFormState] = useState({
        coupon_code: '',
        additional_discount_percentage: 0,
        discount_amount: '',
        apply_discount_on: 'Grand Total'
    });

    const [customerFormState, setCustomerFormState] = useState({
        party_name: ''
    });
    const [taxFormState, setTaxFormState] = useState({
        taxes_and_charges: ''
    });

    const [customerDetails, setCustomerDetails] = useState(null);
    const [defaultTaxDetails, setDefaultTaxDetails] = useState(null);

    const [customerFormErrState, setCustomerFormErrState] = useState({});

    const [formErrState, setFormErrState] = useState({});

    const handleChange = (e) => {
        setFormIsDirty(true);
        setFormState({ ...formState, [e.target.name]: e.target.value });

        // err handlers
        if (e.target.value == '') {
            setFormErrState({ ...formErrState, [e.target.name]: true });
        } else {
            setFormErrState({ ...formErrState, [e.target.name]: false });
        }
    };

    const handleDiscntChange = (e) => {
        setFormIsDirty(true);
        setAddDiscountFormState({ ...addDiscountFormState, [e.target.name]: e.target.value });
    };

    const handleCustomerChange = (e) => {
        setFormIsDirty(true);
        setCustomerFormState({ ...customerFormState, [e.target.name]: e.target.value });

        // err handlers
        if (e.target.value == '') {
            setCustomerFormErrState({ ...customerFormErrState, [e.target.name]: true });
        } else {
            setCustomerFormErrState({ ...customerFormErrState, [e.target.name]: false });
        }
    };

    const handleDefaultTaxChange = (e) => {
        setTaxFormState({ ...taxFormState, taxes_and_charges: e.target.value });

        const data = { master_doctype: 'Sales Taxes and Charges Template', master_name: e.target.value };
        if (e?.target?.value) {
            fetch(`${process.env.REACT_APP_API_URI}/api/get-taxes-template`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (data?.message) {
                        setDefaultTaxDetails(data?.message);
                    }
                });
        }
    };

    const handleSaveQuotation = () => {
        setFormIsDirty(false);
        setLoadingButton(true);
        let taxes = [];
        let itemTaxes = [];
        selectedItems?.map((el) => {
            if (el?.item_tax_rate && el?.item_tax_rate !== '{}') {
                itemTaxes.push(Object?.keys(JSON?.parse(el?.item_tax_rate))[0]);
            }
        });
        let uniqueItemTaxes = [...new Set(itemTaxes)];

        uniqueItemTaxes.map((el) => {
            taxes.push({
                doctype: 'Sales Taxes and Charges',
                charge_type: 'On Net Total',
                parentfield: 'taxes',
                parenttype: 'Quotation',
                description: el?.substring(0, el?.lastIndexOf(' ')),
                account_head: el
            });
        });

        const selectedTaxTemplate = {
            doctype: 'Sales Taxes and Charges',
            charge_type: defaultTaxDetails?.[0]?.charge_type,
            parentfield: 'taxes',
            parenttype: 'Quotation',
            description: defaultTaxDetails?.[0]?.description,
            account_head: defaultTaxDetails?.[0]?.account_head,
            tax_amount: defaultTaxDetails?.[0]?.tax_amount,
            rate: defaultTaxDetails?.[0]?.rate
        };

        let data = {
            ...(!id && { __islocal: 1 }),
            ...(id && {
                creation: doc.creation,
                modified: doc.modified,
                owner: doc.owner,
                modified_by: doc.owner,
                name: doc.name,
                naming_series: doc?.naming_series
            }),
            doctype: 'Quotation',
            __unsaved: 1,
            quotation_to: 'Customer',
            company: company,
            transaction_date: moment().format('YYYY-MM-DD'),
            order_type: 'Sales',
            currency: formState.currency,
            selling_price_list: formState.selling_price_list,
            ignore_pricing_rule: 1,
            apply_discount_on: addDiscountFormState.apply_discount_on,
            group_same_items: 0,
            items: selectedItems,
            valid_till: formState.valid_till,
            party_name: customerFormState.party_name,
            // customerDetails
            customer: customerFormState?.party_name,
            contact_email: customerDetails?.email_id,
            contact_mobile: customerDetails?.mobile_no,
            customer_name: customerDetails?.customer_name,
            customer_group: customerDetails?.customer_group,
            territory: customerDetails?.territory,
            payment_terms_template: null,
            taxes: taxFormState?.taxes_and_charges ? [selectedTaxTemplate] : taxes,
            additional_discount_percentage: parseFloat(addDiscountFormState?.additional_discount_percentage),
            discount_amount: parseFloat(addDiscountFormState?.discount_amount)
        };

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Quotation successfully Saved' }));
                    id ? refetchDoc({}) : navigate('/sales/quotation-list');
                    dispatch(SetItemsQuotation([]));
                }
            });
    };

    const handleCancelQuotation = () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/cancel-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Quotation', name: id })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Quotation successfully cancelled' }));
                    navigate('/sales/quotation-list');
                }
            });
    };

    const handleSubmitQuotation = () => {
        setSubmitQuotationLoading(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: doc, action: 'Submit' })
        })
            .then((res) => {
                setSubmitQuotationLoading(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Quotation successfully Submitted' }));
                    refetchDoc({});
                    dispatch(SetItemsQuotation([]));
                }
            });
    };

    const makeMappedDoc = () => {
        setMappedDocLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/make-mapped-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ method: 'erpnext.selling.doctype.quotation.quotation.make_sales_order', source_name: id })
        })
            .then((res) => {
                setMappedDocLoadingButton(false);
                return res.json();
            })
            .then((data) => {
                if (data?.message) {
                    dispatch(SetMappedSalesOrder(data?.message));
                    navigate('/sales/sales-order');
                }
            });
    };

    const setDoc4SalesInvoice = () => {
        if (doc?.name) {
            setSalesInvoiceMakingLoading(true);
            fetch(`${process.env.REACT_APP_API_URI}/api/make-mapped-doc`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ method: 'erpnext.selling.doctype.quotation.quotation.make_sales_order', source_name: id })
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (data?.message) {
                        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                            method: 'POST',
                            headers: header,
                            body: JSON.stringify({
                                doc: { ...data?.message, status: 'To Deliver and Bill', delivery_date: moment().format('YYYY-MM-DD') },
                                action: 'Submit'
                            })
                        })
                            .then((res) => {
                                if (!res.ok) {
                                    dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                                }
                                return res.json();
                            })
                            .then((dataDoc) => {
                                if (dataDoc?.success === false) {
                                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(dataDoc?.message) }));
                                } else {
                                    fetch(`${process.env.REACT_APP_API_URI}/api/make-mapped-doc`, {
                                        method: 'POST',
                                        headers: header,
                                        body: JSON.stringify({
                                            method: 'erpnext.selling.doctype.sales_order.sales_order.make_sales_invoice',
                                            source_name: dataDoc?.docs?.[0]?.name
                                        })
                                    })
                                        .then((res) => {
                                            setSalesInvoiceMakingLoading(false);
                                            return res.json();
                                        })
                                        .then((mappedData) => {
                                            if (mappedData?.message) {
                                                dispatch(SetMappedSalesInvoice(mappedData?.message));
                                                navigate('/sales/tax-invoice');
                                            }
                                        });
                                }
                            });
                    }
                });
        }
    };

    useEffect(() => {
        if (id && doc?.name) {
            setFormState({
                valid_till: doc?.valid_till,
                currency: doc?.currency,
                selling_price_list: doc?.selling_price_list
            });
            setAddDiscountFormState({
                coupon_code: doc?.coupon_code,
                additional_discount_percentage: doc?.additional_discount_percentage,
                discount_amount: doc?.discount_amount,
                apply_discount_on: doc?.apply_discount_on
            });
            handleDefaultTaxChange({
                target: {
                    value: doc?.taxes_and_charges
                        ? doc?.taxes_and_charges
                        : doc?.taxes?.length === 1
                        ? taxesAndCharges?.find((obj) => obj?.name?.includes(doc?.taxes?.[0]?.account_head))?.name
                        : ''
                }
            });
            setCustomerFormState({
                party_name: doc?.customer_name
            });
        }
    }, [doc]);

    useEffect(() => {
        if (customerFormState?.party_name) {
            fetch(`${process.env.REACT_APP_API_URI}/api/details?doctype=Customer&name=${customerFormState?.party_name}`, {
                method: 'GET',
                headers: header
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (data?.docs[0]) {
                        setCustomerDetails(data?.docs[0]);
                    }
                });
        }
    }, [customerFormState?.party_name]);

    const disableSaveButton = () => {
        if (
            selectedItems?.length === 0 ||
            !formState?.valid_till ||
            !formState?.currency ||
            !formState?.selling_price_list ||
            !customerFormState.party_name
        ) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        const taxWithFive = taxesAndCharges.filter((tax) => tax?.name?.includes('5%'));
        if (taxWithFive?.length) {
            handleDefaultTaxChange({
                target: {
                    value: taxWithFive[0]?.name
                }
            });
        }
    }, [taxesAndCharges]);

    const handleDownloadPdf = () => {
        const options = {
            filename: `${id}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        const element = componentRef.current;

        html2pdf().from(element).set(options).save();
    };

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Box p={-1} display={'flex'} flexDirection={'column'}>
                <ArrowBackIcon
                    className={classes.backLink}
                    onClick={() => {
                        navigate(-1);
                        selectItemsQuotation && dispatch(SetItemsQuotation([]));
                    }}
                />
                <Box display={'flex'}>
                    {id ? `Quotation: ${doc?.name}` : 'New Quotation'}
                    {id && <Chip size="small" color="warning" variant="outlined" sx={{ ml: 1 }} label={doc?.status} />}
                </Box>
            </Box>
            <Box>
                {doc?.status === 'Open' && (
                    <>
                        <LoadingButton
                            startIcon={<ReceiptIcon color={'Menu'} />}
                            variant={'contained'}
                            color="blue"
                            sx={{ ml: 1 }}
                            onClick={setDoc4SalesInvoice}
                            loading={salesInvoiceMakingLoading}
                            disabled={doc && id && isSameDate(doc)}
                        >
                            {`Make an Invoice`}
                        </LoadingButton>
                        {defaultCompany && defaultCompany?.name !== 'Gautier' && (
                            <LoadingButton
                                startIcon={<ReceiptIcon color={'Menu'} />}
                                variant={'contained'}
                                color="blue"
                                sx={{ ml: 1 }}
                                onClick={makeMappedDoc}
                                loading={mappedDocLoadingButton}
                                disabled={doc && id && isSameDate(doc)}
                            >
                                {`Make a Sales Order`}
                            </LoadingButton>
                        )}
                    </>
                )}
                {doc?.status === 'Draft' && (
                    <LoadingButton
                        startIcon={<ReceiptIcon color={'Menu'} />}
                        variant={'contained'}
                        color="blue"
                        sx={{ ml: 1 }}
                        onClick={handleSubmitQuotation}
                        loading={submitQuotationLoading}
                    >
                        {`Submit Quotation`}
                    </LoadingButton>
                )}
                {doc?.name && (
                    <>
                        <ReactToPrint
                            trigger={() => (
                                <IconButton sx={{ ml: 1 }} aria-label="delete" color="blue">
                                    <PrintIcon />
                                </IconButton>
                            )}
                            content={() => componentRef.current}
                        />
                        {/* <IconButton onClick={handleDownloadPdf} aria-label="delete">
                            <PictureAsPdfIcon />
                        </IconButton> */}
                        <EmailSender doctype={'Quotation'} name={id} recipientEmail={customerDetails?.email_id} />
                    </>
                )}
            </Box>
        </Box>
    );

    useEffect(() => {
        if (!id) {
            if (sellingPriceLists?.length) {
                setFormState({
                    ...formState,
                    selling_price_list: sellingPriceLists?.[0]?.name
                });
            }
        }
    }, [sellingPriceLists]);

    if (id && isPending) return <Loader />;
    if (isCurrenciesPending || isSellingPriceListsPending || isTaxesAndChargesPending || isCustomersPending) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                <Box display={'flex'} mt={-4} mb={2}>
                    <InfoIcon sx={{ mt: 0.6 }} />
                    <Typography p={1} variant="h5" color="primary">
                        {`A quotation is an estimated cost of the products/services you're selling to your future/present customer.`}
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <FormDateTime
                            required
                            type="date"
                            label={'Valid Until'}
                            name="valid_till"
                            value={formState.valid_till}
                            handleChange={handleChange}
                            disabled={id && doc?.status !== 'Draft'}
                            isValidDate
                            helper={
                                id && doc && doc?.status !== 'Draft' && isSameDate(doc) && 'Validity period of this quotation has ended.'
                            }
                        />
                        {false && (
                            <>
                                <Box mt={2} mb={2}>
                                    <Divider>
                                        <Chip label="Currency & Price List" />
                                    </Divider>
                                </Box>
                                <SelectForm
                                    sx={{ mb: 2 }}
                                    label={'Currency'}
                                    error={formErrState?.currency}
                                    value={formState.currency}
                                    handleChange={handleChange}
                                    data={currencies}
                                    name="currency"
                                    propToRender="name"
                                    required
                                    disabled={id && doc?.status !== 'Draft'}
                                />
                                <SelectForm
                                    label={'Price List'}
                                    error={formErrState?.selling_price_list}
                                    value={formState.selling_price_list}
                                    handleChange={handleChange}
                                    data={sellingPriceLists}
                                    name="selling_price_list"
                                    propToRender="name"
                                    required
                                    disabled={id && doc?.status !== 'Draft'}
                                />
                            </>
                        )}
                        <Box mt={2} mb={2}>
                            <Divider>
                                <Chip label="Taxes and Charges" />
                            </Divider>
                        </Box>
                        <SelectForm
                            sx={{ mb: 2 }}
                            label={'VAT'}
                            value={taxFormState.taxes_and_charges}
                            handleChange={handleDefaultTaxChange}
                            data={taxesAndCharges}
                            name="taxes_and_charges"
                            propToRender="name"
                            disabled={id && doc?.status !== 'Draft'}
                        />
                        <Box mt={2} mb={2}>
                            <Divider>
                                <Chip label="Additional Discount" />
                            </Divider>
                        </Box>
                        <InputTextField
                            sx={{ mb: 2 }}
                            isPercentage
                            type="number"
                            handleChange={handleDiscntChange}
                            value={addDiscountFormState.additional_discount_percentage}
                            label="Discount (%)"
                            name="additional_discount_percentage"
                            disabled={id && doc?.status !== 'Draft'}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <MainCard
                            title={
                                <Box display={'flex'}>
                                    <PersonIcon sx={{ mr: 1.5, ml: -1.5 }} />
                                    Customer
                                </Box>
                            }
                            divider
                            border
                        >
                            <SelectForm
                                required
                                fullwidth
                                label={'Customer'}
                                error={customerFormErrState?.party_name}
                                value={customerFormState?.party_name}
                                handleChange={handleCustomerChange}
                                data={customers}
                                name="party_name"
                                propToRender="name"
                                flagAddDefaultCustomer
                                refetch={refetch}
                                setFormState={setCustomerFormState}
                                disabled={id && doc?.status !== 'Draft'}
                            />
                            <Box mt={2} mb={2}>
                                <Divider>
                                    <Chip label="Customer Details" />
                                </Divider>
                            </Box>
                            <Box mt={1}>
                                <Typography id="modal-modal-description" sx={{ fontSize: 14, mt: 1 }}>
                                    <strong>Customer Name:</strong> {customerDetails?.customer_name ? customerDetails?.customer_name : '-'}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ fontSize: 14, mt: 1 }}>
                                    <strong>Phone:</strong> {customerDetails?.mobile_no ? customerDetails?.mobile_no : '-'}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ fontSize: 14, mt: 1 }}>
                                    <strong>Address:</strong>{' '}
                                    {customerDetails?.primary_address ? formatErpResponse(customerDetails?.primary_address) : '-'}
                                </Typography>
                            </Box>
                        </MainCard>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <ProductTable
                        company={company}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        priceList={formState.selling_price_list}
                        currency={formState.currency}
                        addDiscountFormState={addDiscountFormState}
                        initialState={doc?.items}
                        uneditable={doc?.name ? (doc?.status === 'Draft' ? false : true) : false}
                        doctype={'Quotation'}
                        defaultTaxDetails={defaultTaxDetails}
                        isProdQuotation
                        setFormIsDirty={setFormIsDirty}
                    />
                </Box>
                {doc?.packed_items && doc?.packed_items?.length !== 0 && (
                    <Box mt={3}>
                        <BundleItemsTable packedItems={doc?.packed_items} currency={doc?.currency} />
                    </Box>
                )}
                {id && (
                    <Box mt={2}>
                        <Comments docinfo={docinfo} refetch={refetchDoc} doc={doc} />
                    </Box>
                )}
                <Box display={'flex'} justifyContent={'right'} mt={3}>
                    <LoadingButton
                        disabled={doc?.name ? (doc?.status === 'Draft' ? disableSaveButton() : false) : disableSaveButton()}
                        loading={loadingButton}
                        variant="contained"
                        size="large"
                        color={doc?.name ? (doc?.status === 'Draft' ? 'blue' : 'error') : 'blue'}
                        onClick={doc?.name ? (doc?.status === 'Draft' ? handleSaveQuotation : handleCancelQuotation) : handleSaveQuotation}
                    >
                        {doc?.name ? (doc?.status === 'Draft' ? 'Save' : 'Cancel') : 'Save'}
                    </LoadingButton>
                </Box>
            </MainCard>
            {doc?.name && (
                <Box display={'none'}>
                    <PrintInvoiceTemplate
                        ref={componentRef}
                        doc={doc}
                        selectedItems={selectedItems}
                        isQuotation
                        customerDetails={customerDetails}
                        defaultTaxDetails={defaultTaxDetails}
                    />
                </Box>
            )}
            <NavigationModal open={modalOpen} onClose={handleCloseBlocker} />
        </Box>
    );
};

export default QuotationForm;
