import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './reducers/customizationReducer';
import loginReducer from './reducers/loginReducer';
import notificationReducer from './reducers/notificationReducer';
import pimReducer from './reducers/pimReducer';
import warehouseReducer from './reducers/warehouseReducer';
import productReducer from './reducers/productReducer';
import themeReducer from './reducers/themeReducer';
import mappedDocsReducer from './reducers/mappedDocsReducer';
import configurationReducer from './reducers/configuration.reducer';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    notification: notificationReducer,
    customization: customizationReducer,
    login: loginReducer,
    core: warehouseReducer,
    pim: pimReducer,
    product: productReducer,
    theme: themeReducer,
    mappedDocs: mappedDocsReducer,
    configuration: configurationReducer
});

export default reducer;
