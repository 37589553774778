import { Button, Divider, Grid, Modal } from '@mui/material';
import { Box } from '@mui/system';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import Loader from 'ui-component/Loader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { modalStyle } from 'views/utilities/ModalStyle';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { useTranslation } from 'react-i18next';

const CustomizeFormItem = () => {
    const dispatch = useDispatch();
    const [list, setList] = useState(0);
    const { t } = useTranslation();
    const initialMandatoryFieldsState = [
        { id: 'item_code', label: t('products:productCode') },
        { id: 'item_name', label: t('products:productName') },
        { id: 'item_group', label: t('products:productCategory') },
        { id: 'company', label: t('company:company') },
        { id: 'standard_rate', label: t('dashboard:standardRate') },
        { id: 'valuation_rate', label: t('dashboard:valuationRate') },
        { id: 'item_tax_template', label: t('products:taxModel') },
        { id: 'default_price_list', label: t('products:defaultPriceList') },
        { id: 'default_warehouse', label: t('products:defaultWarehouse') }
    ];
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const { data: itemFields, isPending } = useFetch(
        `/api/run-method`,
        { method: 'fetch_to_customize', docs: { doctype: 'Customize Form', doc_type: 'Item' } },
        'POST'
    );

    const { data: mandatoryFieldsInLara, refetch } = useFetch(`/api/product-fields`, { company: globalDefaults.default_company }, 'POST');

    /* DRAG&DROP */

    const DraggableButton = (props) => {
        const { tab } = props;
        const [{ isDragging }, drag] = useDrag({
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging()
            }),
            item: props,
            type: 'button'
        });
        const handleMouseDown = () => {
            if (tab) {
                setList(tab);
            } else {
                setList(0);
            }
        };
        return (
            <Button
                onMouseDown={handleMouseDown}
                draggable={true}
                variant="outlined"
                color={isDragging ? 'blue' : 'primary'}
                fullWidth
                size="large"
                sx={{ p: 1, mb: 1, justifyContent: 'flex-start' }}
                ref={drag}
                {...props}
            >
                {props.label}
            </Button>
        );
    };

    const Bucket = (props) => {
        const [{ canDrop, isOver }, drop] = useDrop(() => ({
            accept: 'button',
            drop: (item, monitor) => {
                let data = mandatoryFields;
                if (list === 2) {
                    // Add
                    const index = data.findIndex((el) => el.id === item.id);
                    if (index === -1) {
                        data = [{ id: item.id, label: item.label }, ...data];
                        setMandatoryFields(data);
                    }
                } else if (list === 3) {
                    // Delete
                    setMandatoryFields(data.filter((el) => el.id != item.id));
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }));

        return (
            <Box ref={drop}>
                {canDrop && (
                    <Button
                        className="drag-handle"
                        variant="outlined"
                        color="blue"
                        fullWidth
                        size="large"
                        sx={{ p: 1, mb: 1, opacity: 0.5 }}
                    >
                        Insérer ici
                    </Button>
                )}
            </Box>
        );
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [sectionBreaks, setSectionBreaks] = useState([]);
    const [selectedSectionFields, setSelectedSectionFields] = useState([]);
    const [mandatoryFields, setMandatoryFields] = useState(initialMandatoryFieldsState);
    const [state, setState] = useState([]);

    const handleSelect = (selectedSectionBreak) => {
        const sectionIndex = state?.findIndex((el) => el?.section?.name === selectedSectionBreak?.name);
        if (sectionIndex !== -1) {
            setSelectedSectionFields(state[sectionIndex]);
        }
    };

    const handleSaveMandatoryFields = () => {
        const data = {
            product_required_fields: JSON.stringify(mandatoryFields),
            company: globalDefaults.default_company
        };
        const header = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-product-fields`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ message: "Une erreur s'est produite lors de l'enregistrement", code: 'error' }));
                }
                return res.json();
            })
            .then((data) => {
                data && dispatch(SetNotification({ message: `Changements enregistrés (${data.company})`, code: 'success' }));
                refetch({});
            });
    };

    useEffect(() => {
        if (itemFields && itemFields?.docs && Array.isArray(itemFields?.docs)) {
            let data = [];
            data = itemFields?.docs?.[0]?.fields?.filter((el) => el?.fieldtype !== 'Tab Break' && el?.fieldtype !== 'Column Break');
            let filteredData = data?.filter((el) => el?.fieldtype === 'Section Break');
            let indexes = [];
            filteredData?.map((el, i) => {
                indexes.push(data.findIndex((e) => e.fieldname === el?.fieldname));
            });

            let fakeState = [{ section: itemFields?.docs?.[0]?.fields[0], arr: data?.slice(0, indexes[0]) }];
            indexes?.map((index, i) => {
                let result = {};
                result = {
                    section: data[index],
                    arr: data?.slice(index + 1, indexes[i + 1])
                };
                fakeState.push(result);
            });
            setState(fakeState);
            let sctBrks = [];
            fakeState?.map((el) => {
                sctBrks.push(el?.section);
            });
            setSectionBreaks(sctBrks);
        }
    }, [itemFields]);
    const initialMandatoryFields = () => {
        const row = mandatoryFieldsInLara.find((el) => el?.company === globalDefaults?.default_company);
        if (row && row?.product_required_fields) {
            let data = JSON.parse(row?.product_required_fields);
            if (data && data?.length) {
                setMandatoryFields(data);
            }
        }
    };
    useEffect(() => {
        initialMandatoryFields();
    }, [mandatoryFieldsInLara]);

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            {"Liste de Types d'attributs"}
            <Box>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button variant="outlined" color="blue" onClick={handleOpen} sx={{ marginTop: -3 }}>
                            {t('common:addField')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

    const addCustomField = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:addField')}>
                {t('common:comingSoon')}!
            </MainCard>
        </Box>
    );

    if (isPending) return <Loader />;

    return (
        <Box>
            <div>
                <MainCard title={title}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Box p={1} bgcolor={'Menu'} borderRadius={2}>
                                <MainCard title={'Groupes'}>
                                    <PerfectScrollbar style={{ height: 290, maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}>
                                        <Box>
                                            {sectionBreaks?.map((field, i) => (
                                                <Button
                                                    key={i}
                                                    variant="contained"
                                                    color={selectedSectionFields?.section?.label === field.label ? 'info' : 'primary'}
                                                    fullWidth
                                                    size="large"
                                                    sx={{ p: 1, mb: 1, justifyContent: 'flex-start' }}
                                                    onClick={() => handleSelect(field)}
                                                >
                                                    {field.label}
                                                </Button>
                                            ))}
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </Box>
                        </Grid>
                        <DndProvider backend={HTML5Backend}>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <Box p={1} bgcolor={'Menu'} borderRadius={2}>
                                    <MainCard title={t('common:fields')}>
                                        <PerfectScrollbar style={{ height: 290, maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}>
                                            {list === 3 && <Bucket />}
                                            {!selectedSectionFields?.arr && <Box></Box>}
                                            {selectedSectionFields?.arr?.map((field, i) => (
                                                /* List 3 items (Product sheet) are no longer displayed in list 2 (Fields) */
                                                <>
                                                    {mandatoryFields.findIndex((el) => el.id === field.fieldname) < 0 && (
                                                        <DraggableButton
                                                            key={field.fieldname}
                                                            id={field.fieldname}
                                                            label={field.label}
                                                            tab={2}
                                                        />
                                                    )}
                                                </>
                                            ))}
                                        </PerfectScrollbar>
                                    </MainCard>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <Box p={1} bgcolor={'Menu'} borderRadius={2}>
                                    <MainCard title={t('products:productSheet')}>
                                        <PerfectScrollbar style={{ height: 290, maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}>
                                            {list === 2 && <Bucket />}
                                            {mandatoryFields?.map((field, i) => (
                                                <DraggableButton key={field?.id} id={field?.id} label={field?.label} tab={3} />
                                            ))}
                                        </PerfectScrollbar>
                                    </MainCard>
                                </Box>
                            </Grid>
                        </DndProvider>
                    </Grid>
                </MainCard>

                <Box display={'flex'} justifyContent={'right'} mt={2} mb={2}>
                    <Button sx={{ mr: 1 }} onClick={() => initialMandatoryFields()}>
                        {t('common:cancel')}
                    </Button>
                    <Button
                        onClick={handleSaveMandatoryFields}
                        variant="contained"
                        color="blue"
                        size="large"
                        disabled={mandatoryFields?.length == 9}
                    >
                        {t('common:save')}
                    </Button>
                </Box>
            </div>
            <Modal onClose={handleClose} open={open}>
                {addCustomField}
            </Modal>
        </Box>
    );
};

export default CustomizeFormItem;
