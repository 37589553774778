import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ItemGroupDetails from 'views/products/category/ItemGroupDetails';
import ItemGroupList from 'views/products/category/ItemGroupList';
import AddCategoryForm from 'views/products/category/AddCategoryForm';
import ItemAttributesList from 'views/products/itemAttributes/ItemAttributesList';
import AddItemAttributeForm from 'views/products/itemAttributes/AddItemAttributeForm';
import ItemGroupTree from 'views/products/category/ItemGroupTree';
import AddItemTemplateForm from 'views/products/productManagement/AddItemTemplateForm';
import TemplateProdDetails from 'views/products/productManagement/components/TemplateProdDetails';
import BulkEditForm from 'views/products/productManagement/components/BulkEditForm';
import AllFilesList from 'views/files/AllFilesList';
import DepotsList from 'views/depots/DepotsList';
import AddNewDepot from 'views/depots/AddNewDepot';
import SalesPersonList from 'views/salesPerson/SalesPersonList';
import AddSalesPerson from 'views/salesPerson/AddSalesPerson';
import SalesPersonTree from 'views/salesPerson/SalesPersonTree';
import CustomizeFormItem from 'views/products/customizeForm/CustomizeFormItem';
import CoreDashboard from 'views/dashboard/CoreDashboard';
import Discount from 'views/discount/Discount';
import DiscountForm from 'views/discount/DiscountForm';
import ItemCollectionList from 'views/products/itemcollection/ItemCollectionList';
import ItemCollectionForm from 'views/products/itemcollection/ItemCollectionForm';
import ProductBundleList from 'views/products/productBundle/ProductBundleList';
import ProductBundleForm from 'views/products/productBundle/ProductBundleForm';
import DashboardPIM from 'views/products/pimDashboard/DashboardPIM';
import PricingRulesList from 'views/crm/pricingRules/PricingRulesList';
import PricingRuleForm from 'views/crm/pricingRules/PricingRuleForm';
import SalesOrderForm from 'views/purchaseOrder/components/SalesOrderForm';
import QuotationForm from 'views/quotations/components/QuotationForm';
import LoyaltyProgramList from 'views/crm/loyatlyProgram/LoyaltyProgramList';
import LoyaltyProgramForm from 'views/crm/loyatlyProgram/LoyaltyProgramForm';
import BonLivraisonForm from 'views/facturation/BonLivraisonForm';
import SalesInvoiceList from 'views/invoices/sales/SalesInvoice';
import SalesInvoiceForm from 'views/invoices/sales/SalesInvoice/components/SalesInvoiceForm';
import OnlineUsers from 'views/profile/users/OnlineUsers';
import CustomerList from 'views/crm/customer/CustomerList';
import CustomerDetails from 'views/crm/customer/CustomerDetails';
import DepositInvoiceList from 'views/invoices/sales/depositInvoice';
import AddCouponCode from 'views/crm/pricingRules/codeCoupon/AddCouponCode';
import CouponCodeList from 'views/crm/pricingRules/codeCoupon/CouponCodeList';
import LoyaltyCardList from 'views/crm/loyaltyCard/LoyaltyCardList';
import LoyaltyCardForm from 'views/crm/loyaltyCard/LoyaltyCardForm';
import DepotTree from 'views/depots/DepotTree';
import ItemChannelsForm from 'views/products/itemChannels/ItemChannelsForm';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const PimProducts = Loadable(lazy(() => import('views/products/index')));
const WarehouseTree = Loadable(lazy(() => import('views/warehouse/WarehouseTree')));
// sample page routing
const PosProfile = Loadable(lazy(() => import('views/profile')));
const AddNewProfile = Loadable(lazy(() => import('views/profile/AddNewProfile')));
const Warehouse = Loadable(lazy(() => import('views/warehouse')));
const AddNewWarehouse = Loadable(lazy(() => import('views/warehouse/AddNewWarehouse')));
const RoleDetails = Loadable(lazy(() => import('views/profile/RoleDetails/RoleDetails')));
const UserList = Loadable(lazy(() => import('views/profile/users/UserList')));
const AddNewCompany = Loadable(lazy(() => import('views/company/AddNewCompany')));
const CompanyList = Loadable(lazy(() => import('views/company/CompanyList')));
const CompanyTree = Loadable(lazy(() => import('views/company/CompanyTree')));
const RolesList = Loadable(lazy(() => import('views/roles/RolesList')));
const AddNewRoles = Loadable(lazy(() => import('views/roles/AddNewRoles')));

const AddNewUser = Loadable(lazy(() => import('views/profile/users/AddNewUser')));

const AddNewPOS = Loadable(lazy(() => import('views/Pointofsale/AddNewPOS')));
const POSList = Loadable(lazy(() => import('views/Pointofsale/POSList')));
const HistoryPosProfile = Loadable(lazy(() => import('views/Pointofsale/HistoryPosProfile')));
const UserConnectedList = Loadable(lazy(() => import('views/profile/userConnected/UserConnectedList')));
const PaymentMethodsList = Loadable(lazy(() => import('views/paymentMethods/PaymentMethodsList')));
const AddPaymentMethodsForm = Loadable(lazy(() => import('views/paymentMethods/AddPaymentMethodsForm')));
const AccountSettings = Loadable(lazy(() => import('views/accountSetting/AccountSettings')));
const CurrenciesList = Loadable(lazy(() => import('views/currencies/CurrenciesList')));
const AddCurrencies = Loadable(lazy(() => import('views/currencies/AddCurrencies')));
const TaxesList = Loadable(lazy(() => import('views/taxes/TaxesList')));
const AddTaxes = Loadable(lazy(() => import('views/taxes/AddTaxes')));
const AddNewChannel = Loadable(lazy(() => import('views/canaux/AddNewChannel')));
const CanauxList = Loadable(lazy(() => import('views/canaux/CanauxList')));
const MyStock = Loadable(lazy(() => import('views/stock/index')));
const MyStockDetails = Loadable(lazy(() => import('views/stock/stockDetails/index')));
const QuotationList = Loadable(lazy(() => import('views/quotations/index')));
const SalesOrderList = Loadable(lazy(() => import('views/purchaseOrder/index')));
const FacturationList = Loadable(lazy(() => import('views/facturation/index')));
const SalesDashboard = Loadable(lazy(() => import('views/SalesDashboard/SalesDashboard')));
/***************[520]**************************** */
const StoresList = Loadable(lazy(() => import('views/salesbystore/StoresList')));
const SalesList = Loadable(lazy(() => import('views/salesbystore/SalesList')));
const DefaultDashboard = Loadable(lazy(() => import('views/dashboard/DefaultDashboard')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DefaultDashboard />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DefaultDashboard />
                }
            ]
        },
        {
            path: 'online',
            children: [
                {
                    path: 'users',
                    element: <OnlineUsers />
                }
            ]
        },
        {
            path: 'sales',
            children: [
                {
                    path: 'dashboard',
                    element: <SalesDashboard />
                },
                {
                    path: 'stock-view',
                    element: <MyStock />
                },
                {
                    path: 'stock/:id',
                    element: <MyStockDetails />
                },
                {
                    path: 'quotation-list',
                    element: <QuotationList />
                },
                {
                    path: 'quotation/:id',
                    element: <QuotationForm />
                },
                {
                    path: 'quotation',
                    element: <QuotationForm />
                },
                {
                    path: 'sales-order-list',
                    element: <SalesOrderList />
                },
                {
                    path: 'sales-order/:id',
                    element: <SalesOrderForm />
                },
                {
                    path: 'sales-order',
                    element: <SalesOrderForm />
                },
                {
                    path: 'delivery-notes',
                    element: <FacturationList />
                },
                {
                    path: 'stores',
                    element: <StoresList />
                },
                {
                    path: 'bystore/:idstore/:idcompany',
                    element: <SalesList />
                },
                {
                    path: 'delivery-note',
                    element: <BonLivraisonForm />
                },
                {
                    path: 'delivery-note/:id',
                    element: <BonLivraisonForm />
                },
                {
                    path: 'tax-invoice-list',
                    element: <SalesInvoiceList />
                },
                {
                    path: 'deposit-invoice-list',
                    element: <DepositInvoiceList />
                },
                {
                    path: 'tax-invoice/:id',
                    element: <SalesInvoiceForm />
                },
                {
                    path: 'tax-invoice',
                    element: <SalesInvoiceForm />
                },
                {
                    path: 'customer-details/:id',
                    element: <CustomerDetails />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'core',
            children: [
                {
                    path: 'new-pos',
                    element: <AddNewPOS />
                },
                {
                    path: 'new-pos/:id',
                    element: <AddNewPOS />
                },
                {
                    path: 'pos',
                    element: <POSList />
                },
                {
                    path: 'pos-details/:id',
                    element: <HistoryPosProfile />
                },
                {
                    path: 'new-company',
                    element: <AddNewCompany />
                },
                {
                    path: 'edit-company/:id',
                    element: <AddNewCompany />
                },
                {
                    path: 'company',
                    element: <CompanyList />
                },
                {
                    path: 'company-tree',
                    element: <CompanyTree />
                },
                {
                    path: 'profiles',
                    element: <PosProfile />
                },
                {
                    path: 'new-profiles',
                    element: <AddNewProfile />
                },
                {
                    path: 'role-profile/:id',
                    element: <RoleDetails />
                },
                {
                    path: 'edit-role-profile/:id',
                    element: <AddNewProfile />
                },
                {
                    path: 'user-list-view',
                    element: <UserList />
                },
                {
                    path: 'new-user',
                    element: <AddNewUser />
                },
                {
                    path: 'edit-user/:id',
                    element: <AddNewUser />
                },
                {
                    path: 'magasins',
                    element: <Warehouse />
                },
                {
                    path: 'magasins-tree',
                    element: <WarehouseTree />
                },
                {
                    path: 'new-magasin',
                    element: <AddNewWarehouse />
                },
                {
                    path: 'edit-magasin/:id',
                    element: <AddNewWarehouse />
                },
                {
                    path: 'roles',
                    element: <RolesList />
                },
                {
                    path: 'edit-roles/:id',
                    element: <AddNewRoles />
                },
                {
                    path: 'connected-user',
                    element: <UserConnectedList />
                },
                {
                    path: 'user/account-profile',
                    element: <AccountSettings />
                },
                {
                    path: 'mode-of-payment',
                    element: <PaymentMethodsList />
                },
                {
                    path: 'new-mode-of-payment',
                    element: <AddPaymentMethodsForm />
                },
                {
                    path: 'mode-of-payment/:id',
                    element: <AddPaymentMethodsForm />
                },
                {
                    path: 'currencies-list',
                    element: <CurrenciesList />
                },
                {
                    path: 'currencies',
                    element: <AddCurrencies />
                },
                {
                    path: 'currencies/:id',
                    element: <AddCurrencies />
                },
                {
                    path: 'taxes-list',
                    element: <TaxesList />
                },
                {
                    path: 'new-taxe',
                    element: <AddTaxes />
                },
                {
                    path: 'taxe/:id',
                    element: <AddTaxes />
                },
                {
                    path: 'depots',
                    element: <DepotsList />
                },
                {
                    path: 'depots-tree',
                    element: <DepotTree />
                },
                {
                    path: 'new-depots',
                    element: <AddNewDepot />
                },
                ,
                {
                    path: 'depots/:id',
                    element: <AddNewDepot />
                },
                {
                    path: 'vendeurs',
                    element: <SalesPersonList />
                },
                {
                    path: 'new-vendeur',
                    element: <AddSalesPerson />
                },
                {
                    path: 'vendeur/:id',
                    element: <AddSalesPerson />
                },
                {
                    path: 'vendeur-tree',
                    element: <SalesPersonTree />
                },
                {
                    path: 'dashboard',
                    element: <CoreDashboard />
                },
                {
                    path: 'discount',
                    element: <Discount />
                },
                {
                    path: 'new-discount',
                    element: <DiscountForm />
                },
                {
                    path: 'edit-discount/:id',
                    element: <DiscountForm />
                }
            ]
        },

        {
            path: 'pim',
            children: [
                {
                    path: 'dashboard',
                    element: <DashboardPIM />
                },
                {
                    path: 'canaux',
                    element: <CanauxList />
                },
                {
                    path: 'new-channel',
                    element: <AddNewChannel />
                },
                {
                    path: 'edit-channel/:id',
                    element: <AddNewChannel />
                },
                {
                    path: 'products-view',
                    element: <PimProducts />
                },
                {
                    path: 'product-view/:id',
                    element: <TemplateProdDetails />
                },
                {
                    path: 'category-view/:id',
                    element: <ItemGroupDetails />
                },
                {
                    path: 'products-category-list-view',
                    element: <ItemGroupList />
                },
                {
                    path: 'products-category-tree-view',
                    element: <ItemGroupTree />
                },
                {
                    path: 'products-new-category',
                    element: <AddCategoryForm />
                },
                {
                    path: 'add-products',
                    element: <AddItemTemplateForm />
                },
                {
                    path: 'product-attributes',
                    element: <ItemAttributesList />
                },
                {
                    path: 'products-new-attribute',
                    element: <AddItemAttributeForm />
                },
                {
                    path: 'product-attribute/:id',
                    element: <AddItemAttributeForm />
                },
                {
                    path: 'products-bulk-edit',
                    element: <BulkEditForm />
                },
                {
                    path: 'all-files',
                    element: <AllFilesList />
                },
                {
                    path: 'customize',
                    element: <CustomizeFormItem />
                },
                {
                    path: 'product-collections',
                    element: <ItemCollectionList />
                },
                {
                    path: 'product-collection/:id',
                    element: <ItemCollectionForm />
                },
                {
                    path: 'product-collection',
                    element: <ItemCollectionForm />
                },
                {
                    path: 'product-bundles',
                    element: <ProductBundleList />
                },
                {
                    path: 'product-bundle/:id',
                    element: <ProductBundleForm />
                },
                {
                    path: 'product-bundle',
                    element: <ProductBundleForm />
                },
                {
                    path: 'product-view/:id/channels',
                    element: <ItemChannelsForm />
                }
            ]
        },
        {
            path: 'crm',
            children: [
                {
                    path: 'pricing-rules',
                    element: <PricingRulesList />
                },
                {
                    path: 'pricing-rule/:id',
                    element: <PricingRuleForm />
                },
                {
                    path: 'pricing-rule',
                    element: <PricingRuleForm />
                },
                {
                    path: 'loyatly-programs',
                    element: <LoyaltyProgramList />
                },
                {
                    path: 'loyatly-program',
                    element: <LoyaltyProgramForm />
                },
                {
                    path: 'loyatly-program/:id',
                    element: <LoyaltyProgramForm />
                },
                {
                    path: 'customer',
                    element: <CustomerList />
                },
                {
                    path: 'loyatly-cards',
                    element: <LoyaltyCardList />
                },
                {
                    path: 'loyatly-card/:id',
                    element: <LoyaltyCardForm />
                },
                {
                    path: 'loyatly-card',
                    element: <LoyaltyCardForm />
                }
            ]
        },
        {
            path: 'coupon',
            children: [
                {
                    path: 'code',
                    element: <AddCouponCode />
                },
                {
                    path: 'code-detail/:id',
                    element: <AddCouponCode />
                },
                {
                    path: 'code-list',
                    element: <CouponCodeList />
                }
            ]
        }
    ]
};

export default MainRoutes;
