import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, MenuItem, Select } from '@mui/material';

// project imports
import { drawerWidth } from 'store/constant';
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import { useDispatch, useSelector } from 'react-redux';
import { SetLocal } from 'store/services/api';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const selectedLocal = useSelector((state) => state.theme.local);

    const handleChange = (event) => {
        dispatch(SetLocal(event.target.value));
    };

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: drawerWidth - 43,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        marginTop: 1,
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.secondary.main,
                        color: theme.palette.blue.main,
                        '&:hover': {
                            background: theme.palette.blue.main,
                            color: theme.palette.secondary.main
                        },
                        width: 40,
                        height: 40
                    }}
                    onClick={handleLeftDrawerToggle}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.5} size="1.3rem" />
                </Avatar>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            <Box>
                <Select
                    disableUnderline
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedLocal}
                    onChange={handleChange}
                >
                    <MenuItem value={'en'}>En</MenuItem>
                    <MenuItem value={'fr'}>Fr</MenuItem>
                </Select>
            </Box>

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
