/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { InputLabel, Grid, FormControl, Select, MenuItem, OutlinedInput } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

const AddItem = (props) => {
    const classes = useStyles();
    const {
        label,
        handleChange,
        value,
        name,
        data,
        error,
        propToRender,
        required,
        sx,
        fullwidth,
        handleClickItem,
        handleDeleteItem,
        propToKey
    } = props;
    return (
        <Grid
            sx={sx}
            container
            item
            xs={!fullwidth && 12}
            sm={!fullwidth && 12}
            md={!fullwidth && 8}
            lg={!fullwidth && 8}
            xl={!fullwidth && 8}
            spacing={4}
            display={'flex'}
            alignItems={'center'}
        >
            <Grid item xs={4}>
                <InputLabel error={error ?? error} required={required} className={classes.inputLabel}>
                    {label}
                </InputLabel>
            </Grid>
            <Grid item xs={8}>
                <FormControl variant="outlined" fullWidth>
                    <Select
                        name={name}
                        value={Array.isArray(value) ? value : []}
                        error={error ?? error}
                        labelId="demo-multiple-name-label"
                        id={name}
                        multiple
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" />}
                        renderValue={(selected) => {
                            return (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((object, index) => (
                                        <Chip key={object?.[propToKey]} label={object?.[propToRender]} color="primary" variant="outlined" />
                                    ))}
                                </Box>
                            );
                        }}
                    >
                        {data?.map((row, index) => (
                            <MenuItem key={row?.[propToKey]} value={row}>
                                {row?.[propToRender]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default AddItem;
