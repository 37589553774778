// assets
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    LocalOfferRoundedIcon
};

// ==============================|| USERS MENU ITEMS ||============================== //

const onlineCommercial = {
    id: 'commercial-condition',
    type: 'group',
    role: [ROLE.ADMIN],
    children: [
        {
            id: 'commercial-condition',
            title: 'crm:cndtcomm',
            type: 'item',
            url: '/crm/pricing-rules',
            icon: icons.LocalOfferRoundedIcon
        }
    ]
};

export default onlineCommercial;
