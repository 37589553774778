export const GlobalfilterData = (array, keyword, keys) => {
    const lowercasedValue = keyword?.toLowerCase()?.trim();
    if (lowercasedValue === '') return array;
    else {
        const filteredData = array.filter((item) => {
            return Object?.keys(item).some((key) => {
                if (item[key] !== null) {
                    return keys.includes(key) ? item[key].toString().toLowerCase().match(new RegExp(lowercasedValue, 'g')) : false;
                } else {
                    return false;
                }
            });
        });
        return filteredData;
    }
};
