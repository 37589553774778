import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
// project imports
import NavItem from '../NavItem';

// assets
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {
    const theme = useTheme();
    const classes = useStyles();
    const customization = useSelector((state) => state.customization);
    const { t } = useTranslation(['users', 'routes']);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon
            strokeWidth={1.5}
            size="1.3rem"
            style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                color: theme.palette.secondary.light
            }}
        />
    ) : (
        <></>
    );

    return (
        <>
            <ListItemButton
                sx={{
                    alignItems: 'flex-start'
                }}
                selected={selected === menu.id}
                onClick={handleClick}
            >
                <ListItemIcon
                    sx={
                        selected === menu.id
                            ? { my: 'auto', minWidth: !menu.icon ? 18 : 36, color: theme.palette.secondary.light, opacity: 1 }
                            : { my: 'auto', minWidth: !menu.icon ? 18 : 36, color: theme.palette.secondary.light, opacity: 0.6 }
                    }
                >
                    {menuIcon}
                </ListItemIcon>
                <ListItemText
                    sx={
                        selected === menu.id
                            ? { color: theme.palette.secondary.main }
                            : { color: theme.palette.secondary.light, opacity: 0.6 }
                    }
                    primary={
                        <Typography color="inherit" sx={{ my: 'auto' }}>
                            {t(menu.title)}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
                {open ? (
                    <IconChevronUp
                        stroke={1.5}
                        size="1rem"
                        style={{ marginTop: 'auto', marginBottom: 'auto', color: theme.palette.secondary.light }}
                    />
                ) : (
                    <IconChevronDown
                        stroke={1.5}
                        size="1rem"
                        style={{ marginTop: 'auto', marginBottom: 'auto', color: theme.palette.secondary.light }}
                    />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: 'relative',
                        '&:after': {
                            content: "''",
                            position: 'absolute',
                            left: '32px',
                            top: 0,
                            height: '100%',
                            width: '1px',
                            opacity: 1
                        }
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;
