import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Box } from '@mui/system';
import useFetch from 'hooks/useFetch';
import { CurrencyFormatter, formatter } from 'utils/utils';
import EarningCard from 'views/dashboard/Default/EarningCard';
import { useSelector } from 'react-redux';
import TotalIncomeLightCard from 'views/dashboard/Default/TotalIncomeLightCard';
import TotalIncomeDarkCard from 'views/dashboard/Default/TotalIncomeDarkCard';
import { gridSpacing } from 'store/constant';
import EarningIcon from 'assets/images/icons/earning.svg';
import { useTranslation } from 'react-i18next';

const DashboardPIM = () => {
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const { t } = useTranslation();
    /************************customize form item******************************* */
    const initialMandatoryFieldsState = [
        { id: 'item_code', label: t('products:productCode') },
        { id: 'item_name', label: t('products:productName') },
        { id: 'item_group', label: t('products:productCategory') },
        { id: 'company', label: t('company:company') },
        { id: 'standard_rate', label: t('dashboard:standardRate') },
        { id: 'valuation_rate', label: t('dashboard:valuationRate') },
        { id: 'item_tax_template', label: t('products:taxModel') },
        { id: 'default_price_list', label: t('products:defaultPriceList') },
        { id: 'default_warehouse', label: t('products:defaultWarehouse') }
    ];
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [chartState, setChartState] = useState({
        height: 480,
        type: 'bar',
        options: {
            chart: {
                id: 'bar-chart',
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%'
                }
            },
            xaxis: {
                type: 'category',
                categories: []
            },
            legend: {
                show: true,
                fontSize: '14px',
                fontFamily: `'Quicksand', sans-serif`,
                position: 'bottom',
                offsetX: 20,
                labels: {
                    useSeriesColors: false
                },
                markers: {
                    width: 16,
                    height: 16,
                    radius: 5
                },
                itemMargin: {
                    horizontal: 15,
                    vertical: 8
                }
            },
            fill: {
                type: 'solid'
            },
            dataLabels: {
                enabled: true
            },
            grid: {
                show: true
            }
        },
        series: [
            {
                name: 'Stock Value',
                data: []
            }
        ]
    });
    const [activeItemsPrcntg, setActiveItemsPrcntg] = useState(0);
    const [ttlStockValuePrcntg, setTtlStockValuePrcntg] = useState(0);
    const [sectionBreakLength, setSectionBreakLength] = useState(0);
    const [mandatoryFieldsLength, setMandatoryFieldsLength] = useState(initialMandatoryFieldsState?.length);
    const { data: stockValueCharts, isPending: isStockValueChartsPending } = useFetch(
        `/api/get-warehouse-wise-stock-chart`,
        { chart_name: 'Warehouse wise Stock Value' },
        'POST'
    );

    const activeItemsReqData = {
        doc: {
            name: 'Total Active Items',
            docstatus: 0,
            is_standard: 1,
            module: 'Stock',
            label: 'Total Active Items',
            type: 'Document Type',
            function: 'Count',
            document_type: 'Item',
            is_public: 1,
            show_percentage_stats: 1,
            stats_time_interval: 'Monthly',
            filters_json: '[["Item","disabled","=",0]]',
            dynamic_filters_json: '',
            doctype: 'Number Card'
        },
        filters: [['Item', 'disabled', '=', '0']]
    };
    const { data: activeItems, isPending: isActiveItemsPending } = useFetch(`/api/get-result-number`, activeItemsReqData, 'POST');
    const ttlItemsReqData = {
        doctype: 'Item',
        fields: ['`tabItem`.`name`'],
        distinct: false
    };
    const { data: ttlItems, isPending: isTtlItemsPending } = useFetch(`api/get-list-count`, ttlItemsReqData, 'POST');

    const ttlStockValueReqData = {
        doc: {
            name: 'Total Stock Value',
            docstatus: 0,
            is_standard: 1,
            module: 'Stock',
            label: 'Total Stock Value',
            type: 'Document Type',
            function: 'Sum',
            aggregate_function_based_on: 'stock_value',
            document_type: 'Bin',
            is_public: 1,
            show_percentage_stats: 1,
            stats_time_interval: 'Daily',
            filters_json: '[]',
            doctype: 'Number Card'
        },
        filters: []
    };
    const { data: ttlStockValue, isPending: isTtlStockValuePending } = useFetch(`/api/get-result-number`, ttlStockValueReqData, 'POST');

    const ttlItemGroupReqData = {
        doctype: 'Item Group',
        filters: [['Item Group', 'is_group', '=', 0]],
        fields: ['`tabItem Group`.`name`'],
        distinct: false
    };
    const ttlItemGroupIsGroupReqData = {
        doctype: 'Item Group',
        filters: [['Item Group', 'is_group', '=', 1]],
        fields: ['`tabItem Group`.`name`'],
        distinct: false
    };
    const { data: ttlItemGroups, isPending: isTtlItemGroupsPending } = useFetch(`api/get-list-count`, ttlItemGroupReqData, 'POST');
    const { data: ttlItemGroupIsGroups, isPending: isTtlItemGroupIsGroupsPending } = useFetch(
        `api/get-list-count`,
        ttlItemGroupIsGroupReqData,
        'POST'
    );

    const ttlChannelReqData = {
        doctype: 'Channel',
        filters: [],
        fields: ['`tabChannel`.`name`'],
        distinct: false
    };
    const { data: ttlChannels, isPending: isTtlChannelsPending } = useFetch(`api/get-list-count`, ttlChannelReqData, 'POST');

    const ttlItemAttributeReqData = {
        doctype: 'Item Attribute',
        filters: [],
        fields: ['`tabItem Attribute`.`name`'],
        distinct: false
    };
    const { data: ttlItemAttributes, isPending: isTtlItemAttributesPending } = useFetch(
        `api/get-list-count`,
        ttlItemAttributeReqData,
        'POST'
    );
    const ttlItemCollectionReqData = {
        doctype: 'Item Collection',
        filters: [],
        fields: ['`tabItem Collection`.`name`'],
        distinct: false
    };
    const { data: ttlItemCollections, isPending: isTtlItemCollectionsPending } = useFetch(
        `api/get-list-count`,
        ttlItemCollectionReqData,
        'POST'
    );
    const ttlProductBundleReqData = {
        doctype: 'Product Bundle',
        filters: [],
        fields: ['`tabProduct Bundle`.`name`'],
        distinct: false
    };
    const { data: ttlProductBundles, isPending: isTtlProductBundlesPending } = useFetch(
        `api/get-list-count`,
        ttlProductBundleReqData,
        'POST'
    );
    useEffect(() => {
        if (activeItems && !Array.isArray(activeItems)) {
            fetch(`${process.env.REACT_APP_API_URI}/api/get-percentage`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ ...activeItemsReqData, result: activeItems })
            })
                .then((res) => {
                    if (!res.ok) {
                        throw Error('Something went wrong');
                    }
                    return res.json();
                })
                .then((data) => {
                    setActiveItemsPrcntg(data?.message);
                });
        }
    }, [activeItems]);

    useEffect(() => {
        if (ttlStockValue && !Array.isArray(ttlStockValue)) {
            fetch(`${process.env.REACT_APP_API_URI}/api/get-percentage`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ ...ttlStockValueReqData, result: ttlStockValue })
            })
                .then((res) => {
                    if (!res.ok) {
                        throw Error('Something went wrong');
                    }
                    return res.json();
                })
                .then((data) => {
                    setTtlStockValuePrcntg(data?.message);
                });
        }
    }, [ttlStockValue]);

    useEffect(() => {
        setChartState({
            ...chartState,
            series: [{ name: `Stock Value ${globalDefaults?.default_currency}`, data: stockValueCharts?.datasets?.[0]?.values }],
            options: { ...chartState?.options, xaxis: { type: 'category', categories: stockValueCharts?.labels } }
        });
    }, [stockValueCharts]);

    /************************customize form item******************************* */
    const { data: itemFields, isPending: isTtlitemFieldsPending } = useFetch(
        `/api/run-method`,
        { method: 'fetch_to_customize', docs: { doctype: 'Customize Form', doc_type: 'Item' } },
        'POST'
    );
    useEffect(() => {
        if (itemFields && itemFields?.docs && Array.isArray(itemFields?.docs)) {
            let data = [];
            data = itemFields?.docs?.[0]?.fields?.filter((el) => el?.fieldtype !== 'Tab Break' && el?.fieldtype !== 'Column Break');
            let filteredData = data?.filter((el) => el?.fieldtype === 'Section Break');
            let indexes = [];
            filteredData?.map((el, i) => {
                indexes.push(data.findIndex((e) => e.fieldname === el?.fieldname));
            });

            let fakeState = [{ section: itemFields?.docs?.[0]?.fields[0], arr: data?.slice(0, indexes[0]) }];
            indexes?.map((index, i) => {
                let result = {};
                result = {
                    section: data[index],
                    arr: data?.slice(index + 1, indexes[i + 1])
                };
                fakeState.push(result);
            });
            let sctBrks = [];
            fakeState?.map((el) => {
                sctBrks.push(el?.section);
            });
            setSectionBreakLength(sctBrks.length);
        }
    }, [itemFields]);

    const {
        data: mandatoryFieldsInLara,
        isPending: isTtlmandatoryFieldsInLaraPending,
        refetch
    } = useFetch(`/api/product-fields`, { company: globalDefaults?.default_company }, 'POST');

    useEffect(() => {
        const row = mandatoryFieldsInLara.find((el) => el?.company === globalDefaults?.default_company);
        if (row && row?.product_required_fields) {
            let data = JSON.parse(row?.product_required_fields);
            if (data && data?.length) {
                setMandatoryFieldsLength(data.length);
            }
        }
    }, [mandatoryFieldsInLara]);
    /***********************end customize form item******************************** */
    return (
        <Box>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <EarningCard
                                label={t('common:products')}
                                title={'Disponibles sur un total de ' + ttlItems + ' Produits'}
                                isLoading={isActiveItemsPending && isTtlItemsPending}
                                getResult={activeItems}
                                percentage={activeItemsPrcntg}
                                isFr
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <EarningCard
                                noPercentage
                                label={t('products:productCategory')}
                                isLoading={isTtlItemGroupsPending && isTtlItemGroupIsGroupsPending}
                                getResult={ttlItemGroups}
                                title={ttlItemGroupIsGroups + ' Groupes de catégories'}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <EarningCard
                                title={'Valeur totale du stock'}
                                isLoading={isTtlStockValuePending}
                                getResult={
                                    <CurrencyFormatter
                                        locale="fr-FR"
                                        currency={globalDefaults?.default_currency}
                                        minimumFractionDigits={3}
                                        value={ttlStockValue}
                                    />
                                }
                                percentage={ttlStockValuePrcntg}
                                timeInterval={'Daily'}
                                isFr
                            />
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <TotalIncomeDarkCard
                                icon={EarningIcon}
                                title={'Ensemble produits'}
                                noPercentage
                                isLoading={isTtlProductBundlesPending}
                                getResult={ttlProductBundles}
                            />
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <TotalIncomeLightCard
                                icon={EarningIcon}
                                noPercentage
                                title={t('channels:channels')}
                                isLoading={isTtlChannelsPending}
                                getResult={ttlChannels}
                            />
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <TotalIncomeDarkCard
                                icon={EarningIcon}
                                noPercentage
                                title={t('products:typesofVariants')}
                                isLoading={isTtlItemAttributesPending}
                                getResult={ttlItemAttributes}
                            />
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <TotalIncomeLightCard
                                icon={EarningIcon}
                                noPercentage
                                title={'Collections'}
                                isLoading={isTtlItemCollectionsPending}
                                getResult={ttlItemCollections}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TotalIncomeLightCard
                                icon={EarningIcon}
                                noPercentage
                                title={"Groupes d'attributs"}
                                isLoading={isTtlitemFieldsPending}
                                getResult={sectionBreakLength}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TotalIncomeLightCard
                                icon={EarningIcon}
                                noPercentage
                                title={'Attributs Fiche Produit'}
                                isLoading={isTtlmandatoryFieldsInLaraPending}
                                getResult={mandatoryFieldsLength}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={0.5}></Grid>
                <Grid item xs={11}>
                    <Box sx={{ p: 2.25 }} mt={2} ml={4} borderRadius={2} bgcolor={'Menu'}>
                        {!isStockValueChartsPending && <Chart type="bar" {...chartState} />}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DashboardPIM;
