import React from 'react';
import { Button, Box, ClickAwayListener, Grid, InputLabel, OutlinedInput } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import SimpleTable from 'ui-component/simpleTable/SimpleTable';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import validator from 'validator';
import { GlobalfilterData } from 'utils/formatColumnsHeaders';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';

const CustomerSearchForm = (props) => {
    const classes = useStyles();
    const { setOpenModal, handleCancel, setFormState } = props;
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [searchedCustomer, setSearchedCustomer] = React.useState([]);
    const [state, setState] = React.useState({
        mobile_no: '',
        customer_name: ''
    });

    const columnsName = ['customer_name', 'mobile_no', 'customer_group'];

    const { data: customers, isPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Customer',
            fields: [
                '`tabCustomer`.`name`',
                '`tabCustomer`.`customer_group`',
                '`tabCustomer`.`territory`',
                '`tabCustomer`.`customer_name`',
                '`tabCustomer`.`customer_type`',
                '`tabCustomer`.`mobile_no`'
            ],
            filters: [['Customer', 'disabled', '=', 0]]
        },
        'POST'
    );

    const SearchCustomer = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
        const data = GlobalfilterData(customers, event.target.value.toLowerCase(), ['customer_name', 'mobile_no']);
        data?.length ? setSearchedCustomer(data) : setSearchedCustomer([]);
    };

    useEffect(() => {
        if (selectionModel.length != 0) {
            setFormState({ party_name: selectionModel[0] });
            handleCancel();
        }
    }, [selectionModel]);

    if (isPending) return <Loader />;

    return (
        <ClickAwayListener onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}>
            <div className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {' '}
                        <InputLabel className={classes.label}>Customer Name</InputLabel>
                        <OutlinedInput
                            value={state.customer_name}
                            name="customer_name"
                            autoComplete="off"
                            className={classes.formControll}
                            placeholder="Customer Name"
                            onChange={SearchCustomer}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel className={classes.label} error={state.mobile_no ? !validator.isMobilePhone(state.mobile_no) : ''}>
                            Mobile
                        </InputLabel>
                        <OutlinedInput
                            value={state.mobile_no}
                            name="mobile_no"
                            autoComplete="off"
                            className={classes.formControll}
                            placeholder="Mobile"
                            onChange={SearchCustomer}
                            error={state.mobile_no ? !validator.isMobilePhone(state.mobile_no) : ''}
                        />
                    </Grid>
                </Grid>
                {searchedCustomer.length != 0 && (
                    <SimpleTable
                        selectionModel={selectionModel}
                        setSelectionModel={setSelectionModel}
                        autoHeight={300}
                        data={searchedCustomer}
                        columnsName={columnsName}
                    />
                )}
                <Box mt={1} justifyContent="right" display="flex" className={classes.root}>
                    <Button color="blue" onClick={handleCancel}>
                        Cancel
                    </Button>
                </Box>
            </div>
        </ClickAwayListener>
    );
};

export default CustomerSearchForm;
