import { Box, Divider, Grid, Typography, Chip, FormControlLabel, Checkbox, IconButton, Button } from '@mui/material';
import useGetAxios from 'hooks/useGetAxios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainCard from 'ui-component/cards/MainCard';
import InfoIcon from '@mui/icons-material/Info';
import Loader from 'ui-component/Loader';
import { LoadingButton } from '@mui/lab';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { frLocal } from 'utils/dataGridFRLocal';
import useFetch from 'hooks/useFetch';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import moment from 'moment';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import ApprovalIcon from '@mui/icons-material/Approval';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}
const PricingRuleForm = () => {
    const classes = useStyles();
    const { id } = useParams();
    const { t } = useTranslation();

    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const [warehouses, setWarehouses] = React.useState([]);
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const [isEnabled, setIsEnabled] = useState(false);
    const [isRecursive, setIsRecursive] = useState(false);
    const [isCouponCode, setIsCouponCode] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [isExistCouponCode, setIsExistCouponCode] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeValue = (event, newValue) => {
        setValue(newValue);
    };

    const [selectionItemsModel, setSelectionItemsModel] = useState([]);
    const [selectionItemsAppForModel, setSelectionItemsAppForModel] = useState([]);

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Pricing%20Rule&name=${id}`);

    const { data: companies, isPending: isCompaniesPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Company', fields: ['`tabCompany`.`name`'] },
        'POST'
    );

    const { data: currencies, isPending: isCurrenciesPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Currency', fields: ['`tabCurrency`.`name`'], filters: [['Currency', 'enabled', '=', 1]] },
        'POST'
    );
    //fetch items
    const {
        data: itemsList,
        isPending: isItems,
        refetch
    } = useFetch(`/api/get-list`, { doctype: 'Item', fields: ['`tabItem`.`item_code`', '`tabItem`.`name`'] }, 'POST');

    const [formState, setFormState] = useState({
        title: '',
        apply_on: '',
        price_or_product_discount: '',
        applicable_for: '',
        min_amt: '',
        max_amt: '',
        min_qty: '',
        max_qty: '',
        company: globalDefaults?.default_company,
        currency: globalDefaults?.default_currency,
        warehouse: '',
        valid_from: moment().format('YYYY-MM-DD'),
        valid_upto: '',
        margin_type: '',
        margin_rate_or_amount: 0,
        rate_or_discount: '',
        priority: '',
        discount_type: '',
        free_qty: '',
        free_item: '',
        free_item_rate: '',
        customer: '',
        customer_group: '',
        territory: '',
        discount_percentage: '',
        discount_amount: '',
        rate: ''
    });
    const [formErrState, setFormErrState] = useState({});

    const columns = [{ field: 'name', headerName: 'Nom', flex: 1 }];
    const itemCodeColumns = [{ field: 'item_code', headerName: 'Code De Produit', flex: 1 }];

    const handleChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });

        // err handlers
        if (e.target.value == '') {
            setFormErrState({ ...formErrState, [e.target.name]: true });
        } else {
            setFormErrState({ ...formErrState, [e.target.name]: false });
        }
    };

    const [applyOnItems, setApplyOnItems] = useState([]);
    const [isItemsPending, setIsItemsPending] = useState(false);
    const [appForItems, setAppForItems] = useState([]);
    const [isappForItemsPending, setIsappForItemsPending] = useState(false);

    const handleChangeApplyOn = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
        setIsItemsPending(true);
        if (e.target.value === 'Item Code') {
            fetchItems();
        } else if (e.target.value === 'Item Group') {
            fetchItemGroups();
        } else {
            fetchBrands();
        }
    };

    const handleChangeAppFor = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
        setIsappForItemsPending(true);

        if (e.target.value === 'Customer') {
            fetchCustomers();
        } else if (e.target.value === 'Customer Group') {
            fetchCustomerGroups();
        } else {
            fetchTerritories();
        }
    };

    /* FETCHING WAREHOUSES */
    const fetchWarehouses = () => {
        let warehouseReqData = {
            doctype: 'Warehouse',
            fields: [
                '`tabWarehouse`.`name`',
                '`tabWarehouse`.`company`',
                '`tabWarehouse`.`disabled`',
                '`tabWarehouse`.`city`',
                '`tabWarehouse`.`warehouse_name`'
            ],
            filters: [['Warehouse', 'company', '=', formState?.company]]
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(warehouseReqData)
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setWarehouses(data);
            });
    };
    useEffect(() => {
        fetchWarehouses();
    }, [formState?.company]);
    /* FETCHING */
    const fetchItemGroups = async () => {
        setIsItemsPending(true);
        let data = {
            doctype: 'Item Group',
            fields: ['`tabItem Group`.`name`'],
            // filters: [['Item Group', 'company', '=', 'Petit Bateau']],
            start: 0,
            page_length: 50
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsItemsPending(false);
                return res.json();
            })
            .then((data) => {
                setApplyOnItems(data);
            });
    };

    const fetchItems = () => {
        setIsItemsPending(true);
        let data = {
            doctype: 'Item',
            fields: ['`tabItem`.`item_code`'],
            filters: [['Item', 'not_discountable', '=', 0]],
            start: 0,
            page_length: 500
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsItemsPending(false);
                return res.json();
            })
            .then((data) => {
                setApplyOnItems(data);
            });
    };

    const fetchBrands = () => {
        setIsItemsPending(true);
        let data = {
            doctype: 'Brand',
            fields: ['`tabBrand`.`name`'],
            // filters: [['Brand', 'company', '=', 'Petit Bateau']],
            start: 0,
            page_length: 50
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsItemsPending(false);
                return res.json();
            })
            .then((data) => {
                setApplyOnItems(data);
            });
    };

    /* FETCHING AGAIN */
    const fetchCustomers = () => {
        setIsappForItemsPending(true);
        let data = {
            doctype: 'Customer',
            fields: ['`tabCustomer`.`name`'],
            // filters: [['Item', 'company', '=', 'Petit Bateau']],
            start: 0,
            page_length: 500
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsappForItemsPending(false);
                return res.json();
            })
            .then((data) => {
                setAppForItems(data);
            });
    };

    const fetchCustomerGroups = () => {
        setIsappForItemsPending(true);
        let data = {
            doctype: 'Customer Group',
            fields: ['`tabCustomer Group`.`name`'],
            // filters: [['Item Group', 'company', '=', 'Petit Bateau']],
            start: 0,
            page_length: 50
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsappForItemsPending(false);
                return res.json();
            })
            .then((data) => {
                setAppForItems(data);
            });
    };

    const fetchTerritories = () => {
        setIsappForItemsPending(true);
        let data = {
            doctype: 'Territory',
            fields: ['`tabTerritory`.`name`'],
            filters: [['Territory', 'is_group', '=', 0]],
            start: 0,
            page_length: 50
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsappForItemsPending(false);
                return res.json();
            })
            .then((data) => {
                setAppForItems(data);
            });
    };

    /* FETCHING dd */
    const fetchCouponCode = () => {
        setIsLoading(true);
        let warehouseReqData = {
            doctype: 'Coupon Code',
            fields: ['`tabCoupon Code`.`name`'],
            filters: [['Coupon Code', 'pricing_rule', '=', doc?.name]]
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(warehouseReqData)
        })
            .then((res) => {
                setIsLoading(false);
                return res.json();
            })
            .then((data) => {
                if (data?.message || data?.message == 0) {
                    setIsExistCouponCode(data?.message);
                } else {
                    setIsExistCouponCode(data);
                }
            });
    };

    useEffect(() => {
        if (id && doc && doc?.name) {
            fetchCouponCode();
        }
    }, [doc]);

    const dataSelectionApplayOn = (
        <Box height={417} width={'100%'} m={2}>
            <DataGrid
                rows={applyOnItems}
                columns={formState.apply_on === 'Item Code' ? itemCodeColumns : columns}
                checkboxSelection
                getRowId={(row) => (formState?.apply_on === 'Item Code' ? row.item_code : row.name)}
                localeText={frLocal}
                hideFooterPagination
                hideFooterSelectedRowCount
                rowSelectionModel={selectionItemsModel}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionItemsModel(newSelectionModel);
                }}
                loading={isItemsPending}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        showQuickExport: false,
                        quickFilterProps: { debounceMs: 500 }
                    }
                }}
                disableColumnSelector
                disableDensitySelector
                disableColumnFilter
                disableColumnMenu
            />
        </Box>
    );

    const productDiscountSchema = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <FormControlLabel
                    label={t('crm:isrecursive')}
                    control={<Checkbox color="blue" checked={isRecursive} onChange={() => setIsRecursive(!isRecursive)} />}
                />
                <Typography id="modal-modal-description" sx={{ fontSize: 11, mt: 2 }}>
                    {t('crm:helper-three')}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <FormControlLabel
                    label={t('crm:sameitem')}
                    control={<Checkbox color="blue" checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)} />}
                />
                <Typography id="modal-modal-description" sx={{ fontSize: 11, mt: 2 }}>
                    {t('crm:helper-four')}
                </Typography>
            </Box>
            {!isEnabled && (
                <SelectForm
                    label={t('crm:freeitem')}
                    data={itemsList ?? itemsList}
                    name="free_item"
                    propToRender={'name'}
                    value={formState.free_item}
                    handleChange={handleChange}
                />
            )}
            <InputTextField
                type="number"
                label={t('crm:freeqty')}
                value={formState.free_qty}
                handleChange={handleChange}
                name="free_qty"
                helper={t('crm:helper-five')}
            />
            <InputTextField
                type="number"
                label={t('crm:freeitemrate')}
                value={formState.free_item_rate}
                handleChange={handleChange}
                name="free_item_rate"
                helper={t('crm:helper-six')}
            />
        </>
    );

    const handleSavePricingRule = () => {
        setLoadingButton(true);
        let data = {
            ...(!id && { __islocal: 1 }),
            ...(id && {
                creation: doc.creation,
                modified: doc.modified,
                owner: doc.owner,
                modified_by: doc.owner,
                name: doc.name
            }),
            docstatus: 0,
            doctype: 'Pricing Rule',
            __unsaved: 1,
            naming_series: 'PRLE-.####',
            disable: 0,
            apply_on: formState.apply_on,
            price_or_product_discount: formState.price_or_product_discount,
            mixed_conditions: 0,
            is_cumulative: 0,
            // apply_rule_on_other: 'Item+Code',
            selling: 1,
            buying: 0,
            applicable_for: formState.applicable_for,
            min_amt: formState.min_amt ? parseInt(formState.min_amt) : 0,
            max_amt: formState.max_amt ? parseInt(formState.max_amt) : 0,
            valid_from: formState.valid_from,
            company: formState.company,
            currency: formState.currency,
            margin_type: formState.margin_type,
            margin_rate_or_amount: formState.margin_rate_or_amount ? parseInt(formState.margin_rate_or_amount) : 0,
            rate_or_discount: formState.rate_or_discount,
            apply_discount_on: 'Grand Total',
            rate: formState?.rate ? parseInt(formState?.rate) : 0,
            discount_amount: formState?.discount_amount ? parseInt(formState?.discount_amount) : 0,
            priority: formState.priority,
            apply_multiple_pricing_rules: 0,
            apply_discount_on_rate: 0,
            validate_applied_rule: 0,
            title: formState.title,
            // other_item_code: '001',
            min_qty: formState.min_qty ? parseInt(formState.min_qty) : 0,
            max_qty: formState.max_qty ? parseInt(formState.max_qty) : 0,
            valid_upto: formState.valid_upto ? formState.valid_upto : formState.valid_from,
            discount_percentage: formState?.discount_percentage ? parseInt(formState?.discount_percentage) : 0,
            threshold_percentage: null,
            same_item: isEnabled ? 1 : 0,
            free_qty: formState.free_qty,
            free_item: formState.free_item,
            free_item_rate: formState.free_item_rate,
            is_recursive: isRecursive ? 1 : 0,
            customer: formState.customer,
            customer_group: formState.customer_group,
            territory: formState.territory,
            coupon_code_based: isCouponCode ? 1 : 0
        };

        let applyOnData = [];
        if (formState.apply_on === 'Item Code') {
            selectionItemsModel.map((el, i) => {
                let dataToInsert = {
                    docstatus: 0,
                    doctype: 'Pricing Rule Item Code',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'items',
                    parenttype: 'Pricing Rule',
                    idx: i,
                    __unedited: false,
                    item_code: el
                };
                applyOnData.push(dataToInsert);
            });
        } else if (formState.apply_on === 'Item Group') {
            selectionItemsModel.map((el, i) => {
                let dataToInsert = {
                    docstatus: 0,
                    doctype: 'Pricing Rule Item Group',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'item_groups',
                    parenttype: 'Pricing Rule',
                    idx: i,
                    __unedited: false,
                    item_group: el
                };
                applyOnData.push(dataToInsert);
            });
        } else if (formState.apply_on === 'Brand') {
            selectionItemsModel.map((el, i) => {
                let dataToInsert = {
                    docstatus: 0,
                    doctype: 'Pricing Rule Brand',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'brands',
                    parenttype: 'Pricing Rule',
                    idx: i,
                    __unedited: false,
                    brand: el
                };
                applyOnData.push(dataToInsert);
            });
        }

        data = {
            ...data,
            [formState.apply_on === 'Brand' ? 'brands' : formState.apply_on === 'Item Group' ? 'item_groups' : 'items']: applyOnData
        };

        if (formState.warehouse) {
            data = { ...data, warehouse: formState.warehouse };
        }

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Condition commerciale sauvegardé avec succès' }));
                    navigate('/crm/pricing-rules');
                }
            });
    };

    useEffect(() => {
        if (id && doc?.name) {
            setFormState({
                ...formState,
                apply_on: doc?.apply_on,
                price_or_product_discount: doc?.price_or_product_discount,
                applicable_for: doc?.applicable_for,
                min_amt: doc?.min_amt,
                max_amt: doc?.max_amt,
                valid_from: doc?.valid_from,
                company: doc?.company,
                currency: doc?.currency,
                margin_type: doc?.margin_type,
                margin_rate_or_amount: doc?.margin_rate_or_amount,
                rate_or_discount: doc?.rate_or_discount,
                apply_discount_on: 'Grand Total',
                rate: doc?.rate,
                discount_amount: doc?.discount_amount,
                priority: doc?.priority,
                title: doc?.title,
                min_qty: doc?.min_qty,
                max_qty: doc?.max_qty,
                valid_upto: doc?.valid_upto,
                for_price_list: doc?.for_price_list,
                discount_percentage: doc?.discount_percentage,
                threshold_percentage: doc?.threshold_percentage,
                warehouse: doc?.warehouse,
                free_qty: doc.free_qty,
                free_item: doc.free_item,
                free_item_rate: doc.free_item_rate,
                customer: doc?.customer,
                customer_group: doc?.customer_group,
                territory: doc?.territory
            });
            setIsCouponCode(doc.coupon_code_based == 1 ? true : false);
            setIsEnabled(doc.same_item == 1 ? true : false);
            setIsRecursive(doc.is_recursive == 1 ? true : false);
            if (doc?.apply_on === 'Item Group') {
                fetchItemGroups();
                setTimeout(() => {
                    let forSelection = [];
                    doc?.item_groups?.map((el) => {
                        forSelection.push(el.item_group);
                    });
                    setSelectionItemsModel(forSelection);
                }, 1000);
            } else if (doc?.apply_on === 'Item Code') {
                fetchItems();
                setTimeout(() => {
                    let forSelection = [];
                    doc?.items?.map((el) => {
                        forSelection.push(el.item_code);
                    });
                    setSelectionItemsModel(forSelection);
                }, 1000);
            } else if (doc?.apply_on === 'Brand') {
                fetchBrands();
                setTimeout(() => {
                    let forSelection = [];
                    doc?.brands?.map((el) => {
                        forSelection.push(el.brand);
                    });
                    setSelectionItemsModel(forSelection);
                }, 1000);
            }
            if (doc?.applicable_for === 'Customer') {
                fetchCustomers();
                setTimeout(() => {
                    setSelectionItemsAppForModel([doc?.customer]);
                }, 1000);
            } else if (doc?.applicable_for === 'Customer Group') {
                fetchCustomerGroups();
                setTimeout(() => {
                    setSelectionItemsAppForModel([doc?.customer_group]);
                }, 1000);
            } else if (doc?.applicable_for === 'Territory') {
                fetchTerritories();
                setTimeout(() => {
                    setSelectionItemsAppForModel([doc?.territory]);
                }, 1000);
            }
        }
    }, [doc]);

    useEffect(() => {
        if (formState.discount_type === 'Free Item') {
            fetchItems();
            setFormState({
                ...formState,
                apply_on: 'Item Code',
                price_or_product_discount: 'Product'
            });
        } else if (formState.discount_type === 'Discount On price') {
            setFormState({
                ...formState,
                price_or_product_discount: 'Price',
                rate_or_discount: 'Discount Amount'
            });
        } else if (formState.discount_type === 'Discount On percentage') {
            setFormState({
                ...formState,
                price_or_product_discount: 'Price',
                rate_or_discount: 'Discount Percentage'
            });
        } else if (formState.discount_type === 'Fixed price') {
            setFormState({
                ...formState,
                price_or_product_discount: 'Price',
                rate_or_discount: ''
            });
        } else {
            setFormState({
                ...formState,
                apply_on: '',
                price_or_product_discount: '',
                rate_or_discount: ''
            });
        }
    }, [formState?.discount_type]);

    useEffect(() => {
        isCouponCode
            ? setFormState({
                  ...formState,
                  price_or_product_discount: 'Price'
              })
            : setFormState({
                  ...formState,
                  price_or_product_discount: ''
              });
    }, [isCouponCode]);

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Box>
                <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                {id ? `${t('crm:cndtcom')}: ${doc?.title}` : t('crm:newcndtcom')}
            </Box>
            {id && doc && doc.coupon_code_based ? (
                <Box>
                    {isExistCouponCode && isExistCouponCode?.length != 0 && (
                        <Button
                            startIcon={<FormatListBulletedIcon color="warning" />}
                            variant="outlined"
                            color="warning"
                            sx={{ ml: 1 }}
                            onClick={() => {
                                navigate('/coupon/code-list', { state: { pricingRulesId: id } });
                            }}
                        >
                            {t('crm:viewcouponcode')}
                        </Button>
                    )}
                    <Button
                        startIcon={<AddCircleIcon />}
                        variant="outlined"
                        color="blue"
                        sx={{ ml: 1 }}
                        onClick={() =>
                            navigate('/coupon/code', {
                                state: { pricingRulesId: id, valid_from: doc?.valid_from, valid_upto: doc?.valid_upto }
                            })
                        }
                    >
                        {t('crm:addpromo')}
                    </Button>
                </Box>
            ) : (
                ''
            )}
        </Box>
    );

    const handleBackClick = () => {
        setValue(value - 1);
    };

    const handleNextClick = () => {
        setValue(value + 1);
    };
    if (id && isPending && doc) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                {/* header */}
                <Box display={'flex'} mt={-4} mb={4}>
                    <InfoIcon sx={{ mt: 0.6 }} />
                    <Typography p={1} variant="h5" color="primary">
                        {t('crm:crmdesc')}
                    </Typography>
                </Box>
                {/*tabs*/}
                <Box
                    className={classes.centeredTabs}
                    sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}
                >
                    <Tabs value={value} onChange={handleChangeValue} aria-label="basic tabs example">
                        <Tab label={t('crm:details')} {...a11yProps(0)} />
                        <Tab label={t('crm:trigger')} {...a11yProps(1)} />
                        <Tab label={t('crm:benefices')} {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <FormControlLabel
                                label={t('crm:applypromo')}
                                control={
                                    <Checkbox
                                        color="blue"
                                        checked={isCouponCode}
                                        onChange={() => setIsCouponCode(!isCouponCode)}
                                        disabled={id}
                                    />
                                }
                            />
                            <Typography id="modal-modal-description" sx={{ fontSize: 11, mt: 2, fontWeight: 'bold' }}>
                                {t('crm:info-one')}
                            </Typography>
                        </Box>
                        <InputTextField
                            handleChange={handleChange}
                            value={formState.title}
                            error={formErrState?.title}
                            label={t('crm:title')}
                            name="title"
                            required
                        />
                        <SelectForm
                            label={t('crm:company')}
                            value={formState.company}
                            handleChange={handleChange}
                            data={companies}
                            name="company"
                            propToRender="name"
                        />
                        <SelectForm
                            label={t('currencies:currency')}
                            error={formErrState?.currency}
                            value={formState.currency}
                            handleChange={handleChange}
                            data={currencies}
                            name="currency"
                            propToRender="name"
                            required
                        />
                        <Box mt={2}>
                            <Divider>
                                <Chip label={t('crm:timeparams')} />
                            </Divider>
                        </Box>
                        <FormDateTime
                            type="date"
                            label={t('crm:validfrom')}
                            name="valid_from"
                            value={formState.valid_from}
                            handleChange={handleChange}
                        />
                        <FormDateTime
                            type="date"
                            label={t('crm:validtill')}
                            name="valid_upto"
                            value={formState.valid_upto}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Box display={'flex'} justifyContent={'right'} mt={3}>
                        {value === 0 ? (
                            <IconButton
                                aria-label="delete"
                                sx={{ fontSize: '3rem', backgroundColor: '#ffffff', borderRadius: '50%' }}
                                onClick={handleNextClick}
                            >
                                <KeyboardDoubleArrowRightIcon size="2rem" />
                            </IconButton>
                        ) : null}
                    </Box>
                    <MainCard
                        sx={{ mt: 2 }}
                        isWhite
                        divider
                        title={
                            <Box display={'flex'} alignItems={'center'}>
                                <InfoIcon sx={{ mr: 1 }} /> Notes
                            </Box>
                        }
                    >
                        <ul>
                            <li>{t('crm:note-one')}</li>
                            <li>{t('crm:note-two')}</li>
                            <li>{t('crm:note-three')}</li>
                            <li>{t('crm:note-four')}</li>
                            <li>{t('crm:note-five')}</li>
                            <li>{t('crm:note-six')}</li>
                        </ul>
                    </MainCard>
                    <MainCard
                        sx={{ mt: 2 }}
                        isWhite
                        divider
                        title={
                            <Box display={'flex'} alignItems={'center'}>
                                <InfoIcon sx={{ mr: 1 }} />
                                {t('crm:howtoapply')}
                            </Box>
                        }
                    >
                        <ol>
                            <li>{t('crm:howto-one')}</li>
                            <li>{t('crm:howto-two')}</li>
                            <li>{t('crm:howto-three')}</li>
                            <li>{t('crm:howto-four')}</li>
                            <li>
                                {t('crm:howto-five')}
                                <ul>
                                    <li>Item Code &gt; Item Group &gt; Brand</li>
                                    <li>Customer &gt; Customer Group &gt; Territory</li>
                                </ul>
                            </li>
                            <li>{t('crm:howto-six')}</li>
                        </ol>
                    </MainCard>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid container spacing={2} columns={2} display={'flex'} justifyContent={'center'}>
                        <Grid item xs={1}>
                            <Box m={2}>
                                <Divider>
                                    <Chip label={t('crm:bywarehouse')} />
                                </Divider>
                            </Box>
                            <SelectForm
                                sx={{ ml: -8 }}
                                fullwidth
                                label={t('crm:warehouse')}
                                error={formErrState?.warehouse}
                                value={formState.warehouse}
                                handleChange={handleChange}
                                data={warehouses}
                                name="warehouse"
                                propToRender="name"
                            />
                            <Box m={2}>
                                <Divider>
                                    <Chip label={t('crm:bycustomer')} />
                                </Divider>
                            </Box>
                            <SelectForm
                                sx={{ ml: -8 }}
                                fullwidth
                                label={t('crm:appfor')}
                                error={formErrState?.applicable_for}
                                value={formState.applicable_for}
                                handleChange={handleChangeAppFor}
                                data={['', 'Customer', 'Customer Group', 'Territory']}
                                name="applicable_for"
                            />

                            {formState.applicable_for === 'Customer Group' && (
                                <SelectForm
                                    fullwidth
                                    sx={{ mt: -2, ml: -8 }}
                                    label={t('crm:customergroup')}
                                    value={formState.customer_group}
                                    handleChange={handleChange}
                                    data={appForItems}
                                    name="customer_group"
                                    propToRender={'name'}
                                />
                            )}
                            {formState.applicable_for === 'Territory' && (
                                <SelectForm
                                    fullwidth
                                    sx={{ mt: -2, ml: -8 }}
                                    label={t('crm:territory')}
                                    value={formState.territory}
                                    handleChange={handleChange}
                                    data={appForItems}
                                    name="territory"
                                    propToRender={'name'}
                                />
                            )}
                            {formState.applicable_for === 'Customer' && (
                                <SelectForm
                                    fullwidth
                                    sx={{ mt: -2, ml: -8 }}
                                    label={t('crm:customer')}
                                    value={formState.customer}
                                    handleChange={handleChange}
                                    data={appForItems}
                                    name="customer"
                                    propToRender={'name'}
                                />
                            )}

                            {!isCouponCode && (
                                <>
                                    <Box m={2}>
                                        <Divider>
                                            <Chip label={t('crm:byqty')} />
                                        </Divider>
                                    </Box>
                                    <InputTextField
                                        fullwidth
                                        sx={{ ml: -8 }}
                                        type="number"
                                        handleChange={handleChange}
                                        value={formState.min_qty}
                                        label={t('crm:qtymin')}
                                        name="min_qty"
                                    />
                                    <InputTextField
                                        fullwidth
                                        sx={{ mt: -2, ml: -8 }}
                                        type="number"
                                        handleChange={handleChange}
                                        value={formState.max_qty}
                                        label={t('crm:qtymax')}
                                        name="max_qty"
                                    />

                                    <Box m={2}>
                                        <Divider>
                                            <Chip label={t('crm:byamount')} />
                                        </Divider>
                                    </Box>
                                    <InputTextField
                                        fullwidth
                                        sx={{ ml: -8 }}
                                        isCurrency
                                        type="number"
                                        handleChange={handleChange}
                                        value={formState.min_amt}
                                        label={t('crm:amountmin')}
                                        name="min_amt"
                                    />
                                    <InputTextField
                                        fullwidth
                                        sx={{ mt: -2, ml: -8 }}
                                        isCurrency
                                        type="number"
                                        handleChange={handleChange}
                                        value={formState.max_amt}
                                        label={t('crm:amountmax')}
                                        name="max_amt"
                                    />

                                    <Box m={2}>
                                        <Divider>
                                            <Chip label={t('crm:bymargin')} />
                                        </Divider>
                                    </Box>
                                    <SelectForm
                                        fullwidth
                                        sx={{ ml: -8 }}
                                        label={t('crm:margintype')}
                                        value={formState.margin_type}
                                        handleChange={handleChange}
                                        data={['', 'Percentage', 'Amount']}
                                        name="margin_type"
                                    />
                                    {formState.margin_type && (
                                        <InputTextField
                                            fullwidth
                                            sx={{ mt: -2, ml: -8 }}
                                            isCurrency={formState.margin_type === 'Amount'}
                                            isPercentage={formState.margin_type === 'Percentage'}
                                            type="number"
                                            handleChange={handleChange}
                                            value={formState.margin_rate_or_amount}
                                            label={t('crm:rateoramt')}
                                            name="margin_rate_or_amount"
                                        />
                                    )}
                                </>
                            )}
                        </Grid>
                        <Grid item xs={1}>
                            <MainCard
                                title={
                                    <Box display={'flex'}>
                                        <ApprovalIcon sx={{ mr: 1.5, ml: -1.5 }} />
                                        {t('crm:appon')} {formState.apply_on}
                                    </Box>
                                }
                                divider
                                border
                            >
                                <SelectForm
                                    sx={{ ml: -5 }}
                                    fullwidth
                                    label={t('crm:appon')}
                                    error={formErrState?.apply_on}
                                    value={formState.apply_on}
                                    handleChange={handleChangeApplyOn}
                                    data={['', 'Item Code', 'Item Group', 'Brand']}
                                    name="apply_on"
                                    required
                                />
                                {formState.apply_on != '' && dataSelectionApplayOn}
                            </MainCard>
                        </Grid>
                    </Grid>
                    <Box display={'flex'} justifyContent={'space-between'} mt={3}>
                        {value === 1 ? (
                            <>
                                <IconButton
                                    aria-label="delete"
                                    sx={{ fontSize: '3rem', backgroundColor: '#ffffff', borderRadius: '50%' }}
                                    onClick={handleBackClick}
                                >
                                    <KeyboardDoubleArrowLeftIcon size="2rem" />
                                </IconButton>
                                <IconButton
                                    aria-label="delete"
                                    sx={{ fontSize: '3rem', backgroundColor: '#ffffff', borderRadius: '50%' }}
                                    onClick={handleNextClick}
                                >
                                    <KeyboardDoubleArrowRightIcon size="2rem" />
                                </IconButton>
                            </>
                        ) : null}
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                        {!id && (
                            <SelectForm
                                label={t('crm:disctype')}
                                value={formState.discount_type}
                                handleChange={handleChange}
                                data={['', 'Free Item', 'Fixed price', 'Discount On price', 'Discount On percentage']}
                                name="discount_type"
                            />
                        )}
                        <SelectForm
                            label={t('crm:priority')}
                            value={formState.priority}
                            handleChange={handleChange}
                            data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]}
                            name="priority"
                            helper={t('crm:helper-one')}
                        />
                        <SelectForm
                            label={t('crm:priceorproductdisc')}
                            error={formErrState?.price_or_product_discount}
                            value={formState.price_or_product_discount}
                            handleChange={handleChange}
                            data={['', 'Price', 'Product']}
                            name="price_or_product_discount"
                            required
                            disabled={isCouponCode}
                        />
                        {formState.price_or_product_discount == 'Product' && (
                            <Box mt={2}>
                                <Divider>
                                    <Chip label={t('crm:productdisc')} />
                                </Divider>
                            </Box>
                        )}
                        {formState.price_or_product_discount == 'Product' && productDiscountSchema}
                        {formState.price_or_product_discount == 'Price' && (
                            <Box mt={2}>
                                <Divider>
                                    <Chip label={t('crm:pricedisc')} />
                                </Divider>
                            </Box>
                        )}
                        {formState.price_or_product_discount === 'Price' && (
                            <>
                                <SelectForm
                                    label={t('crm:rateordisc')}
                                    value={formState.rate_or_discount}
                                    handleChange={handleChange}
                                    data={['', 'Rate', 'Discount Percentage', 'Discount Amount']}
                                    name="rate_or_discount"
                                />
                                {formState.rate_or_discount && (
                                    <InputTextField
                                        isCurrency={formState.rate_or_discount === 'Discount Amount'}
                                        isPercentage={formState.rate_or_discount === 'Discount Percentage'}
                                        type="number"
                                        handleChange={handleChange}
                                        value={
                                            formState.rate_or_discount === 'Discount Percentage'
                                                ? formState.discount_percentage
                                                : formState.rate_or_discount === 'Discount Amount'
                                                ? formState.discount_amount
                                                : formState.rate
                                        }
                                        label={
                                            formState.rate_or_discount === 'Discount Percentage'
                                                ? t('crm:discountperc')
                                                : formState.rate_or_discount === 'Discount Amount'
                                                ? t('crm:discountamount')
                                                : t('crm:Taux')
                                        }
                                        name={
                                            formState.rate_or_discount === 'Discount Percentage'
                                                ? 'discount_percentage'
                                                : formState.rate_or_discount === 'Discount Amount'
                                                ? 'discount_amount'
                                                : 'rate'
                                        }
                                    />
                                )}
                            </>
                        )}
                        {formState.discount_type === 'Free Item' && (
                            <>
                                <InputTextField
                                    type="number"
                                    handleChange={handleChange}
                                    value={formState.min_qty}
                                    label={t('crm:qtymin')}
                                    name="min_qty"
                                    helper={t('crm:helper-two')}
                                />
                                <InputTextField
                                    sx={{ mt: -2 }}
                                    type="number"
                                    handleChange={handleChange}
                                    value={formState.max_qty}
                                    label={t('crm:qtymax')}
                                    name="max_qty"
                                />
                            </>
                        )}
                    </Grid>
                    <Box display={'flex'} justifyContent={'space-between'} mt={3}>
                        {value === 2 ? (
                            <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={handleBackClick}
                                sx={{ fontSize: '3rem', backgroundColor: '#ffffff', borderRadius: '50%' }}
                            >
                                <KeyboardDoubleArrowLeftIcon size="2rem" />
                            </IconButton>
                        ) : null}
                        <LoadingButton
                            loading={loadingButton}
                            variant="contained"
                            size="large"
                            color="blue"
                            onClick={handleSavePricingRule}
                        >
                            {t('crm:save')}
                        </LoadingButton>
                    </Box>
                </TabPanel>
            </MainCard>
        </Box>
    );
};

export default PricingRuleForm;
