export default function componentStyleOverrides(theme) {
    const bgColor = theme.colors?.grey50;
    return {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: theme?.colors?.blue
                },
                colorError: {
                    color: theme.colors.errorMain
                },
                colorWarning: {
                    color: theme.colors.warningDark
                },
                colorDisabled: {
                    color: theme.colors.grey500,
                    opacity: 0.6
                },
                colorPrimary: {
                    color: theme.colors.primaryMain
                },
                colorMenu: {
                    color: theme.paper
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: { fontWeight: 600 },
                icon: {
                    color: theme?.colors?.blue
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-asterisk': {
                        color: theme.colors.errorMain
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '0.8rem',
                    borderRadius: '0.625rem',
                    minWidth: 'auto',
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    '&:hover': {
                        opacity: 0.9
                    },
                    '&.MuiButton-containedBlue': {
                        color: theme.paper
                    },
                    '&.MuiButton-sizeLarge': {
                        fontSize: '0.9rem'
                    }
                },
                contained: {
                    // boxShadow: '0rem 0.2rem 0.475rem #0621524E'
                },
                outlined: {
                    backgroundColor: theme.paper
                }
            }
        },
        MuiTreeView: {
            styleOverrides: {
                root: {
                    '& .MuiTreeItem-content': {
                        borderRadius: 10,
                        color: theme?.colors?.blue
                    }
                }
            }
        },
        MuiBadge: {
            styleOverrides: {
                root: {
                    '& .MuiBadge-colorBlue': {
                        color: theme.paper
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: theme?.colors?.blue,
                    display: 'flex',
                    justifyContent: 'center',
                    '& .MuiTabs-indicatorSpan': {
                        maxWidth: 20,
                        width: '100%'
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                    backgroundColor: '#FFF',
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    marginLeft: 2,
                    marginRight: 2,
                    color: theme?.colors?.grey500,
                    opacity: 0.5,
                    '&.Mui-selected': {
                        color: theme?.colors?.blue,
                        opacity: 1
                    }
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'separate',
                    borderSpacing: '0 10px',
                    '& .MuiTableCell-root': {
                        background: '#fff',
                        fontSize: '0.8rem',
                        padding: '8px 12px',
                        color: theme.colors.primaryMain
                    },
                    '& .MuiTableCell-head': {
                        background: 'none',
                        paddingBottom: 0,
                        color: '#657288',
                        fontSize: 12,
                        borderBottom: 'none',
                        paddingLeft: 13
                    },
                    '& .MuiTableRow-root': {
                        minHeight: 45,
                        borderRadius: 10,
                        '& .MuiTableCell-root:first-of-type': {
                            borderTopLeftRadius: 10,
                            borderBottomLeftRadius: 10
                        },
                        '& .MuiTableCell-root:last-child': {
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10
                        }
                    }
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {}
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.paper,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.paper
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingBottom: 3,
                    paddingTop: 3,
                    marginBottom: 13,
                    borderRadius: '0px 7px 7px 0px',
                    borderLeft: '5px solid transparent',
                    '&.Mui-selected': {
                        borderLeftColor: theme?.colors?.blue,
                        backgroundColor: '#3C4162',
                        opacity: 1
                    }
                },
                gutters: {
                    paddingLeft: 11
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.colors.primaryMain,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    },
                    '&.Mui-disabled': {
                        color: theme.colors?.primaryDark,
                        '-webkit-text-fill-color': 'unset !important'
                    }
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:before': {
                        borderBottomColor: theme?.colors?.blue
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        borderBottomColor: theme?.colors?.blue
                    },
                    '&:after': {
                        borderBottomColor: theme?.colors?.blue
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.MuiOutlinedInput-root:hover': {
                        '& > fieldset': {
                            borderColor: theme?.colors?.blue
                        }
                    },
                    '&.MuiOutlinedInput-root.Mui-focused': {
                        '& > fieldset': {
                            // borderWidth: 1,
                            borderColor: theme?.colors?.blue
                        }
                    },
                    // height: 45,
                    width: '100%',
                    background: theme?.paper,
                    borderRadius: 10,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 1
                    },
                    '& fieldset': {
                        borderColor: theme?.colors?.grey300
                    }
                },
                input: {
                    fontWeight: 400,
                    '&.MuiInputBase-inputSizeSmall': {
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryDark,
                    background: theme.colors?.primary200
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    position: 'relative'
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    position: 'relative'
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: theme?.colors?.blue
                    },
                    '&.Mui-completed': {
                        color: theme?.colors?.blue
                    }
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .MuiCircularProgress-svg': {
                        color: theme?.colors?.blue
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderTop: 'none'
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderBottom: 'none'
                    },
                    '& .MuiDataGrid-columnHeaderCheckbox': {
                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                            // display: 'none'
                        }
                    },
                    '&.MuiDataGrid-columnSeparator': {
                        display: 'none'
                    },
                    '.MuiDataGrid-columnSeparator--sideRight': {
                        display: 'none'
                    },
                    '&.MuiDataGrid-root': {
                        borderRadius: 10,
                        border: 'none',
                        color: theme?.colors?.primaryMain,
                        '& .Mui-selected': {},
                        '&.Mui-selected:hover': {}
                    },
                    overflow: 'auto',
                    padding: '0px 4px',
                    '& .MuiDataGrid-cell': {
                        fontWeight: 600,
                        fontSize: '0.8rem',
                        borderBottom: 'none',
                        outline: 0
                    },
                    '& .MuiDataGrid-menuIcon': {
                        display: 'none'
                    },
                    '& .MuiDataGrid-cell:first-of-type': {
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10
                    },
                    '& .MuiDataGrid-cell:last-child': {
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none'
                    },
                    '& .MuiDataGrid-columnHeader:focus-within': {
                        outline: 'none'
                    },
                    '& .MuiDataGrid-row': {
                        background: '#fff',
                        borderRadius: 10,
                        marginBottom: 12,
                        cursor: 'pointer'
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                        width: '0.6em',
                        height: '0.6em'
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                        background: theme.colors?.grey100,
                        borderRadius: 10
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.colors?.grey300,
                        borderRadius: 10
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                        background: theme.colors?.primaryDark
                    },
                    '& .MuiDataGrid-columnHeadersInner': {
                        background: 'none',
                        paddingBottom: 0,
                        color: '#657288',
                        fontSize: 12,
                        borderBottom: 'none'
                    }
                }
            }
        }
    };
}
