import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { CurrencyFormatter } from 'utils/utils';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';

const BundleItemsTable = ({ packedItems, currency }) => {
    return (
        <MainCard
            divider
            border
            title={
                <Box display={'flex'}>
                    <InventoryRoundedIcon sx={{ mr: 1.5, ml: -1.5 }} />
                    Bundle Items
                </Box>
            }
        >
            <PerfectScrollbar style={{ overflow: 'scroll' }}>
                <Table aria-label="simple table" sx={{ mt: -2 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>PARENT ITEM</TableCell>
                            <TableCell>ITEM CODE</TableCell>
                            <TableCell>DESCRIPTION</TableCell>
                            <TableCell>QTY</TableCell>
                            <TableCell>RATE</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(packedItems) &&
                            packedItems?.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell>{row?.parent_item}</TableCell>
                                    <TableCell>{row?.item_code}</TableCell>
                                    <TableCell>{row?.description}</TableCell>
                                    <TableCell>{row?.qty}</TableCell>
                                    <TableCell scope="row">
                                        <CurrencyFormatter locale="fr-FR" currency={currency} minimumFractionDigits={3} value={row?.rate} />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </PerfectScrollbar>
        </MainCard>
    );
};

export default BundleItemsTable;
