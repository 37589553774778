import axios from 'axios';
import { useState, useEffect } from 'react';
const useGetAxios = (url, reqData, isLara) => {
    const [data, setData] = useState([]);
    const [docinfo, setDocinfo] = useState([]);

    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    const [shouldRefetch, refetch] = useState({}); // triggering a force rerender

    const header = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        }
    };

    useEffect(() => {
        setTimeout(async () => {
            return axios
                .get(url, header, {
                    body: reqData ? JSON.stringify(reqData) : null
                })
                .then(({ data }) => {
                    if (isLara) {
                        setData(data);
                    } else {
                        setData(data?.docs[0]);
                        setDocinfo(data?.docinfo);
                    }
                    setIsPending(false);
                    setError(null);
                })
                .catch((err) => {
                    setIsPending(false);
                    setError(err.message);
                });
        }, 1000);
    }, [url, shouldRefetch]);

    return { data, isPending, error, refetch, docinfo };
};
export default useGetAxios;
