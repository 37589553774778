import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useFetch from 'hooks/useFetch';
import useGetAxios from 'hooks/useGetAxios';
import Loader from 'ui-component/Loader';
import SelectForm from 'ui-component/form/SelectForm';
import { SetNotification } from 'store/services/api';
import { CurrencyFormatter, formatErpResponse } from 'utils/utils';
import InfoIcon from '@mui/icons-material/Info';
import InputTextField from 'ui-component/form/InputTextField';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import { useTranslation } from 'react-i18next';

const LoyaltyCardForm = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    const [formattedData, setFormattedData] = useState([]);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const { data: customerList, isPending: isPendingCustomer } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Customer',
            fields: ['`tabCustomer`.`name`'],
            filters: [['Customer', 'disabled', '=', 0]]
        },
        'POST'
    );

    const { data: lps, isPending: isPendingLps } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Loyalty Program',
            fields: ['`tabLoyalty Program`.`name`', '`tabLoyalty Program Collection`.`tier_name`']
        },
        'POST'
    );

    const { data: loyaltyPrograms, isPending: isPendingloyaltyPrograms } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Loyalty Program',
            fields: [
                '`tabLoyalty Program`.`name`',
                '`tabLoyalty Program`.`expiry_duration`',
                '`tabLoyalty Program`.`from_date`',
                '`tabLoyalty Program`.`conversion_factor`',
                '`tabLoyalty Program`.`company`'
            ]
        },
        'POST'
    );

    const { data: lPEntries, isPending: isPendingLPEntries } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Loyalty Point Entry',
            fields: [
                '`tabLoyalty Point Entry`.`creation`',
                '`tabLoyalty Point Entry`.`name`',
                '`tabLoyalty Point Entry`.`customer`',
                '`tabLoyalty Point Entry`.`invoice`',
                '`tabLoyalty Point Entry`.`loyalty_points`',
                '`tabLoyalty Point Entry`.`loyalty_program`',
                '`tabLoyalty Point Entry`.`expiry_date`',
                '`tabLoyalty Point Entry`.`purchase_amount`'
            ]
        },
        'POST'
    );

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Loyalty%20Card&name=${id}`);

    const [state, setState] = useState({
        lp: '',
        customer: '',
        tier: ''
    });

    const handleChangeForm = (e) => {
        // setState({ ...state, [e.target.name]: e.target.value });
    };

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {id ? `${t('crm:lc')}: ${id}` : t('crm:newlc')}
        </Box>
    );

    useEffect(() => {
        if (lps && lps?.length !== 0) {
            const result = Object.values(
                lps.reduce((acc, cur) => {
                    if (acc[cur.name]) {
                        acc[cur.name].push(cur.tier_name);
                    } else {
                        acc[cur.name] = [cur.tier_name];
                    }
                    return acc;
                }, {})
            ).map((tier_names, index) => ({
                name: Object.keys(
                    lps.reduce((acc, cur) => {
                        acc[cur.name] = true;
                        return acc;
                    }, {})
                )[index],
                tier_names
            }));
            setFormattedData(result);
        }
    }, [lps]);

    useEffect(() => {
        if (id && !Array.isArray(doc) && doc) {
            setState({
                lp: doc?.loyalty_program,
                tier: doc?.tier,
                customer: doc?.customer
            });
        }
    }, [doc]);

    if (id && isPending) return <Loader />;
    if (isPendingloyaltyPrograms || isPendingLPEntries || isPendingLps || isPendingCustomer) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                <SelectForm
                    sx={{ mb: 2 }}
                    required
                    label={t('crm:prgrm')}
                    propToRender={'name'}
                    name="lp"
                    value={state.lp}
                    data={formattedData}
                    handleChange={handleChangeForm}
                />
                <SelectForm
                    sx={{ mb: 2 }}
                    label={t('crm:tier')}
                    name="tier"
                    value={state.tier}
                    data={formattedData?.filter((el) => el?.name === state.lp)?.[0]?.tier_names}
                    handleChange={handleChangeForm}
                />
                <SelectForm
                    sx={{ mb: 2 }}
                    required
                    label={t('crm:customer')}
                    propToRender={'name'}
                    name="customer"
                    value={state.customer}
                    data={customerList}
                    handleChange={handleChangeForm}
                />
                <InputTextField
                    sx={{ mb: 2 }}
                    handleChange={null}
                    value={loyaltyPrograms?.filter((el) => el?.name === state.lp)?.[0]?.expiry_duration}
                    label={t('crm:expires')}
                    name="expiry_duration"
                />
                <FormDateTime
                    sx={{ mb: 2 }}
                    type="date"
                    label={t('crm:fromdate')}
                    name="from_date"
                    value={loyaltyPrograms?.filter((el) => el?.name === state.lp)?.[0]?.from_date}
                    handleChange={null}
                />
                <InputTextField
                    isCurrency
                    currency={globalDefaults?.default_currency}
                    sx={{ mb: 2 }}
                    handleChange={null}
                    value={loyaltyPrograms?.filter((el) => el?.name === state.lp)?.[0]?.conversion_factor}
                    label={t('crm:cfactor')}
                    name="conversion_factor"
                />
                <InputTextField
                    sx={{ mb: 2 }}
                    handleChange={null}
                    value={loyaltyPrograms?.filter((el) => el?.name === state.lp)?.[0]?.company}
                    label={t('crm:company')}
                    name="company"
                />
                <MainCard divider border title={t('crm:lpe')}>
                    <Table aria-label={t('crm:lpet')}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('crm:creation')}</TableCell>
                                <TableCell>{t('crm:lp')}</TableCell>
                                <TableCell>{t('crm:invoice')}</TableCell>
                                <TableCell>{t('crm:purchaseamount')}</TableCell>
                                <TableCell>{t('crm:lpts')}</TableCell>
                                <TableCell>{t('crm:expiredate')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lPEntries
                                ?.filter((el) => el?.customer === state.customer)
                                ?.map((row) => (
                                    <TableRow key={`${row?.creation}-${row?.name}`}>
                                        <TableCell scope="row">{row.creation}</TableCell>
                                        <TableCell scope="row">{row.loyalty_program}</TableCell>
                                        <TableCell scope="row">{row.invoice}</TableCell>
                                        <TableCell scope="row">
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                currency={globalDefaults?.default_currency}
                                                minimumFractionDigits={3}
                                                value={row.purchase_amount}
                                            />
                                        </TableCell>
                                        <TableCell scope="row">{row.loyalty_points}</TableCell>
                                        <TableCell scope="row">{row.expiry_date}</TableCell>
                                    </TableRow>
                                ))}
                            {lPEntries?.filter((el) => el?.customer === state.customer)?.length === 0 && (
                                <TableRow key={`${'xxx'}-${'xxx'}`}>
                                    <TableCell colSpan={5}>
                                        <Box display={'flex'} alignItems={'center'}>
                                            <InfoIcon sx={{ mr: 1 }} />
                                            <>{t('crm:noitems')}</>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </MainCard>
            </MainCard>
        </Box>
    );
};

export default LoyaltyCardForm;
