// assets
import { IconKey, IconTypography, IconCategory, IconPackage } from '@tabler/icons';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    SupportAgentIcon
};

const crm = {
    id: 'crm',
    title: 'CRM',
    caption: 'Gestion CRM',
    type: 'group',
    role: [ROLE.ADMIN],
    children: [
        {
            id: 'CRM',
            title: 'CRM',
            type: 'collapse',
            icon: icons.SupportAgentIcon,
            children: [
                {
                    id: 'customer',
                    title: 'crm:customers',
                    type: 'item',
                    url: '/crm/customer'
                }
            ]
        }
    ]
};

export default crm;
