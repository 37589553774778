/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

const $white = '#FFFFFF';

export default function themePalette(theme) {
    return {
        mode: theme?.customization?.navType,
        common: {
            black: theme.colors?.darkPaper
        },
        primary: {
            light: theme.colors?.primaryLight,
            main: theme.colors?.primaryMain,
            dark: theme.colors?.primaryDark,
            200: theme.colors?.primary200,
            800: theme.colors?.primary800
        },
        secondary: {
            light: theme.colors?.secondaryLight,
            main: theme.colors?.secondaryMain,
            dark: theme.colors?.secondaryDark,
            200: theme.colors?.secondary200,
            800: theme.colors?.secondary800
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: $white
        },
        warning: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            contrastText: $white
        },
        orange: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            contrastText: $white
        },
        info: {
            light: '#64b5f6',
            main: '#2196f3',
            dark: '#1976d2',
            contrastText: $white
        },
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: $white
        },
        grey: {
            50: theme.colors?.grey50,
            100: theme.colors?.grey100,
            500: theme.darkTextSecondary,
            600: theme.heading,
            700: theme.darkTextPrimary,
            900: theme.textDark
        },
        dark: {
            light: theme.colors?.darkTextPrimary,
            main: theme.colors?.darkLevel1,
            dark: theme.colors?.darkLevel2,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper
        },
        text: {
            primary: theme.darkTextPrimary,
            secondary: theme.darkTextSecondary,
            dark: theme.textDark,
            hint: theme.colors?.grey100,
            sidebar: theme.darkTextSidebar
        },
        gray: {
            ligth: '#657288',
            main: '#657288',
            dark: '#AAB3BF',
            contrastText: '#657288'
        },
        blue: {
            ligth: theme.colors?.blue,
            main: theme.colors?.blue,
            dark: theme.colors?.blue,
            contrastText: theme.colors?.blue
        },
        white: {
            ligth: $white,
            main: $white,
            dark: $white,
            contrastText: $white
        },
        background: {
            paper: theme.paper,
            default: theme.backgroundDefault
        }
    };
}
