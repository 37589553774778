// action - state management
import * as actionTypes from '../actions';

export const initialState = {
    products: [],
    quotations: [],
    backUproducts: [],
    filtredProducts: [],
    categories: null,
    selectedItems: [],
    ProductBundle: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'actionTypes.SET_PRODUCT':
            return {
                ...state,
                products: action.payload.products,
                backUproducts: action.payload.products
            };
        case 'actionTypes.SET_CATEGORIES':
            return {
                ...state,
                categories: action.payload.categories
            };

        case 'actionTypes.SET_FILTRED_PRODUCT':
            var products = state.products;
            if (action.category) {
                products = products.filter((p) => p.item_group === action.category);
            }
            if (action.search !== '') {
                products = GlobalfilterData(state.products, action.search, [
                    'item_name',
                    'item_code',
                    'description',
                    'item_group',
                    'standard_rate'
                ]);
            }
            return {
                ...state,
                filtredProducts: products
            };
        case 'actionTypes.SET_QUOTATIONS':
            return {
                ...state,
                quotations: action.payload.quotations
            };
        case 'actionTypes.SET_PRODUCT_STOCK_BY_ID':
            return {
                ...state,
                productStock: action.payload.productStock.message
            };
        case 'actionTypes.GET_PRODUCTS_FOR_QUOTATIONS_REQUEST':
            return {
                ...state,
                selectedItems: action.payload.products
            };
        case 'actionTypes.GET_PRODUCT_BUNDLE':
            return {
                ...state,
                ProductBundle: action.payload.productBundle
            };

        default:
            return state;
    }
};

export default productReducer;
