import { LoadingButton } from '@mui/lab';
import { Box, Button, Modal } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetNotification } from 'store/services/api';
import MainCard from 'ui-component/cards/MainCard';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import { formatErpResponse } from 'utils/utils';
import SalesInvoicePayment from 'views/invoices/sales/SalesInvoice/components/SalesInvoicePayment';
import { modalStyle } from 'views/utilities/ModalStyle';

const SubmitOrder = ({ doc, company, refetchDoc }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openPayment, setOpenPayment] = useState(false);
    const handleOpenPayment = () => setOpenPayment(true);
    const handleClosePayment = () => setOpenPayment(false);

    const [state, setState] = useState({
        due_date: moment().format('YYYY-MM-DD')
    });

    const [invoiceMappedDoc, setInvoiceMappedDoc] = useState(null);
    const [invoiceSubmittedDoc, setInvoiceSubmittedDoc] = useState(null);
    const [paymentMappedDoc, setPaymentMappedDoc] = useState(null);

    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingInvoiceButton, setLoadingInvoiceButton] = useState(false);

    const handleChange = (e) => {
        setState({ ...state, due_date: e.target.value });
    };

    const submitSalesOrder = () => {
        let data = {
            ...doc,
            status: 'To Deliver and Bill'
        };
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Submit' })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    fetch(`${process.env.REACT_APP_API_URI}/api/make-mapped-doc`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify({
                            method: 'erpnext.selling.doctype.sales_order.sales_order.make_sales_invoice',
                            source_name: doc?.name
                        })
                    })
                        .then((res) => {
                            setLoadingButton(false);
                            return res.json();
                        })
                        .then((data) => {
                            if (data?.message) {
                                setInvoiceMappedDoc(data?.message);
                                handleOpen();
                            }
                        });
                }
            });
    };

    const handleSubmitSalesInvoice = async () => {
        let reqDoc = {
            ...invoiceMappedDoc,
            due_date: state.due_date
        };
        setLoadingInvoiceButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: reqDoc, action: 'Submit' })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then(async (data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    if (data?.docs?.length) {
                        setInvoiceSubmittedDoc(data?.docs?.[0]);
                        if (data?.docs?.[0]?.due_date === moment().format('YYYY-MM-DD')) {
                            fetch(`${process.env.REACT_APP_API_URI}/api/document/make-mapped-payment`, {
                                method: 'POST',
                                headers: header,
                                body: JSON.stringify({ dt: 'Sales Invoice', dn: data?.docs?.[0]?.name })
                            })
                                .then((res) => {
                                    setLoadingInvoiceButton(false);
                                    return res.json();
                                })
                                .then((data) => {
                                    if (data?.message) {
                                        setPaymentMappedDoc(data?.message);
                                        handleClose();
                                        handleOpenPayment();
                                    }
                                });
                        } else {
                            setLoadingInvoiceButton(false);
                            dispatch(SetNotification({ code: 'success', message: 'Sales Invoice successfully created' }));
                            handleClose();
                            refetchDoc({});
                        }
                    }
                }
            });
    };

    const submitSalesInvoice = (
        <Box sx={modalStyle}>
            <MainCard divider title={'Payment Date'}>
                <FormDateTime
                    fullwidth
                    required
                    type="date"
                    label={'Payment Due'}
                    name="due_date"
                    value={state.due_date}
                    handleChange={handleChange}
                    sx={{ mb: 2 }}
                />
                <Box display={'flex'} justifyContent={'right'}>
                    <Button variant="outlined" color="blue" size="large" onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        sx={{ ml: 2 }}
                        variant="contained"
                        color="blue"
                        size="large"
                        loading={loadingInvoiceButton}
                        onClick={handleSubmitSalesInvoice}
                        disabled={!state.due_date}
                    >
                        Make Sales Invocie
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );

    const makePayment = (
        <Box sx={modalStyle}>
            <SalesInvoicePayment refetchDoc={refetchDoc} mappedDoc={paymentMappedDoc} handleClose={handleClosePayment} company={company} />
        </Box>
    );

    return (
        <>
            <LoadingButton variant={'contained'} color="blue" sx={{ ml: 1 }} onClick={submitSalesOrder} loading={loadingButton}>
                {`Submit Order`}
            </LoadingButton>
            <Modal onClose={handleClose} open={open}>
                {submitSalesInvoice}
            </Modal>
            <Modal onClose={handleClosePayment} open={openPayment}>
                {makePayment}
            </Modal>
        </>
    );
};

export default SubmitOrder;
